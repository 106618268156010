import React, { useState, useEffect } from 'react';
import { IconArrowUp } from '../../../assets/Icons';
import './CardOrder.scss';
import { PRODUCT_DEFAULT_IMAGE_URL } from '../../../Utils/Index';

function CardOrder({ orderInfs, customColor }) {
  const [order, setOrder] = useState(null);
  const [personalizedStyles, setPersonalizedStyles] = useState(null);
  const classOrders = {
    Finalizado: 'dark-grey',
    'Cancelado pelo cliente': 'dark-grey',
    'Cancelado pelo estabelecimento': 'dark-grey',
    'Recusado pelo estabelecimento': 'dark-grey',
    Aguardando: '',
    'Em preparo': '',
    'Pronto para retirada': '',
    'Saiu para entrega': '',
  };

  useEffect(() => {
    if (orderInfs) setOrder(orderInfs);
    else setOrder(null);
    return () => setOrder(null);
  }, [orderInfs]);

  useEffect(() => {
    if (!customColor) {
      setPersonalizedStyles('#000000');
      return;
    }
    setPersonalizedStyles(customColor);
  }, [customColor]);

  return (
    <>
      {order && (
        <div className={'card-user-order'}>
          <div className="card-user-order__logo-store">
            <img
              className="card-user-order__logo-store-image"
              src={order.image ? order.image : PRODUCT_DEFAULT_IMAGE_URL}
              alt="Logo estabelecimento"
            />
          </div>
          <div className="card-user-order__order-details">
            <div className="card-user-order__order-details__inline">
              <span
                className={
                  'card-user-order__order-details__inline-id-order ' +
                  classOrders[order.status]
                }
              >
                #{order.codigo}
              </span>
              <div className="card-user-order__order-details__inline-arrow-icon">
                <IconArrowUp size={20} color={personalizedStyles} />
              </div>
            </div>
            <div
              className={
                'card-user-order__order-details__column ' +
                classOrders[order.status]
              }
            >
              <span className="card-user-order__order-details__column-label-status">
                Status
              </span>
              <span
                className={
                  'card-user-order__order-details__column-status ' +
                  classOrders[order.status]
                }
              >
                {order.status}
              </span>
            </div>
            <div className="card-user-order__order-details__inline">
              <span
                className={
                  'card-user-order__order-details__inline-price ' +
                  classOrders[order.status]
                }
              >
                <small>R$</small> {order.price}
              </span>
              <span className="card-user-order__order-details__inline-date">
                {order.date}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CardOrder;
