import React from 'react';

function MonthlyPlanCard({ onSelectPlan, blockButton }) {
  function onSelectedPlan() {
    if (blockButton) return;
    onSelectPlan();
  }

  return (
    <div className="plans-container__card monthly">
      <div className="plans-container__card-content">
        <h2 className="plans-container__card-title monthly-dark-color">
          Plano mensal
        </h2>

        <div className="position-relative">
          <div className="plans-container__card__price monthly-dark-color ">
            <h1 className="plans-container__card__price-value">R$ 50,00</h1>
            <h3 className="plans-container__card__price-period">/mês</h3>
          </div>
        </div>

        <button
          type="button"
          className="plans-container__card-buy-plan-btn monthly-primary-color"
          onClick={() => onSelectedPlan()}
          disabled={blockButton}
          style={{ opacity: blockButton ? 0.75 : 1 }}
        >
          Assinar plano
        </button>
      </div>
      <button
        type="button"
        className="plans-container__card-footer-btn monthly-footer-btn"
        onClick={() =>
          window.open('https://delivery.legal/home#plans', '_blank')
        }
      >
        Ver todas as funcionalidades
      </button>
    </div>
  );
}

export default MonthlyPlanCard;
