import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Skeleton } from '@material-ui/lab';
import {
  copyText,
  formatDateTime,
  maskCurrencyBRL,
} from '../../../../../Utils/Index';
import { toast } from 'react-toastify';
import { history } from '../../../../../services/Assinaturas';

function PlanHistory() {
  const [loaded, setLoaded] = useState(false);

  const [financialhistory, setFinancialhistory] = useState([]);

  const fakeData = [0, 0, 0, 0, 0, 0, 0, 0];

  useEffect(() => {
    callGetPaymentsHistory();
    return () => {
      setLoaded(false);
      setFinancialhistory([]);
    };
  }, []);

  async function callGetPaymentsHistory() {
    history()
      .then((response) => formatData(response))
      .catch((err) => {
        setLoaded(false);
        toast.error(
          'Ocorreu um erro ao buscar o seu histórico de transação, por favor tente novamente ou entre em contato conosco.',
          { autoClose: 8000 }
        );
      });
  }

  function formatData(paymentsHistory) {
    if (!paymentsHistory.length) {
      setLoaded(true);
      return;
    }

    const formatedData = paymentsHistory.map((history) => {
      history.gateway_tipo = history.chave_pix ? 'Pix' : 'Cartão de crédito';
      history.valor = maskCurrencyBRL(history.valor);
      history.dh_criacao = formatDateTime(history.dh_criacao);
      history.datahora_pagto = history.datahora_pagto
        ? formatDateTime(history.datahora_pagto)
        : '-';
      history.transactionCode = Boolean(history.chave_pix);
      return history;
    });
    setFinancialhistory(formatedData);
    setLoaded(true);
  }

  const columns = [
    'Método pagamento',
    'Status pagamento',
    'Valor',
    'Plano',
    'Vigência do plano',
    'Data/hora cobrança',
    'Data/hora pagamento',
    'Código pagamento',
  ];

  function copyPixCode(index) {
    const response = copyText(financialhistory[index].chave_pix);
    if (!response) {
      toast.error(
        'Ocorreu um erro ao copiar o código pix, por favor tente novamente ou entre em contato conosco.'
      );
      return;
    }
    toast.success('Código PIX copiado com sucesso!');
  }

  return (
    <div className="plan-billing-infos__history">
      <TableContainer sx={{ maxHeight: '100vh' }} style={{ maxHeight: 360 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((label, index) => (
                <TableCell
                  key={index}
                  className="labelColumn testeee"
                  align="center"
                  size="small"
                  style={{ fontFamily: 'MontserrattNormal' }}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loaded && financialhistory.length ? (
              <>
                {financialhistory.map((proposal, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="left" style={{ maxWidth: 100 }}>
                        {proposal.gateway_tipo}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          maxWidth: 150,
                          color: proposal.ativo ? 'green' : 'red',
                        }}
                      >
                        {proposal.ativo ? 'Ativo' : 'Inativo'}
                      </TableCell>
                      <TableCell align="center" style={{ maxWidth: 150 }}>
                        {proposal.valor}
                      </TableCell>
                      <TableCell align="center" style={{ maxWidth: 150 }}>
                        {proposal.nome}
                      </TableCell>
                      <TableCell align="center" style={{ maxWidth: 150 }}>
                        {proposal.dias_validos}
                      </TableCell>
                      <TableCell align="center" style={{ maxWidth: 150 }}>
                        {proposal.dh_criacao}
                      </TableCell>
                      <TableCell align="center" style={{ maxWidth: 250 }}>
                        {proposal.datahora_pagto}
                      </TableCell>
                      <TableCell align="center" style={{ maxWidth: 150 }}>
                        {proposal.transactionCode ? (
                          <button
                            className="plan-billing-infos__history-copy-pix-btn"
                            onClick={() => copyPixCode(index)}
                          >
                            Copiar chave pix
                          </button>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : loaded ? (
              <span>Não há histórico financeiro</span>
            ) : (
              <>
                {fakeData.map((_, index) => {
                  return (
                    <TableRow key={index}>
                      {fakeData.map((_, index2) => {
                        return (
                          <TableCell
                            key={index2}
                            align="left"
                            style={{ maxWidth: 250 }}
                          >
                            <Skeleton
                              animation="wave"
                              variant="rect"
                              height={18}
                              width={'100%'}
                              style={{
                                border: 'none',
                                borderRadius: 8,
                                maxWidth: 450,
                              }}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default PlanHistory;
