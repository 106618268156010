import React, { useEffect, useState } from 'react';
import './StoreData.scss';

import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';

import ChangePassword from './ChangePassword/ChangePassword';
import FinancialHistory from './FinancialHistory/FinancialHistory';
import Assinatura from './Assinatura/Assinatura';

function StoreData() {
  const [routeNav, setRouteNav] = useState([
    {
      actived: true,
      path: '/painel/dados-estabelecimento',
      exact: true,
      label: 'Extrato',
      main: <FinancialHistory />,
    },
    {
      actived: false,
      path: '/painel/dados-estabelecimento/alterar-senha',
      exact: true,
      label: 'Alterar senha',
      main: <ChangePassword />,
    },
    {
      actived: false,
      path: '/painel/dados-estabelecimento/assinatura',
      exact: true,
      label: 'Assinatura',
      main: <Assinatura />,
    },
  ]);

  useEffect(() => {
    handlerActiveRoute({ path: window.location.pathname });
    return () => {
      setRouteNav([]);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  function handlerActiveRoute(currentRoute) {
    const currentActivedRoute = routeNav.map((route) => {
      route.actived = route.path === currentRoute.path;
      return route;
    });
    setRouteNav(currentActivedRoute);
  }

  return (
    <div className="configuracao">
      <Router>
        <h3>Meu cadastro</h3>
        <div className="line-nav">
          {routeNav.map((route, index) => {
            return (
              <Link
                key={index}
                to={route.path}
                className={
                  route.actived ? 'card-config card-active' : 'card-config'
                }
                onClick={() => handlerActiveRoute(route)}
              >
                {route.label}
              </Link>
            );
          })}
        </div>
        <Switch>
          {routeNav.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={route.main}
            />
          ))}
        </Switch>
      </Router>
    </div>
  );
}

export default StoreData;
