import React, { useState, useEffect } from 'react';
import GroupComplements from './GroupComplements/GroupComplements';
import ItemsComplements from './ItemsComplements/ItemsComplements';
import EventEmitter from '../../../../services/Event';
import {
  excludeGroupOnProduct,
  getAllGroupComplements,
  getGroupsByProduct,
} from '../../../../services/Products';
import { Tooltip } from '@material-ui/core';
import { useFormProduct } from '../../ContextProduct/ContextProduct';
import { toast } from 'react-toastify';
import InputRadio from '../../../../shared/InputRadio/InputRadio';

export default function Complements({ productData, pageComplement }) {
  const [step, setStep] = useState(1);
  const [idGroupComplements, setIdGroupComplements] = useState(0);

  const [hasOneGroupComplement, setHasOneGroupComplement] = useState(false);
  const [hasComplement, setHasComplement] = useState(true);

  const [allComplementsGroup, setAllComplementsGroup] = useState(null);
  const [nameGroupComplement, setNameGroupComplement] = useState(null);

  const [groupComplements, setGroupComplements] = useState([{ idGroup: 0 }]);

  const { setComplementsAssignedInProduct } = useFormProduct();

  useEffect(() => {
    if (productData) getGroupComplement(productData.codigo);
    getAllComplementsGroup();
  }, [productData]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    EventEmitter.on('navegateComplements', (e) => selectPage(e));
    EventEmitter.on('updateAllComplementsGroup', () =>
      getAllComplementsGroup()
    );
    EventEmitter.once('hasOneGroupComplement', (e) =>
      setHasOneGroupComplement(e)
    );
    EventEmitter.once('hasOneGroupComplement', () =>
      getGroupComplement(productData.codigo)
    );
    return () => {
      EventEmitter.off('navegateComplements');
      EventEmitter.off('hasOneGroupComplement');
      EventEmitter.off('updateAllComplementsGroup');
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  async function getGroupComplement(idProduct) {
    const response = await getGroupsByProduct(idProduct);
    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao buscar os complementos atribuidos a este produto, por favor tente novamente ou entre em contato conosco.'
      );
      return;
    }

    if (!response.result.length) {
      setHasOneGroupComplement(false);
      setGroupComplements([{ idGroup: 0 }]);
      setComplementsAssignedInProduct(null);
      return;
    }

    const groups = [];
    setHasOneGroupComplement(true);
    response.result.forEach((complement, index) => {
      groups.push({
        idGroup: complement.cod_produto_opcoes,
        name: complement.nome,
        index: index,
        expaded: false,
        hover: false,
      });
    });
    setGroupComplements(groups);
    setComplementsAssignedInProduct(groups);
  }

  async function getAllComplementsGroup() {
    const response = await getAllGroupComplements();
    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao buscar os grupos de complementos existentes, tente novamente ou entre em contato conosco.'
      );
      return;
    }

    response.result.unshift({
      codigo: 'custom',
      nome: 'Criar novo grupo de complementos',
    });
    setAllComplementsGroup(response.result);
  }

  async function removeGroupComplement(idGroupComplement) {
    if (!idGroupComplement.idGroup) {
      setGroupComplements(
        groupComplements.filter(
          (item) => item.index !== idGroupComplement.index
        )
      );
      return;
    }

    const response = await excludeGroupOnProduct(
      productData.codigo,
      idGroupComplement.idGroup
    );

    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao dessacioar grupo de complementos ao produto, tente novamente ou entre em contato conosco.'
      );
      return;
    }

    toast.success('Grupo de opções desassociado ao produto com sucesso.');
    setGroupComplements(
      groupComplements.filter((item) => item.index !== idGroupComplement.index)
    );
  }

  async function removeAllComplementGroups() {
    groupComplements.map(async (item) => {
      excludeGroupOnProduct(productData.codigo, item.idGroup);
    });
    toast.success('Grupos de opções foram desassociados ao produto.');
    setGroupComplements([{ idGroup: 0 }]);
    setHasComplement(true);
  }

  function selectPage(param) {
    setIdGroupComplements(param.idGroup);
    setNameGroupComplement(param.name);
    setStep(param.step === 'next' ? 2 : 1);
    pageComplement(param.step === 'next' ? 2 : 1);
  }

  function addGroupComplement() {
    groupComplements.push({ idGroup: 0, index: null, newComplement: true });
    const updatedComplements = groupComplements.map((groupComplent, index) => {
      if (groupComplent.newComplement) {
        groupComplent.expaded = false;
        groupComplent.name = 'Novo grupo de complementos';
        groupComplent.hover = false;
        groupComplent.index = index;
        groupComplent.newComplement = false;
      }
      return groupComplent;
    });
    setGroupComplements(updatedComplements);
  }

  function toggleExpadBox(gpComplement, closeAll = false) {
    const updatedGroupComplement = groupComplements.map((groupComplement) => {
      if (closeAll) {
        groupComplement.expaded = false;
        return groupComplement;
      }
      if (gpComplement.index === groupComplement.index)
        groupComplement.expaded = !groupComplement.expaded;
      return groupComplement;
    });
    setGroupComplements(updatedGroupComplement);
  }

  function hoverComplement(param, hover = true) {
    let temp = groupComplements.map((item) => {
      if (item.index === param.index) {
        item.hover = hover;
      }
      return item;
    });
    setGroupComplements(temp);
  }

  function toggleHasComplement(activeComplement) {
    setHasComplement(activeComplement);
    toggleExpadBox(
      groupComplements.filter((item) => (item.expaded = true)),
      true
    );
  }

  function getComplementsGroup(idComplement, indexComplement = null) {
    if (!idComplement) {
      setGroupComplements(
        groupComplements.map((complement) => {
          if (complement.index === indexComplement)
            complement.name = 'Novo grupo de complementos';
          return complement;
        })
      );
      return;
    }

    const complementSelected = allComplementsGroup.find(
      (complement) => complement.codigo === idComplement
    );

    if (!complementSelected) return;

    setGroupComplements(
      groupComplements.map((complement) => {
        if (complement.index === indexComplement)
          complement.name = complementSelected.nome;
        return complement;
      })
    );
  }

  return (
    <div className="vstack">
      {step === 1 && (
        <div className="w-100 pt-2">
          <div className="vstack gap-1 pb-3">
            <div className="hstack justify-content-between w-100 align-items-center">
              <h3 className="fw-bold fs-3 m-0">Complementos</h3>
              {hasOneGroupComplement && (
                <Tooltip title="Adicionar mais opções complementares">
                  <button
                    className="btn btn-primary-fill p-0 bg-primary rounded-circle align-items-center d-flex justify-content-center"
                    style={{ width: 40, height: 40 }}
                    onClick={() => addGroupComplement()}
                  >
                    <span className="material-symbols-outlined">add</span>
                  </button>
                </Tooltip>
              )}
            </div>
            <span className="fs-6">
              Adicione complementos no produto para serem escolhidos no ato da
              compra.
            </span>
          </div>

          <div className="vstack">
            <span className="fs-6 fw-bold">Habilitar complementos ?</span>
            {groupComplements.length > 1 && (
              <div className="hstack w-100">
                <InputRadio
                  labelInput={'Sim'}
                  selected={hasComplement}
                  clicked={() => toggleHasComplement(true)}
                />
                <div style={{ paddingLeft: 8 }}>
                  <InputRadio
                    labelInput={'Não'}
                    selected={!hasComplement}
                    clicked={() => toggleHasComplement(false)}
                  />
                </div>
              </div>
            )}
          </div>

          <>
            {
              //Sem ou somente 1 complemento
              hasComplement && step === 1 && groupComplements.length <= 1 && (
                <GroupComplements
                  productInfo={productData}
                  allGroupComplements={allComplementsGroup}
                  idGroup={groupComplements[0].idGroup}
                  goToItemsProduct={selectPage}
                  returnIdGroupComplements={setIdGroupComplements}
                  returnHasComplement={setHasComplement}
                />
              )
            }
            {
              //+1 complemento
              groupComplements.length > 1 &&
                groupComplements.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="my-2 h-100 justify-content-center vstack w-100 position-relative rounded shadow-sm p-2 py-4"
                    >
                      <div className="hstack align-items-center pt-1 w-100 justify-content-between">
                        <span className="fw-4 fw-bold">{item.name}</span>

                        <div className="hstack gap-3">
                          <button
                            className="btn btn-primary-fill p-0 text-primary"
                            onClick={() => toggleExpadBox(item)}
                          >
                            <span className="material-symbols-outlined">
                              {item.expaded
                                ? 'keyboard_arrow_down'
                                : 'keyboard_arrow_up'}
                            </span>
                          </button>

                          <button
                            className="btn btn-primary-fill p-0"
                            onClick={() => removeGroupComplement(item)}
                          >
                            <span className="material-symbols-outlined">
                              delete
                            </span>
                          </button>
                        </div>
                      </div>

                      <>
                        {item.expaded && (
                          <GroupComplements
                            productInfo={productData}
                            showInputsRadio={false}
                            allGroupComplements={allComplementsGroup}
                            idGroup={item.idGroup}
                            indexComplement={item.index}
                            returnIdGroupComplements={getComplementsGroup}
                            goToItemsProduct={selectPage}
                          />
                        )}
                      </>
                    </div>
                  );
                })
            }
            {!hasComplement && step === 1 && (
              <div className="my-2 align-items-center h-100 justify-content-center vstack w-100 position-relative rounded shadow p-3">
                <h3 className="fw-bold fs-6 py-1 w-100 text-center">
                  Deseja desabilitar as opções complementares ?
                </h3>
                <span className="fs-7 w-100 text-center">
                  Ao clicar em confirmar todas as opções complementares serão
                  desassociadas a este produto.
                </span>
                <div className="hstack w-100 p-2 justify-content-between">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ margin: 8 }}
                    onClick={() => setHasComplement(true)}
                  >
                    Não
                  </button>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => removeAllComplementGroups()}
                  >
                    Sim
                  </button>
                </div>
              </div>
            )}
          </>
        </div>
      )}

      {step === 2 && (
        <ItemsComplements
          idGroupItems={idGroupComplements}
          nameGroupComplement={nameGroupComplement}
        />
      )}
    </div>
  );
}
