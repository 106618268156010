import React, { useState, useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import EventEmitter from '../../../services/Event';
import { loginConsumer } from '../../../services/Consumer';
import { getDeliveryPrice } from '../../../Utils/Index';
import { StoreContext, userDataContext } from '../../MenuProvider/MenuProvider';
import InputV2 from '../../../shared/InputV2/InputV2';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import ReCAPTCHA from 'react-google-recaptcha';

function SignInClient({ backStep, onAuthenticate, recoverPassword }) {
  const formRef = useRef();
  const recaptchaRef = useRef();

  const { setUserData } = useContext(userDataContext);
  const { storeSettingsProvider } = useContext(StoreContext);

  const [displayPassword, setDisplayPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [lastToastID, setLastToastID] = useState('');

  async function checkForm(path) {
    try {
      const schema = Yup.object().shape({
        phone: Yup.string()
          .required('Telefone é obrigatório')
          .matches(/[0-9]{2}\s[0-9]{5}-[0-9]{4}/, 'Número inválido'),
        password: Yup.string().required('Senha é obrigatório'),
      });

      if (!recaptchaToken) {
        if (lastToastID && toast.isActive(lastToastID)) return false;
        const toastID = toast.error('Preencha o recaptcha', {
          autoClose: 7000,
        });
        setLastToastID(toastID);
        return false;
      }
      // UizBrazil

      await schema.validate(path, { abortEarly: false });
      formRef.current.setErrors({});
      authenticate();
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  async function authenticate() {
    setLoading(true);
    const { phone, password } = formRef.current.getData();
    const response = await loginConsumer(
      phone.replace(/\D/g, ''),
      password,
      recaptchaToken
    );
    if (!response || !response.success) {
      handleError(response.message);
      setLoading(false);
      return;
    }
    setUserData(response.result);
    callGetDeliveryPrice(response.result);
    toast.success('Login realizado com sucesso');
    EventEmitter.emit('update-address', response.result.address);
    onAuthenticate();
  }

  function handleError(message) {
    const msg =
      message === 'Falha na autenticação'
        ? 'Dados inválidos.'
        : 'Ocorreu um erro ao realizar o login. Por favor, tente novamente ou entre com nosso suporte.';
    toast.error(msg, { pauseOnHover: true });
  }

  async function callGetDeliveryPrice(userInformations) {
    const { address } = userInformations;
    const { codigo } = storeSettingsProvider.settings.store;
    await getDeliveryPrice(address.cep.replace(/\D/, ''), codigo, '');
  }

  return (
    <div className="p-2 vstack w-100">
      <div className="vstack mb-3">
        <h2 className="fw-bold text-center fs-5 mb-0">Acesse sua conta</h2>
        <span className="fs-8 text-secondary">
          Insira o telefone e a senha que você usou ao criar sua conta.
        </span>
      </div>

      <Form ref={formRef} onSubmit={(path) => checkForm(path)}>
        <InputV2
          type="tel"
          label="Telefone"
          name="phone"
          id="phone"
          maxLength="13"
          autoFocus={true}
          mask="PHONE"
          errorMessage="Telefone inválido."
          placeholder="Telefone"
          containerClass="mb-1 "
        />

        <InputV2
          name="password"
          label="Senha"
          placeholder="Senha"
          containerClass="position-relative"
          type={displayPassword ? 'text' : 'password'}
        >
          <button
            type="button"
            className="z-1 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
            style={{ top: 30, right: 4 }}
            onClick={() => setDisplayPassword(!displayPassword)}
          >
            <span className="material-symbols-outlined fs-5">
              {displayPassword ? 'visibility' : 'visibility_off'}
            </span>
          </button>
        </InputV2>

        <div className="w-100 justify-content-end hstack mt-2">
          <button
            type="button"
            className="btn btn-primary-fill fs-7 p-1 text-secondary"
            onClick={() => recoverPassword()}
          >
            Recuperar senha
          </button>
        </div>

        <div className="w-100 hstack justify-content-center py-2">
          <ReCAPTCHA
            ref={recaptchaRef}
            size="normal"
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_LOGIN}
            onExpired={() => setRecaptchaToken()}
            onError={() => setRecaptchaToken()}
            onChange={(token) => setRecaptchaToken(token)}
          ></ReCAPTCHA>
        </div>

        <div className="hstack w-100 justify-content-between algin-items-center mt-4">
          <button
            onClick={() => backStep(false, true)}
            disabled={loading}
            className={`btn btn-fill text-dark
              ${loading ? 'pe-none opacity-50' : ''}
              `}
          >
            Voltar
          </button>
          <button
            type="submit"
            disabled={loading}
            className={`btn btn-primary w-50 ${
              loading ? 'pe-none opacity-50' : ''
            }`}
          >
            Entrar
          </button>
        </div>
      </Form>
    </div>
  );
}

export default SignInClient;
