import React from 'react';
import TextHeader from '../../SkeletonLoading/TextHeader';
import SwitchButton from '../../../../shared/SwitchButton/SwitchButton';

function SettingCard({
  title,
  loaded,
  value,
  onClick,
  booleanValue,
  highlight = false,
  icon = '',
  useSwitch = false,
}) {
  return (
    <button
      className={`gap-1 btn btn-primary-fill p-0 bg-white shadow-sm d-flex flex-column p-2 rounded ${
        !onClick && 'pe-none'
      }`}
      onClick={() => onClick && !useSwitch && onClick()}
    >
      <span className="text-center w-100 d-block">{title}</span>
      <span
        className={`d-block w-100 text-center fw-bold ${
          highlight ? (booleanValue ? 'text-success' : 'text-danger') : ''
        }`}
      >
        {loaded ? (
          useSwitch ? (
            <SwitchButton
              initialValue={booleanValue}
              onChange={() => onClick()}
            />
          ) : (
            <div className="hstack align-items-center justify-content-center gap-1">
              {icon ? (
                <span className="material-symbols-outlined">{icon}</span>
              ) : null}
              <span>{value}</span>
            </div>
          )
        ) : (
          <div className="w-100 d-flex justify-content-center">
            <TextHeader text={true} />
          </div>
        )}
      </span>
    </button>
  );
}

export default SettingCard;
