import { saveInBrowserStorage, validCpf } from '../Utils/Index';
import { httpService } from './HttpService';

export const login = async (email, senha, recaptcha) => {
  const response = await httpService('authentication/dashboard', 'login', {
    email,
    senha,
    recaptcha,
  });
  if (!response || !response?.success || !response?.result) throw response;
  if (response.result) {
    await saveInBrowserStorage('CLIENT_DATA', response.result, false);
    await saveInBrowserStorage('TOKEN', response.result.token, false);
  }
  return response;
};

export const activeStore = async (transacao_referencia) => {
  const response = await httpService('painel/gestor', 'activeStore', {
    transacao_referencia,
  });
  try {
    if (!response) throw response;
    return response;
  } catch (error) {
    return error;
  }
};

export const createStoreDashboard = async (params) => {
  try {
    const response = await httpService(
      'painel/gestor',
      'createStoreDashboard',
      { ...params }
    );
    if (!response) throw response;
    if (response.result) {
      await saveInBrowserStorage('CLIENT_DATA', response.result, false);
      await saveInBrowserStorage('STORE_INITIAL_SETTINGS', {
        id_projeto: 1,
        uuid_estabelecimento: response.result.uuid_estabelecimento,
        id_estabelecimento: response.result.cod_estabelecimento,
        nome: params.nome_estabelecimento,
        email: params.email,
        telefone: params.telefone,
        documento: params.cpf_cnpj,
        uuid_revendedor: null,
        documento_tipo: validCpf(params.cpf_cnpj) ? 'CPF' : 'CNPJ',
      });
    }
    return response;
  } catch (error) {
    return error;
  }
};

export const recoverPassword = async (
  isClient,
  password,
  pincode,
  phone,
  email
) => {
  const path = isClient ? 'consumer/user' : 'painel/gestor';
  return await httpService(path, 'recoverPassword', {
    password,
    pincode,
    phone,
    email,
  });
};

export const pincode = async (isClient, phone, email) => {
  const path = isClient ? 'consumer/user' : 'painel/gestor';
  return await httpService(path, 'pincode', {
    type: 'RECOVER_PASSWORD',
    phone,
    email,
  });
};

export const checkPincode = async (isClient, pincode, phone, email) => {
  const path = isClient ? 'consumer/user' : 'painel/gestor';
  return await httpService(path, 'check-pincode', {
    pincode,
    phone,
    email,
  });
};
