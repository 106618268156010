import React, { useState, useEffect, useContext } from 'react';
import { MenuContext } from '../../MenuProvider/MenuProvider';
import EventEmitter from '../../../services/Event';
import { deepClone, maskCurrencyBRLWithoutSymbol } from '../../../Utils/Index';
import { toast } from 'react-toastify';
import ProductCheckout from '../ProductCheckout';

export default function OrderList({ productsInCart, price, close }) {
  const [orderList, setOrderList] = useState([]);
  const [totalPrice, setTotalPrice] = useState('R$ 0,00');

  const { productsOrders, setProductsOrders } = useContext(MenuContext);

  useEffect(() => {
    calculateSubTotal(productsInCart);
  }, [productsInCart]);

  useEffect(() => {
    setTotalPrice(price);
  }, [price]);

  function calculateSubTotal(productsInCart) {
    const productsWithSubTotal = productsInCart.map((product) => {
      let priceProduct = 0,
        priceComplement = 0,
        totalPrice = 0;
      priceProduct = product.price.with_qty;
      if (product.complementos.length) {
        product.complementos.forEach((complement) => {
          priceComplement += complement.price.with_qty;
        });
      }
      totalPrice = priceProduct + priceComplement;
      product.sub_total = maskCurrencyBRLWithoutSymbol(totalPrice);
      return product;
    });
    setOrderList(productsWithSubTotal);
  }

  function removeProductOnCart(productToRemove) {
    if (productToRemove.qtd > 1) {
      decreaseProductQuantity(productToRemove);
      return;
    }

    let indexProductToRemove = productsOrders.findIndex(
      (product) =>
        product.product_unique_id === productToRemove.product_unique_id &&
        product.codigo === productToRemove.codigo
    );
    if (indexProductToRemove < 0) {
      toast.warning(
        'Ocorreu um erro ao localizar o produto que deseja remover, afim de evitar maiores transtornos removemos os produtos simulares.'
      );
      indexProductToRemove = productsOrders.findIndex(
        (product) => product.codigo === productToRemove.codigo
      );
    }

    productsOrders.splice(indexProductToRemove, 1);
    const productsInCart = deepClone(productsOrders);
    setProductsOrders(productsInCart);
    EventEmitter.emit('update-cart', productsInCart);
    if (!productsInCart.length) {
      EventEmitter.emit('force-close-modal');
      toast.success('Carinho esvaziado.', { position: 'bottom-left' });
    }
  }

  function decreaseProductQuantity(product) {
    const productToDecreaseQuantity = deepClone(product);
    const newProductQuantity = productToDecreaseQuantity.qtd - 1;
    const newTotalProductPrice =
      newProductQuantity * productToDecreaseQuantity.price.value;

    productToDecreaseQuantity.qtd = newProductQuantity;
    productToDecreaseQuantity.price.with_qty = newTotalProductPrice;
    productToDecreaseQuantity.price.with_qty_formated =
      maskCurrencyBRLWithoutSymbol(newTotalProductPrice);

    if (productToDecreaseQuantity.complementos.length) {
      productToDecreaseQuantity.complementos = decreaseComplementQuantity(
        productToDecreaseQuantity.complementos,
        newProductQuantity
      );
    }

    const index = productsOrders.findIndex(
      (product) =>
        product.product_unique_id ===
          productToDecreaseQuantity.product_unique_id &&
        product.codigo === productToDecreaseQuantity.codigo
    );
    productsOrders[index] = productToDecreaseQuantity;
    const productsInCart = deepClone(productsOrders);
    setProductsOrders(productsInCart);
    EventEmitter.emit('update-cart', productsInCart);
  }

  function decreaseComplementQuantity(complements, newQuantity) {
    const newComplementsQuantity = complements.map((complement) => {
      complement.qtd = newQuantity;
      const priceUpdatedWithQty = complement.price.value * newQuantity;
      complement.price.with_qty = priceUpdatedWithQty;
      return complement;
    });
    return newComplementsQuantity;
  }

  function OpenOrderOheckout() {
    EventEmitter.emit('order-checkout', true);
  }

  return (
    <div className="vstack p-2">
      <div className="w-100 hstack justify-content-between align-items-center">
        <span className="fw-bold fs-5">Itens:</span>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => close(false)}
        ></button>
      </div>
      <div className="container-orders-menu">
        {orderList.map((product) => {
          return (
            <ProductCheckout
              key={product.product_unique_id}
              product={product}
              removeProduct={() => removeProductOnCart(product)}
            />
          );
        })}
      </div>

      <div className="hstack w-100 justify-content-between py-2 fw-bold text-success">
        <span>Total</span>
        <div className="hstack">
          <span className="fs-8">R$</span>
          <span className="price">{totalPrice}</span>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary w-100 fw-bold mt-1"
        onClick={() => OpenOrderOheckout()}
      >
        Escolher forma de pagamento
      </button>
    </div>
  );
}
