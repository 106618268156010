import { httpService } from './HttpService';

const PATH = 'v1/assinatura';
const URI = process.env.REACT_APP_ASSINATURAS_WS_URL;

export const addCliente = async (params) => {
  try {
    const response = await httpService(
      PATH,
      'add-cliente',
      {
        ...params,
      },
      false,
      'post',
      URI
    );
    if (!response) throw response;
    return response;
  } catch (error) {
    return error;
  }
};

export const plans = async () => {
  try {
    const response = await httpService(PATH, 'planos', null, false, 'get', URI);
    if (!response) throw response;
    return response;
  } catch (error) {
    return error;
  }
};

export const cancelPlan = async (externalReference) => {
  try {
    const response = await httpService(
      PATH,
      'cancel-subscription',
      {
        id: externalReference,
      },
      false,
      'post',
      URI
    );
    if (!response) throw response;
    return response;
  } catch (error) {
    return error;
  }
};

export const subscription = async (type, method, creditCardParams = {}) => {
  try {
    const response = await httpService(
      PATH,
      'subscription',
      prepareSubscriptionPayload(type, method, creditCardParams),
      false,
      'post',
      URI
    );
    if (!response) throw response;
    return response;
  } catch (error) {
    return error;
  }
};

function prepareSubscriptionPayload(planCode, method, creditCardParams) {
  const idPlan = planCode === 'ANNUAL' ? 8 : 7;
  return {
    id_plano: idPlan,
    metodo: method,
    cartao_token: creditCardParams.token ?? null,
    cartao: {
      cartao_numero: creditCardParams.cc_number ?? null,
      cartao_cvv: creditCardParams.cc_cvv ?? null,
      cartao_validade: creditCardParams.cc_expiration ?? null,
      cartao_nome: creditCardParams.cc_name ?? null,
    },
  };
}

export const planStatus = async () => {
  try {
    const response = await httpService(PATH, '', null, false, 'get', URI);
    if (!response) throw response;
    return response;
  } catch (error) {
    return error;
  }
};

export const newPlan = async (id_plan) => {
  try {
    const response = await httpService(
      PATH,
      'assinatura',
      {
        id_plan,
        metodo: 'PIX',
      },
      false,
      'post',
      URI
    );
    if (!response) throw response;
    return response;
  } catch (error) {
    return error;
  }
};

export const history = async () => {
  try {
    const response = await httpService(
      PATH,
      'historico',
      null,
      false,
      'get',
      URI
    );
    if (!response) throw response;
    return response;
  } catch (error) {
    return error;
  }
};

export const findCreditCards = async () => {
  try {
    const response = await httpService(PATH, 'cartao', null, false, 'get', URI);
    if (!response) throw response;
    return response;
  } catch (error) {
    return error;
  }
};

export const removeCreditCard = async (token) => {
  try {
    const response = await httpService(
      PATH,
      `cartao?token=${token}`,
      null,
      false,
      'delete',
      URI
    );
    if (!response) throw response;
    return response;
  } catch (error) {
    return error;
  }
};

export const updatePaymentMethod = async (
  subscriptionID,
  method,
  externalReference
) => {
  try {
    const response = await httpService(
      PATH,
      'subscription',
      {
        id: subscriptionID,
        metodo: method,
        external_reference: externalReference,
      },
      false,
      'put',
      URI
    );
    if (!response) throw response;
    return response;
  } catch (error) {
    return error;
  }
};
