import { getItemInBrowserStorage, removeItemFromSession } from '../Utils/Index';
import { httpService } from './HttpService';

export const getStore = async (nome, verifySession = false) => {
  if (verifySession) {
    const data = getItemInBrowserStorage(`${nome}_BY_REDIR`);
    if (data) {
      removeItemFromSession(`${nome}_BY_REDIR`);
      return data;
    }
  }
  const data = await httpService('consumer/store', 'info', { nome }, true);
  return data;
};

export const getGroupsByProduct = async (cod_produto) => {
  const data = await httpService(
    'painel/gestor',
    'getGroupsByProduct',
    { cod_produto },
    true
  );
  return data;
};

export const getItemsByIdGroup = async (codigo) => {
  const data = await httpService(
    'painel/gestor',
    'getItemsByIdGroup',
    { codigo },
    true
  );
  return data;
};

export const getComplements = async (codigo) => {
  const data = await httpService(
    'painel/gestor',
    'getComplements',
    { codigo },
    true
  );
  return data;
};

export const newOrder = async (orderDetails) => {
  const data = await httpService(
    'consumer/store',
    'newOrder',
    { ...orderDetails },
    true
  );
  return data;
};

export const logout = () => {
  removeItemFromSession('CART');
  removeItemFromSession('PAYMENT');
  removeItemFromSession('USER_INFS', false);
  removeItemFromSession('USER_TOKEN', false);
  window.location.reload();
  return true;
};
