const documentations = [
  {
    type: 'Como mexer no painel do estabelecimento',
    documentation_url:
      'https://deliverylegal.notion.site/Painel-51f06106daef4ae7b52edacde45cbc6d',
    documentations: [],
  },
  {
    type: 'Como configurar o cardápio do estabelecimento',
    documentation_url:
      'https://deliverylegal.notion.site/Card-pio-7e83b13868624a4cbdbed77034a856ab',
    documentations: [],
  },
  {
    type: 'Como configurar o estabelecimento',
    documentation_url:
      'https://deliverylegal.notion.site/Configura-es-90dadbda478a4e7d88b94cc9e014b75a',
    documentations: [
      {
        type: 'Enviar/Alterar logotipo',
        documentation_url:
          'https://deliverylegal.notion.site/Logotipo-e88463ad44144ed3981f0b541512dbba',
      },
      {
        type: 'Alterar link de acesso',
        documentation_url:
          'https://deliverylegal.notion.site/Link-de-acesso-17380577030b42be8aeab24a190ec585',
      },
      {
        type: 'Alterar a cor do estabelecimento',
        documentation_url:
          'https://deliverylegal.notion.site/Como-alterar-a-cor-do-estabelecimento-069a0f7051f24a4abc43688b52831836',
      },
      {
        type: 'Configurar horário de funcionamento',
        documentation_url:
          'https://deliverylegal.notion.site/Definir-hor-rio-de-funcionamento-b8582a16d20740d1ad44894515ce0847',
      },
      {
        type: 'Configurar o endereço do estabelecimento',
        documentation_url:
          'https://deliverylegal.notion.site/Definir-endere-o-do-estabelecimento-2ad6cda07ba248e1af92b616a3807232',
      },
      {
        type: 'Definir tempo para entrega/retirada',
        documentation_url:
          'https://www.notion.so/deliverylegal/Definir-estimativa-de-entrega-retirada-5b5c60be3ccf45febbfae44e2615a35f',
      },
      {
        type: 'Definir taxa de entrega de acordo com a distância',
        documentation_url:
          'https://www.notion.so/deliverylegal/Definir-a-taxa-de-entrega-5c7c0ef778b54d9c8faed0100fb78ca1',
      },
      {
        type: 'Definir métodos de pagamentos aceitos',
        documentation_url:
          'https://www.notion.so/deliverylegal/Definir-m-todos-de-pagamento-0058a853529d419ba480d5d4b895c1fa',
      },
    ],
  },
  {
    type: 'Como funciona as categorias',
    documentation_url:
      'https://deliverylegal.notion.site/Categorias-5a16faafcc3345f1bb8f4ece907af45e',
    documentations: [
      {
        type: 'Como criar uma categoria',
        documentation_url:
          'https://deliverylegal.notion.site/Como-criar-uma-nova-categoria-dad51b22b8d4483d9320e0b6efd00ddb',
      },
      {
        type: 'Como editar uma categoria',
        documentation_url:
          'https://deliverylegal.notion.site/Como-editar-uma-categoria-6b182bfbe72f45feb8cb2ec73217f556',
      },
      {
        type: 'Como excluir uma categoria',
        documentation_url:
          'https://deliverylegal.notion.site/Como-excluir-uma-categoria-5e43489d8a464354811ab4626631898e',
      },
    ],
  },
  {
    type: 'Como funciona os produtos',
    documentation_url:
      'https://deliverylegal.notion.site/Produtos-a72e6924e94e47d98be0d98d58923a97',
    documentations: [
      {
        type: 'Como criar um produto',
        documentation_url:
          'https://deliverylegal.notion.site/Como-criar-um-produto-9bdc3a286e114a80b8aaccd0da4f81a1',
      },
      {
        type: 'Como editar um produto',
        documentation_url:
          'https://deliverylegal.notion.site/Como-editar-um-produto-809a730b833a48c482e12122f461504b',
      },
      {
        type: 'Como excluir um produto',
        documentation_url:
          'https://deliverylegal.notion.site/Como-excluir-um-produto-0b886b4ace4c4da99774caf7f14d69c0',
      },
    ],
  },
  {
    type: 'Como funciona os grupos de complementos',
    documentation_url:
      'https://deliverylegal.notion.site/Grupo-de-complementos-4db8b576929e4cc3b702a87c5c410ca2',
    documentations: [
      {
        type: 'Como criar um grupo de complementos',
        documentation_url:
          'https://deliverylegal.notion.site/Como-criar-um-grupo-de-complementos-c9efe9f4b97f4feb84ec9a45b0a55da4',
      },
      {
        type: 'Como adicionar um grupo de complementos em um produto',
        documentation_url:
          'https://deliverylegal.notion.site/Como-adicionar-um-grupo-de-complementos-existente-em-um-produto-3abefc7fc5b34a38917f2fe8faeade34',
      },
      {
        type: 'Como editar um grupo de complementos',
        documentation_url:
          'https://deliverylegal.notion.site/Como-editar-um-grupo-de-complementos-401241350a344921b2352e12898fa5e1',
      },
      {
        type: 'Como desassociar um grupo de complementos do produto',
        documentation_url:
          'https://deliverylegal.notion.site/Como-desassociar-um-grupo-de-complementos-do-produto-470e7676be0340019640abc417026bcf',
      },
    ],
  },
  {
    type: 'Como funciona os complementos',
    documentation_url:
      'https://deliverylegal.notion.site/Complemento-add33d698e7e4dd2b7a59c422c646d41',
    documentations: [
      {
        type: 'Como criar um complemento',
        documentation_url:
          'https://deliverylegal.notion.site/Como-adicionar-complemento-em-um-grupo-de-complementos-e74eba556a524f29b5030d03db8f4a68',
      },
      {
        type: 'Como editar um complemento',
        documentation_url:
          'https://deliverylegal.notion.site/Como-editar-o-complemento-de-um-grupo-de-complementos-f4cc1daec42b4b81ae3a96df5c17d101',
      },
      {
        type: 'Como excluir um complemento',
        documentation_url:
          'https://deliverylegal.notion.site/Como-excluir-um-complemento-de-um-grupo-de-complementos-2a200e2b48534fbfb85db3f655d1f24d',
      },
    ],
  },
];

export default documentations;
