import React from 'react';

function AnnualPlanCard({ onSelectPlan, blockButton }) {
  function onSelectedPlan() {
    if (blockButton) return;
    onSelectPlan();
  }

  return (
    <div className="plans-container__card annual">
      <div className="plans-container__card-content">
        <h2 className="plans-container__card-title">Plano anual</h2>

        <div className="plans-container__card__price">
          <h1 className="plans-container__card__price-value">R$ 35,00</h1>
          <h3 className="plans-container__card__price-period">/mês</h3>
        </div>

        <button
          type="button"
          className="plans-container__card-buy-plan-btn"
          onClick={() => onSelectedPlan()}
          disabled={blockButton}
          style={{ opacity: blockButton ? 0.75 : 1 }}
        >
          Assinar plano
        </button>
      </div>
      <button
        type="button"
        className="plans-container__card-footer-btn"
        onClick={() =>
          window.open('https://delivery.legal/home#plans', '_blank')
        }
      >
        Ver todas as funcionalidades
      </button>
    </div>
  );
}

export default AnnualPlanCard;
