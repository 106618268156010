import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

import { getHistoryProposal } from '../../services/SalesRepresentativeService';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { maskCurrencyBRL } from '../../Utils/Index';
import { Skeleton } from '@material-ui/lab';
import { SalesRepresentativeInfs } from '../SalesRepresentativeProvider/SalesRepresentativeProvider';

function ProposalHistory() {
  const [submittedProposal, setSubmittedProposal] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const fakeData = [0, 0, 0, 0, 0, 0];

  const statusPayment = {
    1: 'Aguardando pagamento',
    2: 'Em análise',
    3: 'Paga',
    4: 'Disponível',
    5: 'Em disputa',
    6: 'Devolvida',
    7: 'Cancelada',
  };

  const { proposalsHistory, setProposalsHistory } = useContext(
    SalesRepresentativeInfs
  );

  useEffect(() => {
    if (!proposalsHistory) {
      getAllSubmittedProposals();
      return;
    }
    setSubmittedProposal(proposalsHistory);
    setLoaded(true);
    getAllSubmittedProposals();
  }, []);

  async function getAllSubmittedProposals() {
    const response = await getHistoryProposal();

    if (!response || !response.success) {
      setLoaded(true);
      toast.error(
        'Ocorreu um erro ao buscar o histórico de envios, tente novamente ou entre em contato conosco.'
      );
      return;
    }

    if (response.results.length) {
      formatData(response.results);
      return;
    }

    setLoaded(true);
  }

  function formatData(proposals) {
    const allProposals = proposals.map((proposal) => {
      proposal.dt_envio = new Date(proposal.dt_envio).toLocaleDateString(
        'pt-BR'
      );
      proposal.valor = maskCurrencyBRL(proposal.valor);
      proposal.status = statusPayment[proposal.status];
      return proposal;
    });
    setProposalsHistory(allProposals);
    setSubmittedProposal(allProposals);
    setLoaded(true);
  }

  return (
    <div>
      <h1 className="sales-representative-section-header">
        Histórico de envios
      </h1>
      <div>
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell className="labelColumn" align="left">
                  Nome
                </TableCell>
                <TableCell className="labelColumn" align="center">
                  CNPJ/CPF
                </TableCell>
                <TableCell className="labelColumn" align="center">
                  Data envio
                </TableCell>
                <TableCell className="labelColumn" align="center">
                  Código referência
                </TableCell>
                <TableCell className="labelColumn" align="center">
                  Valor
                </TableCell>
                <TableCell className="labelColumn" align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loaded && submittedProposal.length ? (
                <>
                  {submittedProposal.map((proposal, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left" style={{ maxWidth: 250 }}>
                          {proposal.nome}
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: 150 }}>
                          {proposal.documento}
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: 150 }}>
                          {proposal.dt_envio}
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: 250 }}>
                          {proposal.cod_referencia}
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: 150 }}>
                          {proposal.valor}
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: 150 }}>
                          {proposal.status}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : loaded ? (
                <span>Não há propostas enviadas</span>
              ) : (
                <>
                  {fakeData.map((_, index) => {
                    return (
                      <TableRow key={index}>
                        {fakeData.map((_, index2) => {
                          return (
                            index <= 5 && (
                              <TableCell
                                key={index2}
                                align="left"
                                style={{ maxWidth: 250 }}
                              >
                                <Skeleton
                                  animation="wave"
                                  variant="rect"
                                  height={18}
                                  width={'100%'}
                                  style={{
                                    border: 'none',
                                    borderRadius: 8,
                                    maxWidth: 450,
                                  }}
                                />
                              </TableCell>
                            )
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default ProposalHistory;
