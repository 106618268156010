import { getItemInBrowserStorage } from '../Utils/Index';
import { httpService } from './HttpService';

export const newOrder = async (codigo) => {
  const data = await httpService('painel/gestor', 'newOrder', { codigo });
  return data;
};

export const getPaymentsMethod = async (cod_estabelecimento) => {
  const result = getItemInBrowserStorage(
    `${cod_estabelecimento}_PAYMENTS_ACCEPT`
  );
  if (result?.length) return result;
  const response = await httpService(
    'consumer/store',
    'payments',
    { cod_estabelecimento },
    true
  );
  if (!response || !response.success) throw response;
  if (!response.results?.length) return [];
  const options = response.results.map((payment) =>
    handlePaymentInfos(payment)
  );
  return options;
};

function handlePaymentInfos(payment) {
  const { pagamento_grupo: group } = payment;
  if (group === 'Outros') {
    payment.tooltip = payment.pagamento_tipo;
    return payment;
  }

  if (group.includes('Vale')) {
    const prefix = group.includes('alimentação') ? 'VA' : 'VR';
    payment.tooltip = `${prefix} - ${payment.pagamento_tipo}`;
    return payment;
  }

  payment.tooltip = `${payment.pagamento_tipo} - ${group}`;
  return payment;
}
