import { Skeleton } from '@material-ui/lab';

function SkeletonCardHistoryOrder() {
  return (
    <div style={{ width: '100%' }}>
      <Skeleton
        animation="wave"
        variant="rect"
        height={110}
        width={'100%'}
        style={{
          border: 'none',
          borderRadius: 8,
          marginBottom: 16,
          maxWidth: 450,
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        height={110}
        width={'100%'}
        style={{
          border: 'none',
          borderRadius: 8,
          marginBottom: 16,
          maxWidth: 450,
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        height={110}
        width={'100%'}
        style={{
          border: 'none',
          borderRadius: 8,
          marginBottom: 16,
          maxWidth: 450,
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        height={110}
        width={'100%'}
        style={{
          border: 'none',
          borderRadius: 8,
          marginBottom: 16,
          maxWidth: 450,
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        height={110}
        width={'100%'}
        style={{
          border: 'none',
          borderRadius: 8,
          marginBottom: 16,
          maxWidth: 450,
        }}
      />
    </div>
  );
}

export default SkeletonCardHistoryOrder;
