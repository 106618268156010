import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  getItemInBrowserStorage,
  LOGO_IMAGE_URL,
  onlyNumbers,
  passwordSecurity,
  removeItemFromSession,
  saveInBrowserStorage,
  validCNPJ,
  validCpf,
  validStepsPassword,
} from '../../Utils/Index';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { StoreOwner } from '../Provider/AuthenticationProvider';
import { httpService } from '../../services/HttpService';
import ReCAPTCHA from 'react-google-recaptcha';
import InputV2 from '../../shared/InputV2/InputV2';
import { createStoreDashboard, login } from '../../services/AuthService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import OnboardingContent from './OnboardingContent';

function StoreCreateAccount() {
  const history = useHistory();
  const formRef = useRef();
  const recaptchaRef = useRef();

  const [blockSubmitButton, setBlockSubmitButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);
  const [errorOnCreate, setErrorOnCreate] = useState(false);
  const [errorAcm, setErrorAcm] = useState(1);
  const [formValues, setFormValues] = useState(null);

  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [secundsToRetry, setSecundsToRetry] = useState('30 segundos');

  const [step, setStep] = useState(1);
  const [countdownTimeout, setCountdownTimeout] = useState(0);

  const [passwordSecurityStatus, setPasswordSecurityStatus] = useState({
    label: null,
    color: null,
  });
  const [buttonsLabel, setButtonsLabel] = useState({
    back: 'Voltar para login',
    next: 'Ativar',
  });
  const [feedback, setFeedback] = useState({
    title:
      'Precisamos de algumas informações para configurar seu estabelecimento',
    subtitle: '',
  });

  const { storeData, setStoreData } = useContext(StoreOwner);

  useEffect(() => {
    const qtyErrors = getItemInBrowserStorage('TIMEOUT_LOGIN', false);
    if (qtyErrors) countToRetry(qtyErrors);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  function countToRetry(qtyErrors) {
    let totalInterval = parseInt(qtyErrors * 30);
    setSecundsToRetry(`${totalInterval} segundos`);
    setBlockSubmitButton(true);
    saveInBrowserStorage('TIMEOUT_LOGIN', qtyErrors, false);
    clearInterval(countdownTimeout);
    const interval = setInterval(() => {
      totalInterval--;
      setSecundsToRetry(`${totalInterval} segundos`);
      if (totalInterval === 0) {
        clearInterval(countdownTimeout);
        setBlockSubmitButton(false);
        removeItemFromSession('TIMEOUT_LOGIN', false);
      }
    }, 1000);
    setCountdownTimeout(interval);
  }

  function backward() {
    if (step === 1 || step === 3) {
      history.push('/login');
      return;
    }
    setStep(step - 1);
  }

  async function handleSubmit(stepData) {
    if (step === 3) {
      if (errorOnCreate) {
        errorAcm <= 3 ? createStore() : backward();
        return;
      }
      authenticated ? history.push(`/primeiro-acesso`) : backward();
      return;
    }

    setLoading(true);
    const isValid = await verifyFormValues(step, stepData);
    setLoading(false);
    if (!isValid) return;

    if (step === 1) {
      setStoreData(stepData.store);
      setStep(2);
      setButtonsLabel({ back: 'Voltar', next: 'Confirmar' });
      return;
    }

    createStore();
  }

  async function verifyFormValues(step, field) {
    try {
      const storeDataSchema = Yup.object().shape({
        store: Yup.object().shape({
          nome_estabelecimento: Yup.string().required(
            'Nome do estabelecimento é obrigatório'
          ),
          cpf_cnpj: Yup.string()
            .required('CNPJ/CPF é obrigatório')
            .verifyDocument('Documento inválido')
            .verifyIsUniqueData('Documento cadastrado em outra conta'),
          telefone: Yup.string()
            .required('Telefone é obrigatório')
            .matches(/[0-9]{2}\s[0-9]{5}-[0-9]{4}/, 'Telefone inválido')
            .verifyIsUniqueData('Telefone cadastrado em outra conta'),
          segmento: Yup.string().required('Segmento é obrigatório'),
        }),
      });

      const loginSchema = Yup.object().shape({
        login: Yup.object().shape({
          email: Yup.string()
            .required('E-mail é obrigatório')
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              'Email inválido'
            )
            .verifyIsUniqueData('E-mail cadastrado em outra conta'),
          email_confirmacao: Yup.string()
            .required('Confirmação seu e-mail')
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              'Email inválido'
            )
            .oneOf([Yup.ref('email'), 'Teste'], 'Os e-mails não conferem'),
          senha: Yup.string()
            .required('Senha é obrigatória')
            .min(6, 'A senha não atinge os requisitos mínimos de segurança'),
          senha_confirmacao: Yup.string()
            .required('Confirme sua senha')
            .oneOf([Yup.ref('senha'), 'Teste'], 'As senhas não conferem'),
        }),
      });

      let schema;
      if (step === 1) schema = storeDataSchema;
      if (step === 2) schema = loginSchema;

      await schema.validate(field, { abortEarly: false });
      formRef.current.setErrors({});
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  Yup.addMethod(Yup.string, 'verifyDocument', function (errorMessage) {
    return this.test(
      `verify-is-valid-document`,
      errorMessage,
      function (document) {
        return validCpf(document) || validCNPJ(document);
      }
    );
  });

  Yup.addMethod(Yup.string, 'verifyIsUniqueData', function (errorMessage) {
    return this.test(
      `verify-is-unique-document`,
      errorMessage,
      async function (value) {
        const { path } = this;
        const fieldValue =
          path.split('.')[1] === 'email' ? value : onlyNumbers(value);
        return new Promise((resolve) => {
          httpService('painel/gestor', 'verifyUniqueData', {
            field: path.split('.')[1],
            value: fieldValue,
          })
            .then((response) => {
              if (response?.erro) throw response;
              resolve(response.success);
            })
            .catch((error) => {
              toast.error(
                `Ocorreu um erro ao realizar a validação do campo, por favor tente novamente ou entre em contato conosco`
              );
              resolve(false);
            });
        });
      }
    );
  });

  function handlePasswordSecurity(password) {
    let acmSecurity = 0;
    const validatorsPassword = validStepsPassword(password, true);
    Object.keys(validatorsPassword).forEach((validator) => {
      if (validatorsPassword[validator]) acmSecurity++;
    });
    setPasswordSecurityStatus(passwordSecurity(acmSecurity));
  }

  function getFormValues() {
    if (formValues) return formValues;
    const { login } = formRef.current.getData();
    storeData.cpf_cnpj = onlyNumbers(storeData.cpf_cnpj);
    storeData.telefone = onlyNumbers(storeData.telefone);
    setFormValues({ ...login, ...storeData });
    return { ...login, ...storeData };
  }

  function createStore() {
    setLoading(true);
    const params = getFormValues();
    createStoreDashboard(params)
      .then((response) => {
        setStep(3);
        setLoading(false);
        if (!response || !response.success) {
          errorOnCreateStore();
          return;
        }
        onCreatedAccount(params);
      })
      .catch((error) => {
        errorOnCreateStore();
        console.error(`${new Date().toISOString()}|`, error);
      })
      .finally(() => {
        setStep(3);
        setLoading(false);
      });
  }

  function errorOnCreateStore() {
    setErrorAcm(errorAcm + 1);
    setErrorOnCreate(true);
    setFeedback({
      title: 'Ocorreu um erro ao realizar o cadastro do seu estabelecimento :(',
      subtitle:
        'Por favor tente novamente ou entre em contato com o nosso suporte.',
    });

    setButtonsLabel({
      next: 'Tentar novamente',
      back: 'Sair',
    });
  }

  async function onCreatedAccount(params) {
    setErrorOnCreate(false);
    const { email, senha } = params;
    const response = await login(email, senha, recaptchaToken);
    const authenticated = response && response.success;
    setAuthenticated(authenticated);
    setButtonsLabel({
      next: authenticated
        ? 'Configurar estabelecimento'
        : 'Acessar estabelecimento',
      back: 'Ir para página de login',
    });
    setFeedback({
      title: 'Estabelecimento cadastrado com sucesso :)',
      subtitle: `Clique em ${
        authenticated ? 'Configurar estabelecimento' : 'Acessar estabelecimento'
      } para acessar a plataforma e realizar as configurações iniciais do seu estabelecimento!`,
    });
  }

  return (
    <OnboardingContent>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className={loading ? 'pe-none opacity-50' : ''}
        style={{ maxWidth: 500 }}
      >
        <img
          src={LOGO_IMAGE_URL}
          alt="Logotipo do Delivery.Legal"
          width={300}
          className="mb-5"
        />

        <div className="vstack">
          <div className="vstack gap-1">
            <h3 className="fw-bold fs-5 text-center mb-3">{feedback.title}</h3>
            {feedback.subtitle && (
              <span className="fs-6">{feedback.subtitle}</span>
            )}
          </div>

          <div className="vstack gap-3">
            {step === 1 && (
              <Scope path="store">
                <InputV2
                  label="Nome do estabelecimento"
                  name="nome_estabelecimento"
                  type="text"
                  maxLength={50}
                  placeholder="Nome do estabelecimento"
                />
                <div className="w-100 hstack justify-content-between gap-1">
                  <InputV2
                    label="CPF/CNPJ"
                    name="cpf_cnpj"
                    type="text"
                    placeholder="CPF/CNPJ"
                    mask="DOCUMENT"
                    maxLength={18}
                    containerClass="w-50"
                  />

                  <InputV2
                    label="WhatsApp/Telefone"
                    name="telefone"
                    type="text"
                    placeholder="WhatsApp/Telefone"
                    mask="PHONE"
                    maxLength={13}
                    containerClass="w-50"
                  />
                </div>
                <InputV2
                  label="Segmento"
                  name="segmento"
                  placeholder="Segmento Ex: Pizzaria, Farmácia, Mercado..."
                  maxLength={50}
                />
              </Scope>
            )}

            {step === 2 && (
              <Scope path="login">
                <div className="hstack gap-2">
                  <InputV2
                    name="email"
                    type="email"
                    label="E-mail"
                    placeholder="E-mail"
                    maxLength={50}
                    containerClass="w-50"
                  />

                  <InputV2
                    name="email_confirmacao"
                    type="email"
                    label="Confirme seu E-mail"
                    placeholder="Confirme seu E-mail"
                    maxLength={50}
                    containerClass="w-50"
                  />
                </div>

                <div className="hstack gap-2">
                  <InputV2
                    label="Senha"
                    name="senha"
                    type={displayPassword ? 'text' : 'password'}
                    placeholder="Senha"
                    onUpdateValue={(text) => handlePasswordSecurity(text)}
                    containerClass="position-relative w-50"
                    maxLength={75}
                  >
                    <button
                      type="button"
                      className="z-2 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
                      style={{ top: 30, right: 4 }}
                      onClick={() => setDisplayPassword(!displayPassword)}
                    >
                      <span className="material-symbols-outlined fs-5">
                        {displayPassword ? 'visibility' : 'visibility_off'}
                      </span>
                    </button>
                  </InputV2>

                  <InputV2
                    label="Confirme sua senha"
                    name="senha_confirmacao"
                    type={displayConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirme sua senha"
                    containerClass="position-relative w-50"
                    maxLength={75}
                  >
                    <button
                      type="button"
                      className="z-2 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
                      style={{ top: 30, right: 4 }}
                      onClick={() =>
                        setDisplayConfirmPassword(!displayConfirmPassword)
                      }
                    >
                      <span className="material-symbols-outlined fs-5">
                        {displayConfirmPassword
                          ? 'visibility'
                          : 'visibility_off'}
                      </span>
                    </button>
                  </InputV2>
                </div>

                {passwordSecurityStatus.label && (
                  <div className="w-100 text-center">
                    <span
                      className="fs-7 fw-bold text-center"
                      style={{ color: passwordSecurityStatus.color }}
                    >
                      {passwordSecurityStatus.label}
                    </span>
                  </div>
                )}
              </Scope>
            )}
          </div>
        </div>

        <div className="d-flex flex-column gap-2 my-4">
          {blockSubmitButton && (
            <span className="fw-bold w-100 gap-1 text-danger text-center mt-2">
              aguarde {secundsToRetry} para tentar novamente
            </span>
          )}

          <div
            className="my-2 w-100 justify-content-center"
            style={{
              display: step === 1 ? 'flex' : 'none',
            }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              size="normal"
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_CREATE_ACCOUNT}
              onChange={(token) => setRecaptchaToken(token)}
              onExpired={() => setRecaptchaToken()}
              onError={() => setRecaptchaToken()}
            ></ReCAPTCHA>
          </div>

          <button
            type="submit"
            className={`btn btn-primary ${
              blockSubmitButton || !recaptchaToken ? 'disabled-btn' : ''
            }`}
            disabled={blockSubmitButton || !recaptchaToken}
          >
            {buttonsLabel.next}
          </button>

          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => backward()}
          >
            {buttonsLabel.back}
          </button>
        </div>
      </Form>
    </OnboardingContent>
  );
}

export default StoreCreateAccount;
