import React, { useEffect, useState } from 'react';
import './PlanBillingInfos.scss';
import {
  cardsFlags,
  maskCurrencyBRLWithSymbolString,
} from '../../../../Utils/Index';
import {
  cancelPlan,
  findCreditCards,
  removeCreditCard,
  updatePaymentMethod,
} from '../../../../services/Assinaturas';
import { toast } from 'react-toastify';
import PlanHistory from './components/PlanHistory';
import { IconTrash } from '../../../../assets/Icons';

function PlanBillingInfos({ plan }) {
  const tabs = [
    {
      id: 'SIGNATURE',
      label: 'Minhas assinatura',
      active: true,
    },
    {
      id: 'CARDS',
      label: 'Meus cartões',
      active: false,
    },
    {
      id: 'HISTORY',
      label: 'Histórico',
      active: false,
    },
  ];

  const cardTabs = [
    {
      id: 'CARD',
      label: 'Cartão',
    },
    {
      id: 'PATTERN',
      label: 'Bandeira',
    },
    {
      id: 'STATUS',
      label: 'Situação',
    },
  ];

  const [activeTab, setActiveTab] = useState('SIGNATURE');
  const [planInfos, setPlanInfos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setPlanInfos(plan);
    getCreditCards();
    setLoading(false);
  }, [plan]);

  async function onChangePaymentMethod(method) {
    if (method === plan.metodo) return;
    const response = await updatePaymentMethod(
      plan.id,
      method,
      plan.external_reference
    );
    if (response.message === 'ok' || response.success) {
      planInfos.metodo = method;
      setPlanInfos(planInfos);
      toast.error('Forma de pagamentoa atualizada com sucesso');
      return;
    }
    toast.error(
      'Ocorreu um erro ao alterar o método de pagamento, por favor tente novamente.'
    );
  }

  async function callCancelPlan() {
    const response = await cancelPlan(plan.external_reference);
    if (response) window.alert('Seu plano foi cancelado :(');
  }

  async function getCreditCards() {
    const response = await findCreditCards();
    if (!response || !response?.length) return;
    setCards(response);
  }

  async function deleteCreditCard(card) {
    const response = await removeCreditCard(card.token);
    if (response.message === 'OK') {
      toast.success('Cartão removido com sucesso');
      getCreditCards();
      return;
    }
    toast.error(
      'Ocorreu um erro ao remover o cartão, tente novamente mais tarde'
    );
  }

  return (
    <div className="plan-billing-infos">
      <div className="plan-billing-infos-tabs">
        {tabs.map((tab) => (
          <span
            className={
              tab.id === activeTab ? 'plan-billing-infos-tabs-active' : ''
            }
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </span>
        ))}
      </div>

      {loading ? (
        <div>Carregando conteúdo</div>
      ) : (
        <>
          {activeTab === 'SIGNATURE' && (
            <div className="plan-billing-infos__signature">
              {planInfos.permitir_cobranca ? (
                <>
                  <div className="plan-billing-infos__signature__content">
                    <span>Data da próxima cobrança:</span>
                    <span>
                      {new Date(planInfos.proxima_cobranca).toLocaleDateString(
                        'pt-BR'
                      )}
                    </span>
                  </div>
                  <div className="plan-billing-infos__signature__content">
                    <span>Valor a ser cobrado:</span>
                    <span>
                      {maskCurrencyBRLWithSymbolString(planInfos.valor)}
                    </span>
                  </div>
                  <div className="plan-billing-infos__signature__content">
                    <span>Forma de pagamento atual:</span>
                    <select
                      id="dropdown"
                      value={planInfos.metodo}
                      onChange={(event) =>
                        onChangePaymentMethod(event.target.value)
                      }
                    >
                      <option value="CREDIT_CARD">Cartão de crédito</option>
                      <option value="PIX">Pix</option>
                    </select>
                  </div>

                  <button
                    className="plan-billing-infos__signature-cancel-plan"
                    onClick={() => callCancelPlan()}
                  >
                    Cancelar assinatura
                  </button>
                </>
              ) : (
                <span className="plan-billing-infos__signature-recurring-payment-canceled">
                  Nenhuma cobrança futura será realizada.
                </span>
              )}
            </div>
          )}

          {activeTab === 'CARDS' && (
            <div className="plan-billing-infos__cards">
              <div className="plan-billing-infos-tabs">
                {cardTabs.map((tab) => (
                  <span key={tab.id}>{tab.label}</span>
                ))}
              </div>
              {cards.map((card) => (
                <div
                  key={card.id}
                  className="plan-billing-infos__cards__content"
                >
                  <span>**** **** **** {card.final}</span>
                  <span className="plan-billing-infos__cards__content-pattern">
                    {cardsFlags[card.bandeira.toLowerCase()]} {card.bandeira}
                  </span>
                  <span className="plan-billing-infos__cards__content-pattern__remove">
                    {card.ativo ? 'Ativo' : 'Inativo'}
                    <button
                      className="plan-billing-infos__cards__content-pattern__remove-btn"
                      onClick={() => deleteCreditCard(card)}
                    >
                      <IconTrash size={14} />
                    </button>
                  </span>
                </div>
              ))}
            </div>
          )}

          {activeTab === 'HISTORY' && <PlanHistory />}
        </>
      )}
    </div>
  );
}

export default PlanBillingInfos;
