import React, { useState, useEffect, useContext } from 'react';
import InputRadio from '../../../shared/InputRadio/InputRadio';
import {
  ComplementsContext,
  MenuContext,
  StoreContext,
} from '../../MenuProvider/MenuProvider';
import { maskCurrencyBRLWithoutSymbol } from '../../../Utils/Index';
import { toast } from 'react-toastify';

export default function Complements({
  idProduct,
  qty,
  complements,
  updateComplement,
  updateProduct,
}) {
  const [groupComplement, setGroupComplement] = useState(null);
  const [qtyComplements, setQtyComplements] = useState(1);

  const { storeSettingsProvider } = useContext(StoreContext);
  const { complementsProductSelected, setComplementsProductSelected } =
    useContext(ComplementsContext);
  const { productSelectedProvider, setProductSelectedProvider } =
    useContext(MenuContext);

  useEffect(() => {
    setGroupComplement(complements);
  }, [complements]);

  useEffect(() => {
    setQtyComplements(qty);
    updateQtyComplement(qty);
  }, [qty]);

  function toggleExpadedGroup(groupComplement) {
    updateComplement((groupComplement.expaded = !groupComplement.expaded));
  }

  function selectComplement(complement) {
    const response = groupComplement.items.map((productComplement) => {
      if (productComplement.codigo === complement.codigo)
        productComplement = handlerProductComplement(productComplement);
      groupComplement.is_valid = groupComplement.required
        ? groupComplement.qtd_complement_selected >= groupComplement.qtd_minima
        : true;
      return productComplement;
    });
    const updatedComplement = { ...groupComplement, ...{ items: response } };
    setGroupComplement(updatedComplement);
    updateComplement(updatedComplement);
  }

  function handlerProductComplement(complement) {
    if (!complement.selected) {
      if (groupComplement.qtd_complement_selected < groupComplement.qtd_maxima)
        complement.selected = addComplementsOnProduct(complement);
      else
        toast.warning('Você já selecionou a quantidade máxima de itens.', {
          position: 'bottom-left',
        });
    } else complement.selected = removeComplementsOnProduct(complement);

    return complement;
  }

  function addComplementsOnProduct(complement) {
    groupComplement.qtd_complement_selected += 1;
    const newComplement = {
      cod_complemento: complement.codigo,
      qtd: qtyComplements,
      name: complement.nome,
      price: {
        value: complement.preco,
        formated: complement.formated_price,
        with_qty: complement.preco * qtyComplements,
      },
    };
    const createComplement = {
      id_product: groupComplement.id_product,
      complement_selected: [newComplement],
    };

    if (!complementsProductSelected.length)
      complementsProductSelected.push(createComplement);
    else if (
      !complementsProductSelected.find(
        (selectedComplement) =>
          selectedComplement.id_product === groupComplement.id_product
      )
    )
      complementsProductSelected.push(createComplement);
    else
      complementsProductSelected
        .find(
          (selectedComplement) =>
            selectedComplement.id_product === groupComplement.id_product
        )
        .complement_selected.push(newComplement);

    setOrderPrice(complement.preco, 'increase');
    setComplementsProductSelected(complementsProductSelected);
    return true;
  }

  function removeComplementsOnProduct(complement) {
    groupComplement.qtd_complement_selected -= 1;
    complementsProductSelected
      .find(
        (selectedComplement) =>
          selectedComplement.id_product === groupComplement.id_product
      )
      .complement_selected.pop();
    setOrderPrice(complement.preco, 'decrease');
    setComplementsProductSelected(complementsProductSelected);
    return false;
  }

  function setOrderPrice(price, type) {
    const product = productSelectedProvider.map((product) => {
      if (product.codigo === groupComplement.id_product) {
        const priceOrder = product.count_price
          ? product.count_price.number
          : product.preco;
        const newOrderPrice =
          type === 'increase' ? priceOrder + price : priceOrder - price;
        const newOrderPriceFormated =
          maskCurrencyBRLWithoutSymbol(newOrderPrice);
        product.count_price = {
          number: newOrderPrice,
          formated: newOrderPriceFormated,
        };
      }
      return product;
    });
    setProductSelectedProvider(product);
    updateProduct(
      product.find((product) => product.codigo === groupComplement.id_product)
    );
  }

  function updateQtyComplement(qty) {
    const updateQtyComplements = complementsProductSelected.map(
      (productComplements) => {
        if (productComplements.id_product === idProduct) {
          productComplements.complement_selected.forEach((complement) => {
            complement.qtd = qty;
            complement.price.with_qty = complement.price.value * qty;
          });
        }
        return productComplements;
      }
    );
    setComplementsProductSelected(updateQtyComplements);
    updateComplement(updateQtyComplements);
  }

  return (
    <div>
      <div className="py-2 px-1 w-100 hstack justify-content-between bg-secondary-subtle">
        <div className="vstack">
          <h2 className="fs-5 fw-bold m-0">{groupComplement?.nome}</h2>
          <span className="fs-7 border">{groupComplement?.label}</span>
        </div>

        <div className="hstack gap-2 align-items-center justify-content-center">
          {groupComplement?.required && (
            <>
              {groupComplement?.is_valid ? (
                <span className="material-symbols-outlined text-success">
                  check_circle
                </span>
              ) : (
                <div className="hstack gap-2">
                  <span className="bg-dark bg-opacity-75 p-1 rounded-2 text-white fs-8 fw-bold">
                    Obrigatório
                  </span>
                </div>
              )}
            </>
          )}
          <div
            className="btn btn-primary-fill p-0 m-0 align-items-center hstack"
            onClick={() => toggleExpadedGroup(groupComplement)}
          >
            <span className="material-symbols-outlined">
              {groupComplement?.expaded
                ? 'keyboard_arrow_up'
                : 'keyboard_arrow_down'}
            </span>
          </div>
        </div>
      </div>

      <div>
        {groupComplement?.items.map((complement) => {
          if (!groupComplement?.expaded) return null;
          return (
            <div
              key={complement.codigo}
              className={complement.last_item ? '' : 'border-bottom'}
            >
              <div className="w-100 hstack px-2 justify-content-between align-items-center gap-3 py-2">
                <h5 className="fs-6 text-dark m-0 p-0">{complement.nome}</h5>
                <div className="hstack gap-1">
                  <span className="fs-7">+{complement.formated_price}</span>
                  <InputRadio
                    label=""
                    selected={complement.selected}
                    clicked={() => selectComplement(complement)}
                    customColor={storeSettingsProvider.settings.store.cor}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
