import React from 'react';
import OnboardingContent from './OnboardingContent';
import RecoverPasswordCore from './RecoverPassword';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function StoreRecoverPassword() {
  const history = useHistory();

  return (
    <OnboardingContent>
      <RecoverPasswordCore
        isClientRecover={false}
        goBack={() => history.push('/login')}
      />
    </OnboardingContent>
  );
}

export default StoreRecoverPassword;
