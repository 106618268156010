import React, { useState } from 'react';
import { cardsFlags } from '../../../../../Utils/Index';

function CreditCardBox({ cards, onSelectCard, registerNewCard }) {
  const [selectedCard, setSelectedCard] = useState(null);

  function onSelectCreditCard(card) {
    if (selectedCard && selectedCard.token === card.token) return;
    setSelectedCard(card);
  }

  return (
    <div className="credit-card-box">
      <h3 className="credit-card-box-title">
        Selecione o cartão que irá pagar:
      </h3>

      <div className="credit-card-box__content">
        {cards.map((card, index) => (
          <div
            key={index}
            className={
              selectedCard && selectedCard.token === card.token
                ? 'credit-card-box__content__box credit-card-box__content__box-active'
                : 'credit-card-box__content__box'
            }
            onClick={() => onSelectCreditCard(card)}
          >
            <span className="credit-card-box__content__box-cc-number">
              **** **** **** {card.final}
            </span>
            <div className="credit-card-box__content__box-pattern">
              <span>{card.bandeira}</span>
              <div>{cardsFlags[card.bandeira.toLowerCase()]}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="d-flex flex-column text-center mt-3">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => onSelectCard(selectedCard)}
        >
          Realizar Pagamento
        </button>
        <span>ou</span>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => registerNewCard()}
        >
          Cadastrar um novo cartão
        </button>
      </div>
    </div>
  );
}

export default CreditCardBox;
