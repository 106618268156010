import React from 'react';

function CarouselBanner() {
  function handleToSection() {
    window.document
      .getElementById('howWork')
      .scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="w-100 justify-content-center align-items-center">
      <div id="lpBannerIndicator" className="carousel slide ">
        <div className="carousel-indicators z-3 m-0 opacity-75 d-none">
          <button
            type="button"
            data-bs-target="#lpBannerIndicator"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Image 1"
          ></button>
          <button
            type="button"
            data-bs-target="#lpBannerIndicator"
            data-bs-slide-to="1"
            aria-label="Image 2"
          ></button>
        </div>

        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={`${process.env.REACT_APP_FIREBASE_URL}/assets%2Flanding_page%2Flanding_page_banner1.png?alt=media`}
              className="d-block w-100 cursor-pointer"
              alt="Banner promocional Delivery.Legal"
              width="100%"
              height="100%"
              fetchpriority="high"
              onClick={() => handleToSection()}
            />
          </div>
          <div className="carousel-item">
            <img
              src={`${process.env.REACT_APP_FIREBASE_URL}/assets%2Flanding_page%2Flanding_page_banner2.png?alt=media`}
              className="d-block w-100 cursor-pointer"
              alt="Banner promocional Delivery.Legal"
              width="100%"
              height="100%"
              loading="lazy"
              onClick={() => handleToSection()}
            />
          </div>
        </div>

        <button
          className="carousel-control-prev z-3 p-1 p-md-2 m-0 w-auto"
          type="button"
          data-bs-target="#lpBannerIndicator"
          data-bs-slide="prev"
        >
          <span className="material-symbols-outlined bg-dark bg-opacity-50 rounded-circle">
            chevron_left
          </span>
        </button>

        <button
          className="carousel-control-next z-3 p-1 p-md-2 m-0 w-auto"
          type="button"
          data-bs-target="#lpBannerIndicator"
          data-bs-slide="next"
        >
          <span className="material-symbols-outlined bg-dark bg-opacity-50 rounded-circle">
            chevron_right
          </span>
        </button>
      </div>
    </div>
  );
}

export default CarouselBanner;
