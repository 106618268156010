import { httpService } from './HttpService';

export const getAllCategories = async () => {
  const data = await httpService('painel/gestor', 'getAllCategories', {});
  return data;
};

export const updateCategory = async (codigo, nome, ativo) => {
  const data = await httpService('painel/gestor', 'updateCategory', {
    codigo,
    nome,
    ativo,
  });
  return data;
};
