import React, { useEffect, useState } from 'react';
import { subscription } from '../../../../../services/Assinaturas';
import PixPayment from './PixPayment';
import CreditCardPayment from './CreditCardPayment';
import Loader from './Loader';
import { toast } from 'react-toastify';

function SubscriptionProcess({ params, concludedProcess }) {
  const [isPixPayment, setIsPixPayment] = useState(false);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(true);
  const [paymentInfos, setPaymentInfos] = useState(null);

  useEffect(() => {
    const payByPix = params.payment_method === 'PIX';
    setIsPixPayment(payByPix);
    if (payByPix) getPixQRCode();
    else setLoadingPaymentMethod(false);
  }, [params]);

  async function doSubscription(payByPix, creditCardInfos = {}) {
    if (!payByPix) setLoadingSubscription(true);
    const response = await subscription(
      params.type,
      params.payment_method,
      creditCardInfos
    );

    if (payByPix) {
      if (response.success) setPaymentInfos(response);
      else
        toast.error(
          'Ocorreu um erro ao carregar as informações de pagamento, por favor tente novamente.'
        );
      setLoadingPaymentMethod(false);
      return;
    }

    if (!response || response?.message !== 'OK') {
      setLoadingSubscription(false);
      setPaymentInfos(null);
      toast.error(
        'Ocorreu um erro ao realizar o pagamento, por favor tente novamente.'
      );
      return;
    }
    setPaymentInfos(response);
    setLoadingSubscription(false);
    concludedProcess();
  }

  async function getPixQRCode() {
    doSubscription(true);
    setLoadingPaymentMethod(true);
  }

  function payByCreditCard(isNewCC, params) {
    if (isNewCC) {
      doSubscription(false, params);
      return;
    }
    doSubscription(false, params);
  }

  return (
    <div>
      {loadingPaymentMethod || loadingSubscription ? (
        <Loader
          title={
            loadingSubscription
              ? 'Confirmando a contratação do plano, por favor aguarde'
              : ''
          }
        />
      ) : isPixPayment ? (
        <PixPayment
          code={paymentInfos.payload}
          expiration={paymentInfos.expirationDate}
        />
      ) : (
        <CreditCardPayment
          newCreditCard={(params) => payByCreditCard(true, params)}
          onSelectedCreditCard={(params) => payByCreditCard(false, params)}
        />
      )}
    </div>
  );
}

export default SubscriptionProcess;
