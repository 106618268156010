import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

function Textarea({
  label = '',
  containerStyle,
  labelStyle,
  name,
  callBack,
  controlled = false,
  initialCrontrolledValue = null,
  disabled = false,
  clearValueInput = false,
  labelCustomClass = '',
  ...rest
}) {
  const inputRef = useRef(null);

  const { defaultValue, fieldName, registerField, error } = useField(name);

  const [inputValue, setInputValue] = useState('');
  const [controlledInput, setControlledInput] = useState(false);
  const [inputControls, setInputControls] = useState(null);

  const [customsStylesInLabel, setCustomsStylesInLabel] = useState(null);
  const [customsStylesInContainer, setCustomsStylesInContainer] =
    useState(null);

  useEffect(() => {
    if (clearValueInput && inputValue) setInputValue('');
  }, [clearValueInput]);

  useEffect(() => {
    if (containerStyle !== customsStylesInContainer)
      setCustomsStylesInContainer(containerStyle);
    if (labelStyle !== customsStylesInLabel)
      setCustomsStylesInLabel(labelStyle);
    return () => {
      setCustomsStylesInLabel(null);
      setCustomsStylesInContainer(null);
    };
  }, [containerStyle, labelStyle]);

  useEffect(() => {
    setControlledInput(controlled);
  }, [controlled]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
    setInputControls(rest);
  }, [defaultValue, registerField]);

  useEffect(() => {
    if (initialCrontrolledValue) setInputValue(initialCrontrolledValue);
  }, [initialCrontrolledValue]);

  return (
    <div className="input-content" style={customsStylesInContainer}>
      <label
        htmlFor={name}
        className={`input-content-label ${labelCustomClass}`}
        style={customsStylesInLabel}
      >
        {label}
        {controlledInput ? (
          <div>
            <textarea
              {...inputControls}
              ref={inputRef}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              style={{ borderColor: error && '#F10542' }}
              disabled={disabled}
            ></textarea>
          </div>
        ) : (
          <div>
            <textarea
              {...inputControls}
              ref={inputRef}
              style={{ borderColor: error && '#F10542' }}
              disabled={disabled}
            ></textarea>
          </div>
        )}
      </label>
      {error && <span className="input-content-error-input">{error}</span>}
    </div>
  );
}

export default Textarea;
