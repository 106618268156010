import React, { useState, useEffect } from 'react';
import {
  getTaxDelivery,
  updateTaxDelivery,
} from '../../../services/Configuration';
import {
  maskCurrencyBRLWithoutSymbolString,
  maskCurrencyBRLWithSymbolString,
  onlyNumbers,
} from '../../../Utils/Index';
import { toast } from 'react-toastify';

export default function TaxaDelivery({
  firstAccess = false,
  handleConfigureStore,
}) {
  const [shippingRates, setShippingRates] = useState([]);
  const [km, setKm] = useState(0);
  const [preco, setPreco] = useState(0);

  useEffect(() => {
    callGetShippingRates();
  }, []);

  async function callGetShippingRates() {
    getTaxDelivery()
      .then((response) => {
        setShippingRates(response.results);
      })
      .catch((error) => {
        console.error('DELIVERY_PRICE_ERROR', error);
        toast.error(
          'Ocorreu um erro ao buscar os preços de entrega, por favor tente novamente.'
        );
      });
  }

  function addShippingRateByKm() {
    if (!km || !preco) {
      toast.error('Informe a quilometragem e o valor.');
      return;
    }
    shippingRates.push({
      km: km,
      preco: parseFloat(onlyNumbers(preco)),
      codigo: 0,
      ativo: true,
      index: shippingRates.length,
    });
    const orderedByKm = shippingRates.sort((a, b) => a.km - b.km);
    setShippingRates(structuredClone(orderedByKm));
    setKm(0);
    setPreco(0);
  }

  async function removeShippingRates(codigo, index) {
    if (!codigo) {
      setShippingRates(shippingRates.filter((item) => item.index !== index));
      return;
    }

    updateTaxDelivery({
      codigo: codigo,
      ativo: false,
      km: shippingRates[index].km,
      preco: shippingRates[index].preco,
      index: shippingRates[index].index,
    })
      .then(() => {
        setShippingRates(
          shippingRates.filter((item) => item.codigo !== codigo)
        );
        toast.success('Taxa removida.');
      })
      .catch(() =>
        toast.error(
          'Ocorreu um erro ao removar a taxa de entrega, por favor tente novamente.'
        )
      );
  }

  async function saveShippingRates() {
    const items = shippingRates.filter((item) => item.codigo === 0);
    Promise.all(items.map((items) => updateTaxDelivery(items)))
      .then(() => {
        toast.success('Taxas adicionadas com sucesso.');
        if (firstAccess) handleConfigureStore(true);
      })
      .catch((error) => {
        toast.error(
          'Ocorreu um erro ao salvar as taxas de entrega, por favor tente novamente.'
        );
        console.error('SAVE_SHIPPING_RATES_ERROR', error);
      });
  }

  function handleKmValue(value) {
    setKm(value ? parseFloat(value.replace(/\D/g, '')) : '');
  }

  function handlePriceValue(value) {
    setPreco(value ? maskCurrencyBRLWithoutSymbolString(value) : '');
  }

  return (
    <div className="vstack" style={{ maxWidth: 550 }}>
      <div className="vstack pb-3">
        <h3 className="fs-3 fw-bold m-0 p-0 text-primary">Preço de Entrega</h3>
        <span className="fs-7 text-body-secondary">
          Configure sua taxa de entrega por raio de quilômetros.
        </span>
      </div>

      <div className="d-flex flex-row gap-1 gap-sm-2">
        <div>
          <label htmlFor="distanceKM" className="form-label">
            Distância até (Km)
          </label>
          <input
            type="text"
            className="form-control"
            id="distanceKM"
            placeholder="Distância em KM"
            value={km}
            maxLength={10}
            onChange={(event) => handleKmValue(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="distanceKM" className="form-label">
            Preço
          </label>
          <input
            type="text"
            className="form-control"
            id="distanceKM"
            placeholder="Distância em KM"
            value={preco}
            onChange={(event) => handlePriceValue(event.target.value)}
            maxLength={100}
          />
        </div>

        <button
          className={`btn btn-outline-danger hstack align-items-center ${
            !km || !preco ? 'pe-none opacity-50' : ''
          }`}
          disabled={!km || !preco}
          onClick={() => addShippingRateByKm()}
        >
          <span className="material-symbols-outlined">add</span>
        </button>
      </div>

      <div className="vstack py-3">
        {shippingRates?.map((item, index) => {
          return (
            <div
              key={index}
              className="w-100 border-bottom hstack justify-content-between align-items-center"
            >
              <span>
                até {item.km}km por{' '}
                {maskCurrencyBRLWithSymbolString(item.preco)}
              </span>
              <button
                className="btn btn-primary-fill p-0"
                onClick={() => removeShippingRates(item.codigo, index)}
              >
                <span className="material-symbols-outlined">delete</span>
              </button>
            </div>
          );
        })}
      </div>
      <button
        className="btn btn-primary fw-bold"
        onClick={() => saveShippingRates()}
      >
        Salvar alterações
      </button>
    </div>
  );
}
