import React, { useEffect, useState, useRef } from 'react';
import './Primary.scss';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Textarea from '../../../../shared/Textarea/Textarea';
import DropDown from '../../../../shared/DropDown/DropDown';
import InputV2 from '../../../../shared/InputV2/InputV2';
import {
  deepClone,
  getItemInBrowserStorage,
  getProductImage,
  maskCurrencyBRL,
  PRODUCT_DEFAULT_IMAGE_URL,
  removeMaskPrice,
} from '../../../../Utils/Index';
import { updateProduct } from '../../../../services/Products';
import EventEmitter from '../../../../services/Event';
import { useFormProduct } from '../../ContextProduct/ContextProduct';
import { uploadProductImage } from '../../../../services/Firebase';

function Primary({
  productData,
  categories,
  idCategoryToCreateProduct,
  saveProductData,
}) {
  const [idCategory, setIdCategory] = useState(0);
  const [idProduct, setIdProduct] = useState(0);

  const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [previousProductData, setPreviousProductData] = useState(null);

  const [disableButtons, setDisableButtons] = useState(false);

  const { setSidebar } = useFormProduct();

  const formRef = useRef();

  useEffect(() => {
    if (productData) {
      setPreviousProductData(productData);
      handleInitialFormValues(productData);
      return;
    }
    if (idCategoryToCreateProduct) setIdCategory(idCategoryToCreateProduct);
  }, [productData]); //eslint-disable-line react-hooks/exhaustive-deps

  function handleInitialFormValues(data) {
    const { firebase_path } = getItemInBrowserStorage('CLIENT_DATA', false);
    formRef.current.setData(data);
    setIdProduct(data.codigo);
    setIdCategory(data.cod_categoria);
    setImage(getProductImage(firebase_path, data.cod_categoria, data.codigo));
  }
  async function handleValidityFormFields(field) {
    try {
      const productInfs = Yup.object().shape({
        ativo: Yup.boolean(),
        codigo: Yup.number(),
        nome: Yup.string().required('Informe o nome do produto'),
        descricao: Yup.string(),
        peso: Yup.string(),
        preco: Yup.string().required('Informe o valor do produto'),
      });

      await productInfs.validate(field, { abortEarly: false });
      formRef.current.setErrors({});
      saveProductDetails();
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  function saveProductImage(file) {
    if (Boolean(file) && file.size <= 5242880) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) setImage(reader.result);
      };
      reader.readAsDataURL(file);
      setImage(file);
    } else
      toast.error(
        'Ops! O tamanho da imagem é muito grande, a imagem deve ser de até 5MB.'
      );
  }

  function changeProductCategory(categorySelected) {
    const { codigo } = categorySelected[0];
    setIdCategory(codigo);
  }

  async function saveProductDetails() {
    const productData = getProductData();

    if (!verifyHasChange(productData)) {
      toast.warning('Não há alterações para serem salvas.');
      return;
    }
    callSaveProduct(productData);
  }

  function getProductData() {
    let product = deepClone(formRef.current.getData());
    product.cod_categoria = idCategory;
    product.codigo = idProduct;
    product.ativo = true;
    product.preco = removeMaskPrice(product.preco);
    return product;
  }

  function verifyHasChange(currentProductData) {
    if (typeof image !== 'string') return true;
    if (!previousProductData) return true;

    let someChangeInProductInfs = false;
    const fieldsToVerify = {
      ativo: true,
      codigo: true,
      nome: true,
      descricao: true,
      peso: true,
      preco: true,
      cod_categoria: true,
    };

    Object.keys(currentProductData).forEach((field) => {
      if (fieldsToVerify[field]) {
        if (currentProductData[field] !== previousProductData[field])
          someChangeInProductInfs = true;
      }
    });

    return someChangeInProductInfs;
  }

  async function callSaveProduct(product) {
    setDisableButtons(true);
    const response = await updateProduct({ ...product });

    if (!response || !response.success) {
      setDisableButtons(false);
      toast.error(
        'Ocorreu um erro ao criar o produto, tente novamente ou entre em contato conosco.'
      );
      return;
    }

    product.new_product = Boolean(product.codigo === 0);
    if (product.codigo === 0) product.codigo = response.result.id;

    toast.success(
      `Produto ${product.new_product ? 'criado' : 'editado'} com sucesso`
    );

    if (imageFile) {
      await uploadImage(imageFile, product);
      return;
    }

    EventEmitter.emit('update-products', product);
    saveCurrentData(product);
    setDisableButtons(false);
    return;
  }

  async function uploadImage(file, product) {
    await uploadProductImage(file, product.codigo, product.cod_categoria);
    saveProductData(product);
    setDisableButtons(false);
    EventEmitter.emit('update-products', product);
  }

  function saveCurrentData(productInfo) {
    productInfo.preco = maskCurrencyBRL(productInfo.preco);
    setPreviousProductData(productInfo);
    handleInitialFormValues(productInfo);
    setIdCategory(productInfo.cod_categoria);
    saveProductData(productInfo);
  }

  return (
    <div className="w-100 d-flex flex-column">
      <Form ref={formRef} onSubmit={handleValidityFormFields}>
        <div className="d-flex flex-md-row flex-column gap-md-3">
          <div className="d-flex flex-column gap-2 w-100">
            <div className="pt-1">
              <span className="input-content-label">Categoria</span>
              <DropDown
                items={categories}
                filterItems={changeProductCategory}
                msgAllCategory={false}
                personalizeWidth={true}
                defaultText="Escolha uma categoria"
                openvalue={idCategory}
              />
            </div>

            <InputV2
              label="Nome do produto"
              placeholder="Nome do produto"
              name="nome"
              type="text"
              maxLength={50}
            />

            <Textarea
              label="Descrição"
              name="descricao"
              type="text"
              maxLength={250}
              className="dl-textarea"
              labelCustomClass="w-100"
            />
          </div>

          <div className="d-flex flex-column gap-2 w-100">
            <div>
              <span className="input-content-label" style={{ marginBottom: 8 }}>
                Imagem do produto
              </span>
              <div className="upload-product-image__image">
                <div className="upload-product-image__image__container">
                  <img
                    src={image}
                    className="w-100"
                    alt="Imagem do produto"
                    onError={() => setImage(PRODUCT_DEFAULT_IMAGE_URL)}
                  />

                  <label
                    htmlFor="file-upload"
                    className={
                      image
                        ? 'upload-product-image__image__container__btn-upload'
                        : 'upload-product-image__image__container__btn-upload product__fields-section__right__image__container__btn-upload-has-image'
                    }
                    style={{ position: 'absolute', bottom: 10 }}
                  >
                    Carregar imagem
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => saveProductImage(e.target.files[0])}
                  />
                </div>
              </div>
            </div>

            <InputV2
              label="Peso"
              name="peso"
              type="text"
              placeholder="Peso"
              maxLength={9}
            />

            <InputV2
              label="Preço"
              name="preco"
              type="text"
              placeholder="Valor do produto"
              mask={'CURRENCY'}
              maxLength={10}
            />
          </div>
        </div>

        <div className="w-100 d-flex justify-content-sm-end justify-content-between py-2 gap-3">
          <button
            type="button"
            className={`btn btn-outline-danger ${
              disableButtons && 'disabled-btn'
            }`}
            onClick={() => setSidebar(false)}
            disabled={disableButtons}
          >
            Sair
          </button>
          <button
            type="submit"
            className={`btn btn-primary ${disableButtons && 'disabled-btn'}`}
            disabled={disableButtons}
          >
            Salvar
          </button>
        </div>
      </Form>
    </div>
  );
}

export default Primary;
