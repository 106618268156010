import React from 'react';
import './OnboardingContent.scss';

function OnboardingContent({ children }) {
  return (
    <div className="w-100 vh-100 d-grid dl-onboarding">
      <div className="align-items-center d-flex h-100 justify-content-center p-3 w-100">
        {children}
      </div>
      <div className="position-relative overflow-hidden">
        <div className="dl-onboarding-element"></div>
        <div className="dl-onboarding-shadow"></div>
        <img
          src={`${process.env.REACT_APP_FIREBASE_URL}/assets%2Fonboarding%2Fsmiling-woman.png?alt=media`}
          alt="Mulher vestida de vermelho com um sorriso amplo e battom vermelho"
          width="100%"
          height="100%"
          className="object-fit-cover"
        />
      </div>
    </div>
  );
}

export default OnboardingContent;
