import React from 'react';

function SearchProducts({ search }) {
  return (
    <div className="bg-white p-2 w-100">
      <input
        className="form-control rounded-1"
        type="text"
        placeholder="O que você procura hoje ?"
        onChange={(event) => search(event.target.value)}
      />
    </div>
  );
}

export default SearchProducts;
