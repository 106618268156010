import React, { useEffect, useState } from 'react';
import { findCreditCards } from '../../../../../services/Assinaturas';
import CreditCardForm from './CreditCardForm';
import Loader from './Loader';
import CreditCardBox from './CreditCardBox';

function CreditCardPayment({ newCreditCard, onSelectedCreditCard }) {
  const [creditCards, setCreditCards] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCreditCards();
  }, []);

  async function getCreditCards() {
    const response = await findCreditCards();
    if (response?.length) setCreditCards(response);
    setLoading(false);
  }

  return (
    <div className="subscription-process-flex-column">
      {loading ? (
        <Loader />
      ) : creditCards.length > 0 ? (
        <CreditCardBox
          cards={creditCards}
          onSelectCard={(card) => onSelectedCreditCard(card)}
          registerNewCard={() => setCreditCards([])}
        />
      ) : (
        <CreditCardForm onAddCreditCard={(params) => newCreditCard(params)} />
      )}
    </div>
  );
}

export default CreditCardPayment;
