import React, { useEffect, useState } from 'react';
import './SwitchButton.scss';

function SwitchButton({ onChange, initialValue }) {
  useEffect(() => {
    setIsActive(initialValue);
  }, [initialValue]);

  const [isActive, setIsActive] = useState(initialValue ?? false);

  return (
    <div
      className="dl-switch-button btn-group"
      role="group"
      aria-label="Status"
    >
      <button
        type="button"
        className={`p-1 p-md-1 btn fw-bold ${
          isActive ? 'btn-primary' : 'btn-light'
        }`}
        onClick={() => {
          setIsActive(true);
          onChange(true);
        }}
      >
        Aberto
      </button>
      <button
        type="button"
        className={`p-1 p-md-1 btn fw-bold ${
          !isActive ? 'btn-primary' : 'btn-light'
        }`}
        onClick={() => {
          setIsActive(false);
          onChange(false);
        }}
      >
        Fechado
      </button>
    </div>
  );
}

export default SwitchButton;
