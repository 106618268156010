import { useField } from '@unform/core';
import React, { useEffect, useRef, useState } from 'react';
import {
  maskCardNumber,
  maskCEP,
  maskCNPJ,
  maskCPF,
  maskCurrencyBRLWithSymbolString,
  maskDocument,
  maskPhoneBR,
  onlyNumbers,
  validateExpiryDate,
} from '../../Utils/Index';

function InputV2({
  label,
  id,
  type,
  placeholder,
  name,
  errorMessage,
  mask,
  containerClass = '',
  labelClass = '',
  customClass = '',
  onUpdateValue,
  children,
  ...settings
}) {
  const ref = useRef();
  const { defaultValue, fieldName, registerField, error } = useField(name);

  const [value, setValue] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        if (ref.current && value) {
          setValue(value);
          ref.current.value = value;
        }
      },
      clearValue: (ref) => (ref.current.value = ''),
    });
  }, [fieldName, registerField]);

  function handleInputValue(text) {
    const value = text && Boolean(mask) ? applyMaskToInputValue(text) : text;
    setValue(value);
    if (onUpdateValue) onUpdateValue(value);
  }

  function applyMaskToInputValue(text) {
    const OPTIONS = {
      DOCUMENT: maskDocument(text),
      CPF: maskCPF(text),
      PHONE: maskPhoneBR(text),
      CEP: maskCEP(text),
      CNPJ: maskCNPJ(text),
      CURRENCY: maskCurrencyBRLWithSymbolString(text),
      CC_NUMBER: maskCardNumber(text),
      CC_EXPIRATION: validateExpiryDate(text),
      NUMBER: onlyNumbers(text),
    };
    if (OPTIONS[mask]) return OPTIONS[mask];
  }

  return (
    <div className={containerClass}>
      <label
        htmlFor={fieldName}
        className={`form-label fs-7 m-0 ${labelClass} ${
          error ? 'text-danger' : ''
        }`}
      >
        {label}
      </label>
      <input
        className={`form-control py-2 ${customClass} ${
          error ? 'border-danger' : ''
        }`}
        ref={ref}
        type={type}
        id={fieldName}
        defaultValue={defaultValue}
        placeholder={placeholder}
        value={value}
        onChange={(event) => handleInputValue(event.target.value)}
        {...settings}
      />
      {error && (
        <span id={fieldName} className="form-text fs-7 text-danger">
          {error}
        </span>
      )}
      {children && children}
    </div>
  );
}

export default InputV2;
