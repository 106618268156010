import React, { useEffect, useState, useRef } from 'react';
import './DropDown.scss';
import { IconArrowUp, IconArrowDown } from '../../assets/Icons';

export default function DropDown({
  items,
  filterItems,
  labelAllCategory = 'Todas as categorias',
  msgAllCategory = true,
  personalizeWidth = false,
  defaultText = 'Todas as categorias',
  openvalue = 0,
}) {
  /*
        msgAllCategory = true -> Opção para mostrar todas as categorias novamente
        personalizeWidth = true -> irá usar 100% do espaço
        defaultText -> troca o label inicial do dropdown (antes de selecionar um item)
    */
  const [itemsModalPerma, setItemsModalPerma] = useState([
    { nome: '', codigo: 1 },
  ]);
  const [itemsModalEdit, setItemsModalEdit] = useState(null);
  const [labelDropDown, setlabelDropDown] = useState('Selecione um item');
  const [dropDown, setDropDown] = useState(false);
  const [msgDefault, setMsgDefault] = useState('Selecione um item');

  useEffect(() => {
    if (items) {
      setItemsModalEdit(items);
      setItemsModalPerma(items);
      setMsgDefault(msgAllCategory);
      setlabelDropDown(defaultText);
    }
  }, [items]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (openvalue !== 0 && itemsModalEdit !== null) {
      selectCategory(
        itemsModalEdit.find((items) => items.codigo === openvalue)
      );
    }
  }, [openvalue, itemsModalEdit]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!defaultText) setlabelDropDown(null);
  }, [defaultText]); //eslint-disable-line react-hooks/exhaustive-deps

  function OutSideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropDown(!dropDown);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  function CheckClick(props) {
    const wrapperRef = useRef(null);
    dropDown && OutSideClick(wrapperRef);
    return (
      <div
        className="input-style short"
        style={{ width: personalizeWidth && '100%' }}
        ref={wrapperRef}
        onClick={() => setDropDown(!dropDown)}
      >
        {props.children}
      </div>
    );
  }

  function selectCategory(param) {
    if (param === 'default') {
      itemSelected(itemsModalPerma);
    } else if (param !== undefined) {
      setlabelDropDown(param.nome);
      itemSelected(
        itemsModalPerma.filter((cat) => cat.codigo === param.codigo)
      );
    }
  }

  function itemSelected(param) {
    filterItems(param);
  }

  return (
    <>
      {itemsModalPerma && (
        <CheckClick>
          <div style={{ position: 'absolute', right: 0, paddingRight: 8 }}>
            {dropDown ? <IconArrowDown /> : <IconArrowUp />}
          </div>
          <span style={{ color: '#828282', fontSize: 15 }}>
            {labelDropDown}
          </span>
          <div className={dropDown ? 'dropDown-items' : 'hidden'}>
            {msgDefault && !labelDropDown.includes('Todas as') && (
              <span
                className="options-selected"
                onClick={() => selectCategory('default')}
              >
                {labelAllCategory}
              </span>
            )}
            {itemsModalPerma.map((item, index) => {
              return (
                <span
                  key={index}
                  className="options-selected"
                  onClick={() => selectCategory(item)}
                >
                  {item.nome}
                </span>
              );
            })}
          </div>
        </CheckClick>
      )}
    </>
  );
}
