import React, { useEffect, useState } from 'react';
import HamburgerIcon from '../HamburgerIcon/HamburgerIcon';
import { GESTOR_TABS } from './constants/header.constants';
import { Link, useHistory } from 'react-router-dom';
import './StoreHeader.scss';
import { SM_LOGO_IMAGE_URL } from '../../Utils/Index';

function StoreHeader() {
  const [displayTabs, setDisplayTabs] = useState(false);
  const [tabs, setTabs] = useState(GESTOR_TABS);

  const history = useHistory();

  useEffect(() => {
    getLastActiveTabFromSession();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  function getLastActiveTabFromSession() {
    const lastActiveTabId = sessionStorage.getItem('LAST_ACTIVE_TAB_ID');
    if (!lastActiveTabId) return;
    updateActiveTab(lastActiveTabId, true);
  }

  function navigate(route) {
    if (route.soon) return;
    if (route.id === 'login') {
      logout();
      return;
    }
    setDisplayTabs(false);
    updateActiveTab(route.id);
  }

  function updateActiveTab(id, navToPage = false) {
    sessionStorage.setItem('LAST_ACTIVE_TAB_ID', id);
    const newValue = tabs.map((tab) => {
      if (navToPage && tab.id === id) history.push(tab.url);
      tab.active = tab.id === id;
      return tab;
    });
    setTabs(newValue);
  }

  function logout() {
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => window.open('/login', '_self'), 100);
  }

  return (
    <div className="h-100 w-100 z-3">
      <div className="d-inline-flex w-100 justify-content-between bg-primary-hover px-4 py-3 w-100 gap-2 align-items-center hstack justify-content-start">
        <HamburgerIcon
          initialValue={displayTabs}
          customClass="orders"
          onToggleVisibility={(value) => setDisplayTabs(value)}
          disableScrollWhenOpen={true}
        />
        <div className="d-inline-flex align-items-center">
          <img
            src={SM_LOGO_IMAGE_URL}
            alt="Logo delivery Legal"
            height={60}
            width={60}
          />
          <span className="fw-bold text-white fs-1">Delivery.Legal</span>
        </div>
      </div>

      <div
        className={`z-3 w-100 h-100 position-absolute bg-secondary-subtle vstack ${
          displayTabs ? 'open' : 'close'
        }`}
      >
        {tabs.map((route) => {
          return (
            <Link
              key={route.id}
              to={route.url}
              className={`text-decoration-none ${route.soon ? 'pe-none' : ''}`}
              onClick={() => navigate(route)}
            >
              <div
                className={`px-3 py-3 text-dark text-decoration-none hstack gap-2 fw-bold ${
                  route.active ? 'text-primary' : ''
                }`}
              >
                <span className={`${route.em_breve ? 'opacity-50' : ''}`}>
                  {route.label}
                </span>
                {route.soon && (
                  <span className="bg-warning p-1 rounded-3 fs-7 ml-2">
                    em breve
                  </span>
                )}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default StoreHeader;
