import React from 'react';

function PlanPurchaseInfos({ infos }) {
  const headerInfos = [
    { label: 'Contratar plano', info: 'label' },
    { label: 'Valor do plano', info: 'price' },
    { label: 'Método de pagamento', info: 'payment_label' },
  ];

  return (
    <div className="plan-purchase-container__texts">
      {headerInfos.map((item, index) => {
        if (!infos || !infos[item.info]) return null;
        return (
          <span
            key={index}
            className={
              index === 0 ? 'plan-purchase-container__texts-plan-title' : ''
            }
          >
            {item.label} - &nbsp;
            <span className="plan-purchase-container__texts-highlight">
              {infos[item.info]}
            </span>
          </span>
        );
      })}
    </div>
  );
}

export default PlanPurchaseInfos;
