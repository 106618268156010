import { httpService } from './HttpService';

export const dashboardCards = async () => {
  const data = await httpService('painel/representante', 'dashboardCards', {});
  return data;
};

export const sendBankSlip = async (dataToSendTicket) => {
  const data = await httpService(
    'painel/gestor',
    'sendBankSlip',
    dataToSendTicket
  );
  return data;
};

export const getHistoryProposal = async () => {
  const data = await httpService(
    'painel/representante',
    'getHistoryProposal',
    {}
  );
  return data;
};

export const getPix = async () => {
  const data = await httpService('painel/representante', 'getPix', {});
  return data;
};

export const savePix = async (chave_pix, tipo_chave) => {
  const data = await httpService('painel/representante', 'savePix', {
    chave_pix,
    tipo_chave,
  });
  return data;
};

export const requestWithdrawal = async (amount) => {
  const data = await httpService('painel/representante', 'requestWithdrawal', {
    amount,
  });
  return data;
};

export const history = async () => {
  const data = await httpService('painel/representante', 'history', {});
  return data;
};
