import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Scope } from '@unform/core';
import InputV2 from '../../../shared/InputV2/InputV2';
import { StoreOwner } from '../../../Authentication/Provider/AuthenticationProvider';
import {
  getItemInBrowserStorage,
  maskPhoneBR,
  onlyNumbers,
  saveInBrowserStorage,
} from '../../../Utils/Index';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { updateStoreContacts } from '../../../services/Configuration';
import { toast } from 'react-toastify';

function Contacts() {
  const formRef = useRef();
  const history = useHistory();

  const { storeOwnerData, setStoreOwnerData } = useContext(StoreOwner);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setStoreContacts();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  function logout() {
    history.push('/login');
    console.error('access-denied=[401]');
    sessionStorage.clear();
    localStorage.clear();
  }

  function getStoreContacts() {
    if (!storeOwnerData) {
      const data = getItemInBrowserStorage('STORE_OWNER');
      if (data) {
        setStoreOwnerData(data);
        return data;
      }
      logout();
      return;
    }
    return storeOwnerData;
  }

  function setStoreContacts() {
    const store = getStoreContacts();
    formRef.current.setFieldValue(
      'contacts.phone',
      maskPhoneBR(store.telefone)
    );
    formRef.current.setFieldValue(
      'contacts.whatsapp',
      maskPhoneBR(store.whatsapp)
    );
  }

  async function handleSubmit() {
    const data = formRef.current.getData().contacts;
    const { phone: previousPhone, whatsapp: previousWP } = storeOwnerData;
    data.phone = onlyNumbers(data.phone);
    data.whatsapp = onlyNumbers(data.whatsapp ?? '');
    const { phone, whatsapp } = data;

    if (previousPhone === phone && previousWP === whatsapp) {
      setLoading(false);
      return;
    }
    updateStoreInfos({ phone, whatsapp });
  }

  async function handleForm(data) {
    setLoading(true);
    try {
      const schema = Yup.object().shape({
        contacts: Yup.object().shape({
          phone: Yup.string()
            .required('Telefone é obrigatório')
            .matches(/[0-9]{2}\s[0-9]{5}-[0-9]{4}/, 'Telefone inválido'),
          whatsapp: Yup.string()
            .nullable()
            .when({
              is: (value) => value && value.trim().length > 0,
              then: Yup.string().matches(
                /[0-9]{2}\s[0-9]{5}-[0-9]{4}/,
                'WhatsApp inválido'
              ),
              otherwise: Yup.string().nullable(),
            }),
        }),
      });

      await schema.validate(data, { abortEarly: false });
      formRef.current.setErrors({});
      handleSubmit();
      return true;
    } catch (error) {
      setLoading(false);
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  function updateStoreInfos(params) {
    updateStoreContacts(params)
      .then(() => {
        toast.success('Dados atualiados');
        setLoading(false);
        updateSessionContacts(params);
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao atualizar os meios de contato, tente novamente ou entre em contato com nosso suporte'
        );
        setLoading(false);
      });
  }

  function updateSessionContacts(params) {
    const { phone, whatsapp } = params;
    storeOwnerData.telefone = phone;
    storeOwnerData.whatsapp = whatsapp;
    saveInBrowserStorage('STORE_OWNER', storeOwnerData);
    setStoreOwnerData(structuredClone(storeOwnerData));
  }

  return (
    <div className="w-100 h-100" style={{ maxWidth: 550 }}>
      <div className="vstack">
        <div className="vstack pb-2">
          <h3 className="fs-3 fw-bold m-0 p-0 text-primary">
            Meios de contato
          </h3>
          <span className="fs-7 text-body-secondary">
            Configure os meios de contato do seu estabelecimento
          </span>
        </div>

        <Form
          ref={formRef}
          onSubmit={(path) => handleForm(path)}
          className={loading ? 'pe-none opacity-50' : ''}
          style={{ maxWidth: 500 }}
        >
          <Scope path="contacts">
            <div className="vstack gap-3">
              <InputV2
                label="Telefone"
                name="phone"
                type="text"
                placeholder="Telefone"
                mask="PHONE"
                maxLength={13}
              />

              <InputV2
                label="WhatsApp"
                name="whatsapp"
                type="text"
                placeholder="WhatsApp"
                mask="PHONE"
                maxLength={13}
              />
            </div>
          </Scope>

          <button
            type="submit"
            className={`mt-3 btn btn-primary ${loading ? 'disabled-btn' : ''}`}
          >
            Salvar alterações
          </button>
        </Form>
      </div>
    </div>
  );
}

export default Contacts;
