import React, { useEffect, useState } from 'react';
import './GroupComplements.scss';
import DropDown from '../../../../../shared/DropDown/DropDown';
import InputRadio from '../../../../../shared/InputRadio/InputRadio';
import { useFormProduct } from '../../../ContextProduct/ContextProduct';
import { toast } from 'react-toastify';
import EventEmitter from '../../../../../services/Event';
import {
  updateComplementoGrupo,
  includeGroupOnProduct,
} from '../../../../../services/Products';

export default function GroupComplements({
  productInfo,
  idGroup,
  allGroupComplements,
  goToItemsProduct,
  returnIdGroupComplements,
  showInputsRadio = true,
  indexComplement = null,
  returnHasComplement = true,
}) {
  const [hasComplement, setHasComplement] = useState(false);
  const [required, setRequired] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [disableInputRadio, setDisableInputRadio] = useState(false);

  const [groupComplement, setGroupComplement] = useState('');
  const [nameGroup, setNameGroup] = useState('');
  const [complementsGroup, setComplementsGroup] = useState(null);
  const [indexPosition, setIndexPosition] = useState(null);
  const [productData, setProductData] = useState(null);

  const [contMin, setContMin] = useState(0);
  const [contMax, setContMax] = useState(0);
  const [idGroupComplement, setIdGroupComplement] = useState(0);

  const [canCreateGroup, setCanCreateGroup] = useState({
    valid: false,
    items: null,
  });

  const [isValidInput, setIsValidInput] = useState([
    { name: 'hasComplement', valid: true, value: null },
    { name: 'groupComplement', valid: true, value: null },
    { name: 'min', valid: true, value: 0 },
    { name: 'max', valid: true, value: 2 },
    { name: 'required', valid: true, value: false },
    { name: 'customGroup', valid: true, value: false },
  ]);

  const { complementsAssignedInProduct } = useFormProduct();

  useEffect(() => {
    setProductData(productInfo);
  }, [productInfo]);

  useEffect(() => {
    setQtdItems();
  }, [hasComplement]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (idGroup !== 0) {
      setHasComplement(true);
      setIdGroupComplement(idGroup);
      validInput('hasComplement', true);
    }
    setComplementsGroup(allGroupComplements);
    if (indexComplement) setIndexPosition(indexComplement);
  }, [allGroupComplements, idGroup]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !allGroupComplements ||
      !allGroupComplements.find(
        (complement) => complement.codigo === idGroupComplement
      )
    )
      return;
    returnIdGroupComplements(idGroupComplement, indexPosition);
  }, [idGroupComplement]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!showInputsRadio) {
      setDisableInputRadio(true);
      setHasComplement(true);
      validInput('hasComplement', true);
    }
  }, [showInputsRadio]); //eslint-disable-line react-hooks/exhaustive-deps

  function handlerComplementProduct(active) {
    setHasComplement(active);
    returnHasComplement(active);
    validInput('hasComplement', active);
  }

  function verifyReturn(param) {
    const type = typeof param[0].codigo;
    if (type === 'string') {
      setShowInput(true);
      setGroupComplement('');
      validInput('customGroup', true);
      setIdGroupComplement(0);
    } else {
      setShowInput(false);
      setIdGroupComplement(param[0].codigo);
      getInfsGroupComplements(param);
      validInput('customGroup', false);
    }
  }

  function getInfsGroupComplements(infsComplements) {
    const data = infsComplements[0];
    const type = typeof data.codigo;
    if (type !== 'string') {
      setNameGroup(data.nome);
      setContMax(data.qtd_maxima);
      setContMin(data.qtd_minima);
      setRequired(data.qtd_minima >= 1 ? true : false);
      validInput('groupComplement', data);
      validInput('min', data.qtd_minima);
      validInput('required', data.qtd_minima >= 1 ? true : false);
      validInput('max', data.qtd_maxima);
    }
  }

  function setQtdComplements(input, value) {
    if (input === 'min') {
      const minValue = required ? 1 : 0;
      if (value >= minValue && value <= contMax) {
        setContMin(parseInt(value));
        validInput('min', parseInt(value));
      } else {
        let errorMensage = '';
        if (required && value <= 1) errorMensage = 'menor que 1.';
        else if (!required && value <= 0) errorMensage = 'menor que 0.';
        else errorMensage = 'maior que o valor máximo.';
        toast.error(`Valor mínimo não pode ser ${errorMensage}`);
      }
      return;
    }

    if (value >= contMin) {
      setContMax(parseInt(value));
      validInput('max', parseInt(value));
    } else toast.error('Valor máximo não pode ser menor que o valor mínimo. ');
  }

  function validInput(name, value) {
    let temp = isValidInput.map((item) => {
      if (item.name === name) {
        if (value !== '' || value === 0) {
          item.valid = true;
          item.value = value;
        } else {
          item.valid = false;
        }
      }
      return item;
    });
    setIsValidInput(temp);
    if (isValidInput.every((item) => item.valid && item.value !== null)) {
      setCanCreateGroup({ valid: true, items: isValidInput });
    } else {
      setCanCreateGroup({ valid: false, items: isValidInput });
    }
  }

  function changeRequiredItems(value) {
    setRequired(value);
    setQtdItems(value);
    validInput('required', value);
  }

  function setQtdItems(value) {
    const min = hasComplement ? (value ? 1 : 0) : 0;
    const max = hasComplement ? (value ? 1 : 1) : 0;
    setContMin(min);
    setContMax(max);
    validInput('min', min);
    validInput('max', max);
  }

  function verifyHaveChanges(original, dataTemp) {
    if (
      original[0].qtd_minima !== dataTemp[2].value ||
      original[0].qtd_maxima !== dataTemp[3].value
    )
      return true;
    return false;
  }

  async function saveChangesComplementGroup(data) {
    let hasChange = true;
    if (idGroupComplement)
      hasChange = verifyHaveChanges(
        complementsGroup.filter((item) => item.codigo === idGroupComplement),
        data
      );
    const code = data[5].value ? 0 : data[1].value.codigo;
    const nome = data[5].value ? data[1].value : data[1].value.nome;
    const min = data[2].value;
    const max = data[3].value;
    const items = data[5].value ? [] : null;

    if (hasChange) {
      const response = await updateComplementoGrupo(
        code,
        nome,
        true,
        min,
        max,
        items
      );

      if (!response || !response.success) {
        toast.error(
          `Ocorreu um erro ao ${
            code === 0
              ? 'criar um novo grupo de complementos'
              : 'atualizar o grupo de complementos'
          }`
        );
        return;
      }

      toast.success(
        `${
          data[5].value
            ? 'Novo grupo de complementos criado'
            : 'Alterações foram salvas'
        } com sucesso.`
      );
      if (data[5].value) {
        setIdGroupComplement(response.results.cod_grupo_complemento);
        includeGroupComplementOnProduct(
          response.results.cod_grupo_complemento,
          nome
        );
      }
      return;
    }

    includeGroupComplementOnProduct(code, nome, hasChange);
  }

  async function includeGroupComplementOnProduct(
    idGroupComplement,
    nameGroup,
    isUpdateOnGroupComplement = false
  ) {
    if (
      !isUpdateOnGroupComplement &&
      complementsAssignedInProduct &&
      complementsAssignedInProduct.find(
        (complements) => complements.idGroup === idGroupComplement
      )
    ) {
      toast.warning('Grupo de complementos já atribuido a este produto.');
      return;
    }

    const response = await includeGroupOnProduct(
      productData.codigo,
      idGroupComplement
    );

    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao incluir o grupo de complementos no produto, por favor tente novamente ou entre em contato conosco.'
      );
      return;
    }

    if (idGroup === 0) {
      EventEmitter.emit('hasOneGroupComplement', true);
      EventEmitter.emit('updateAllComplementsGroup', true);
    }
    toast.success(`${nameGroup} foi associado ao produto com sucesso.`);
  }

  function goToProduct() {
    EventEmitter.emit('goToProduct', 1);
  }

  function saveChangesGroupComplements() {
    if (canCreateGroup.valid) saveChangesComplementGroup(canCreateGroup.items);
  }

  return (
    <div className="p-0">
      {!disableInputRadio && (
        <div className="hstack pb-2 pt-0">
          <InputRadio
            labelInput={'Sim'}
            selected={hasComplement}
            clicked={() => handlerComplementProduct(true)}
          />
          <div style={{ paddingLeft: 8 }}>
            <InputRadio
              labelInput={'Não'}
              selected={!hasComplement}
              clicked={() => handlerComplementProduct(false)}
            />
          </div>
        </div>
      )}

      <div
        className={`d-flex flex-column flex-md-row gap-3 gap-md-3 justify-content-between w-100 ${
          !hasComplement && 'pe-none opacity-50'
        }`}
      >
        <div className="vstack gap-3">
          <div className="d-flex flex-column">
            <span className="fw-bold fs-7 text-body-secondary">
              Grupo de complementos <b>*</b>
            </span>
            <div className="dropdown-complements">
              <DropDown
                items={complementsGroup}
                filterItems={(param) => verifyReturn(param)}
                msgAllCategory={false}
                personalizeWidth={true}
                defaultText={
                  showInput ? '' : 'Escolha um grupo de complementos'
                }
                openvalue={idGroupComplement}
              />
              {showInput && (
                <input
                  className={
                    isValidInput[0].valid
                      ? 'input-style input-style-custom'
                      : 'input-style invalid-input input-style-custom'
                  }
                  type="text"
                  placeholder="Novo grupo de complementos"
                  value={groupComplement}
                  onChange={(e) => setGroupComplement(e.target.value)}
                  onBlur={() => validInput('groupComplement', groupComplement)}
                />
              )}
            </div>
          </div>

          <div className="d-flex flex-column">
            <span className="fw-bold fs-7 text-body-secondary">Quantidade</span>
            <span className="fs-8 text-body-secondary">
              Indique quantos itens podem ser selecionados
            </span>
            <div className="d-flex flex-row justify-content-start pt-1">
              <div className="w-100 position-relative">
                <span className="fw-bold fs-7 text-body-secondary">Mínimo</span>
                <div
                  className={
                    isValidInput[2].valid
                      ? 'box-count'
                      : 'box-count invalid-input'
                  }
                >
                  <button
                    className="down-count"
                    onClick={() => setQtdComplements('min', contMin - 1)}
                  >
                    -
                  </button>
                  <input
                    className="count"
                    value={contMin}
                    type="number"
                    readOnly
                  />
                  <button
                    className="up-count"
                    onClick={() => setQtdComplements('min', contMin + 1)}
                  >
                    +
                  </button>
                </div>
              </div>
              <div style={{ paddingLeft: 16 }}>
                <span className="fw-bold fs-7 text-body-secondary">Máximo</span>
                <div
                  className={
                    isValidInput[3].valid
                      ? 'box-count'
                      : 'box-count invalid-input'
                  }
                >
                  <button
                    className="down-count"
                    onClick={() => setQtdComplements('max', contMax - 1)}
                  >
                    -
                  </button>
                  <input
                    className="count"
                    value={contMax}
                    type="number"
                    readOnly
                  />
                  <button
                    className="up-count"
                    onClick={() => setQtdComplements('max', contMax + 1)}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span className="fw-bold fs-7 text-body-secondary">
            Obrigatoriedade
          </span>
          <div className="inputs-radio-content" style={{ paddingTop: 8 }}>
            <InputRadio
              labelInput={'Opcional'}
              selected={hasComplement && !required}
              clicked={() => changeRequiredItems(false)}
            />
            <span className="fs-8 text-body-secondary">
              O cliente pode ou não selecionar os itens.
            </span>
            <div style={{ paddingTop: 8 }}>
              <InputRadio
                labelInput={'Obrigatório'}
                selected={hasComplement && required}
                clicked={() => changeRequiredItems(true)}
              />
              <span className="fs-8 text-body-secondary">
                O cliente deve selecionar um ou mais itens para adicionar o
                pedido ao carrinho.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 d-inline-flex pt-3 justify-content-between align-items-center gap-3">
        <button
          type="button"
          className="btn btn-outline-dark"
          onClick={() => goToProduct()}
        >
          Voltar
        </button>
        <button
          type="button"
          className={`btn btn-outline-primary ${
            (!hasComplement || !canCreateGroup.valid || !idGroupComplement) &&
            'pe-none opacity-50'
          }`}
          onClick={() =>
            goToItemsProduct({
              step: 'next',
              idGroup: idGroupComplement,
              name: nameGroup,
            })
          }
          disabled={!hasComplement || !canCreateGroup.valid}
        >
          Ver itens
        </button>
        <button
          type="button"
          className={`btn btn-primary ${
            (!canCreateGroup.valid || !hasComplement) && 'pe-none opacity-50'
          }`}
          onClick={() => saveChangesGroupComplements()}
          disabled={!hasComplement || !canCreateGroup.valid}
        >
          Salvar
        </button>
      </div>
    </div>
  );
}
