import React, { useState, useRef } from 'react';

import InputV2 from '../../../shared/InputV2/InputV2';
import { changePassword } from '../../../services/StoreData';
import { validStepsPassword } from '../../../Utils/Index';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

function ChangePassword() {
  const [blockSubmitButton, setBlockSubmitButton] = useState(false);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [displayNewPassword, setDisplayNewPassword] = useState(false);
  const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);
  const [passwordSecurityStatus, setPasswordSecurityStatus] = useState({
    label: null,
    color: null,
  });

  const formRef = useRef(null);

  async function verifyIsValidField(field) {
    try {
      const passwordSchema = Yup.object().shape({
        senha_atual: Yup.string().required('Informa a sua senha atual.'),
        senha: Yup.string().required('Senha é obrigatória'),
        senha_confirmacao: Yup.string()
          .required('Confirme sua senha')
          .oneOf([Yup.ref('senha'), 'Teste'], 'As senhas não conferem'),
      });

      await passwordSchema.validate(field, { abortEarly: false });
      formRef.current.setErrors({});
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  function setStatusSecurityPassword(password) {
    let acmSecurity = 0;
    const validatorsPassword = validStepsPassword(password, true);

    Object.keys(validatorsPassword).forEach((validator) => {
      if (validatorsPassword[validator]) acmSecurity++;
    });

    setPasswordSecurityStatus(returnPasswordStatus(acmSecurity));
  }

  function returnPasswordStatus(qtyStepsSecurity) {
    let status = { label: 'Senha frágil', color: '#E4001B' };
    if (qtyStepsSecurity < 2) return status;
    else if (qtyStepsSecurity <= 4)
      status = { label: 'Senha razoável', color: '#E46E00' };
    else if (qtyStepsSecurity === 5)
      status = { label: 'Senha forte', color: '#27AE60' };

    return status;
  }

  async function handleSubmit(formData) {
    const isValid = await verifyIsValidField(formData);
    if (!isValid) return;
    callChangePassword(formData);
  }

  async function callChangePassword(passwords) {
    setBlockSubmitButton(true);
    const response = await changePassword(passwords);
    if (!response || !response.success) {
      const message = response.msg
        ? response.msg
        : 'Ocorreu ume erro ao alterar sua senha, por favor tente novamente ou entre em contato conosco.';
      toast.error(message, { autoClose: 5000 });
      setBlockSubmitButton(false);
      return;
    }
    setBlockSubmitButton(false);
    toast.success('Senha alterada com sucesso.');
  }

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      autoComplete="off"
      className="w-100 gap-2 vstack"
      style={{ maxWidth: 550 }}
    >
      <InputV2
        containerClass="position-relative"
        label="Senha atual"
        name="senha_atual"
        type={displayPassword ? 'text' : 'password'}
        maxLength={50}
        placeholder="Senha atual"
      >
        <button
          type="button"
          className="z-2 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
          style={{ top: 30, right: 4 }}
          onClick={() => setDisplayPassword(!displayPassword)}
        >
          <span className="material-symbols-outlined fs-5">
            {displayPassword ? 'visibility' : 'visibility_off'}
          </span>
        </button>
      </InputV2>

      <InputV2
        containerClass="position-relative"
        label="Nova senha"
        name="senha"
        type={displayNewPassword ? 'text' : 'password'}
        placeholder="Insira uma senha"
        maxLength={50}
        controlled={true}
        onUpdateValue={(password) => setStatusSecurityPassword(password)}
      >
        <button
          type="button"
          className="z-2 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
          style={{ top: 30, right: 4 }}
          onClick={() => setDisplayNewPassword(!displayNewPassword)}
        >
          <span className="material-symbols-outlined fs-5">
            {displayNewPassword ? 'visibility' : 'visibility_off'}
          </span>
        </button>
      </InputV2>

      <InputV2
        containerClass="position-relative"
        label="Confirme a nova senha"
        name="senha_confirmacao"
        type={displayConfirmPassword ? 'text' : 'password'}
        maxLength={50}
        placeholder="Confirme a nova senha"
      >
        <button
          type="button"
          className="z-2 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
          style={{ top: 30, right: 4 }}
          onClick={() => setDisplayConfirmPassword(!displayConfirmPassword)}
        >
          <span className="material-symbols-outlined fs-5">
            {displayConfirmPassword ? 'visibility' : 'visibility_off'}
          </span>
        </button>
      </InputV2>

      {passwordSecurityStatus.label && (
        <span
          className="form__password-security-status"
          style={{ color: passwordSecurityStatus.color }}
        >
          {passwordSecurityStatus.label}
        </span>
      )}

      <button
        type="submit"
        disabled={blockSubmitButton}
        className={`btn btn-primary w-100 mt-2 ${
          blockSubmitButton ? 'pe-none opacity-50' : ''
        }`}
      >
        Alterar senha
      </button>
    </Form>
  );
}

export default ChangePassword;
