import * as React from 'react';
import './Icons.scss';

export const IconTrash = (props) => {
  return (
    <svg
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M19.3249 9.4682C19.3249 9.4682 18.7819 16.2032 18.4669 19.0402C18.3169 20.3952 17.4799 21.1892 16.1089 21.2142C13.4999 21.2612 10.8879 21.2642 8.27991 21.2092C6.96091 21.1822 6.13791 20.3782 5.99091 19.0472C5.67391 16.1852 5.13391 9.4682 5.13391 9.4682"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7082 6.2397H3.75018"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4406 6.2397C16.6556 6.2397 15.9796 5.6847 15.8256 4.9157L15.5826 3.6997C15.4326 3.1387 14.9246 2.7507 14.3456 2.7507H10.1126C9.53358 2.7507 9.02558 3.1387 8.87558 3.6997L8.63258 4.9157C8.47858 5.6847 7.80258 6.2397 7.01758 6.2397"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconArrowDown = (props) => {
  return (
    <svg
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 8.5L12 15.5L5 8.5"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconArrowUp = (props) => {
  return (
    <svg
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 15.5L12 8.5L19 15.5"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconSave = (props) => {
  return (
    <svg
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7363 2.7619H8.0843C6.0253 2.7619 4.2503 4.4309 4.2503 6.4909V17.2279C4.2503 19.4039 5.9083 21.1149 8.0843 21.1149H16.0723C18.1323 21.1149 19.8023 19.2879 19.8023 17.2279V8.0379L14.7363 2.7619Z"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4736 2.7502V5.6592C14.4736 7.0792 15.6226 8.2312 17.0426 8.2342C18.3586 8.2372 19.7056 8.2382 19.7966 8.2322"
        stroke={props.color ? props.color : '#000000'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1716 8.91093C12.0309 8.76958 11.8396 8.69012 11.6401 8.69014C11.4406 8.69016 11.2494 8.76964 11.1086 8.91102L8.27064 11.762C7.97841 12.0556 7.9795 12.5305 8.27306 12.8227C8.56662 13.1149 9.04149 13.1138 9.33372 12.8203L10.8906 11.2562V16.0136C10.8906 16.4278 11.2264 16.7636 11.6406 16.7636C12.0548 16.7636 12.3906 16.4278 12.3906 16.0136V11.2567L13.9477 12.8204C14.24 13.1139 14.7149 13.1149 15.0084 12.8226C15.3019 12.5303 15.3029 12.0554 15.0106 11.7619L12.1716 8.91093Z"
        fill="#000000"
      />
    </svg>
  );
};

export const IconEdit = (props) => {
  return (
    <svg
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M11.4923 2.789H7.7533C4.6783 2.789 2.75031 4.966 2.75031 8.048V16.362C2.75031 19.444 4.6693 21.621 7.7533 21.621H16.5773C19.6623 21.621 21.5813 19.444 21.5813 16.362V12.334"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82782 10.9209L16.3008 3.4479C17.2318 2.5179 18.7408 2.5179 19.6718 3.4479L20.8888 4.6649C21.8198 5.5959 21.8198 7.1059 20.8888 8.0359L13.3798 15.5449C12.9728 15.9519 12.4208 16.1809 11.8448 16.1809H8.09882L8.19282 12.4009C8.20682 11.8449 8.43382 11.3149 8.82782 10.9209Z"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1652 4.6025L19.7312 9.1685"
        stroke={props.color ? props.color : '#000000'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconBell = (props) => {
  return (
    <svg
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 7C16 5.4087 15.3679 3.88258 14.2426 2.75736C13.1174 1.63214 11.5913 1 10 1C8.4087 1 6.88258 1.63214 5.75736 2.75736C4.63214 3.88258 4 5.4087 4 7C4 14 1 16 1 16H19C19 16 16 14 16 7Z"
        stroke={props.color ? props.color : '#27AE60'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.73 20C11.5542 20.3031 11.3019 20.5547 10.9982 20.7295C10.6946 20.9044 10.3504 20.9965 10 20.9965C9.64964 20.9965 9.30541 20.9044 9.00179 20.7295C8.69818 20.5547 8.44583 20.3031 8.27002 20"
        stroke={props.color ? props.color : '#27AE60'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconMenuMobileOrders = (props) => {
  return (
    <svg
      width={props.size ? props.size : '16'}
      height={props.size ? props.size : '18'}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8L6 13L11 8"
        stroke="#E4001B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L6 6L11 1"
        stroke="#E4001B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconSearch = (props) => {
  return (
    <svg
      width={props.size ? props.size : '20'}
      height={props.size ? props.size : '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9999 19L14.6499 14.65"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconCircleAlert = (props) => {
  return (
    <svg
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 2.7502C17.1089 2.7502 21.2499 6.8922 21.2499 12.0002C21.2499 17.1082 17.1089 21.2502 11.9999 21.2502C6.8919 21.2502 2.7499 17.1082 2.7499 12.0002C2.7499 6.8922 6.8919 2.7502 11.9999 2.7502Z"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M11.995 8.2043V12.6233"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M11.995 15.7961H12.005"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconDone = (props) => {
  return (
    <svg
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99999 16.2L4.79999 12L3.39999 13.4L8.99999 19L21 6.99998L19.6 5.59998L8.99999 16.2Z"
        fill={props.color ? props.color : '#323232'}
      />
    </svg>
  );
};

export const IconShoppingBag = (props) => {
  return (
    <svg
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 1L1 5V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V5L16 1H4Z"
        stroke={props.color ? props.color : 'white'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 5H19"
        stroke={props.color ? props.color : 'white'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 9C14 10.0609 13.5786 11.0783 12.8284 11.8284C12.0783 12.5786 11.0609 13 10 13C8.93913 13 7.92172 12.5786 7.17157 11.8284C6.42143 11.0783 6 10.0609 6 9"
        stroke={props.color ? props.color : 'white'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconEyeHide = (props) => {
  return (
    <svg
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M9.7606 14.3668C9.1856 13.7928 8.8356 13.0128 8.8356 12.1378C8.8356 10.3848 10.2476 8.9718 11.9996 8.9718C12.8666 8.9718 13.6646 9.3228 14.2296 9.8968"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M15.1048 12.6989C14.8728 13.9889 13.8568 15.0069 12.5678 15.2409"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6546 17.4723C5.0676 16.2263 3.7236 14.4063 2.7496 12.1373C3.7336 9.8583 5.0866 8.0283 6.6836 6.7723C8.2706 5.5163 10.1016 4.8343 11.9996 4.8343C13.9086 4.8343 15.7386 5.5263 17.3356 6.7913"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4476 8.9908C20.1356 9.9048 20.7406 10.9598 21.2496 12.1368C19.2826 16.6938 15.8066 19.4388 11.9996 19.4388C11.1366 19.4388 10.2856 19.2988 9.46761 19.0258"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.887 4.2496L4.11301 20.0236"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconEyeShow = (props) => {
  return (
    <svg
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1615 12.0531C15.1615 13.7991 13.7455 15.2141 11.9995 15.2141C10.2535 15.2141 8.8385 13.7991 8.8385 12.0531C8.8385 10.3061 10.2535 8.8911 11.9995 8.8911C13.7455 8.8911 15.1615 10.3061 15.1615 12.0531Z"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.998 19.3549C15.806 19.3549 19.289 16.6169 21.25 12.0529C19.289 7.4889 15.806 4.7509 11.998 4.7509H12.002C8.194 4.7509 4.711 7.4889 2.75 12.0529C4.711 16.6169 8.194 19.3549 12.002 19.3549H11.998Z"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconCheckAnimated = () => {
  return (
    <svg
      className="checkmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <circle
        className="checkmark__circle"
        cx="26"
        cy="26"
        r="25"
        fill="none"
      />
      <path
        className="checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  );
};

export const IconErrorAnimated = () => {
  return (
    <div className="animated-svg">
      <div className="icon error flex ">
        <span className="mark">
          <span className="mark-line-left"></span>
          <span className="mark-line-right"></span>
        </span>
      </div>
    </div>
  );
};

export const IconLeftArrow = (props) => {
  return (
    <svg
      width={props.size ? props.size : 24}
      height={props.size ? props.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M4.25 12.2743L19.25 12.2743"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2998 18.2987L4.2498 12.2747L10.2998 6.2497"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconUser = (props) => {
  return (
    <svg
      width={props.size ? props.size : 24}
      height={props.size ? props.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00002 18.2014C3.99873 17.8655 4.07385 17.5337 4.2197 17.2311C4.67736 16.3158 5.96798 15.8307 7.03892 15.611C7.81128 15.4462 8.59431 15.336 9.38217 15.2815C10.8408 15.1533 12.3079 15.1533 13.7666 15.2815C14.5544 15.3367 15.3374 15.4468 16.1099 15.611C17.1808 15.8307 18.4714 16.27 18.9291 17.2311C19.2224 17.8479 19.2224 18.564 18.9291 19.1808C18.4714 20.1419 17.1808 20.5812 16.1099 20.7918C15.3384 20.9634 14.5551 21.0766 13.7666 21.1304C12.5794 21.2311 11.3866 21.2494 10.1968 21.1854C9.92221 21.1854 9.65677 21.1854 9.38217 21.1304C8.59663 21.0773 7.81632 20.9641 7.04807 20.7918C5.96798 20.5812 4.68652 20.1419 4.2197 19.1808C4.0746 18.8747 3.99955 18.5401 4.00002 18.2014Z"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="11.5"
        cy="6.5"
        r="4.75"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const IconDownload = (props) => {
  return (
    <svg
      width={props.size ? props.size : 24}
      height={props.size ? props.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7364 2.7619H8.0844C6.0254 2.7619 4.2504 4.4309 4.2504 6.4909V17.2279C4.2504 19.4039 5.9084 21.1149 8.0844 21.1149H16.0724C18.1324 21.1149 19.8024 19.2879 19.8024 17.2279V8.0379L14.7364 2.7619Z"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4737 2.7502V5.6592C14.4737 7.0792 15.6227 8.2312 17.0427 8.2342C18.3587 8.2372 19.7057 8.2382 19.7967 8.2322"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3907 9.44075C12.3907 9.02654 12.0549 8.69075 11.6407 8.69075C11.2265 8.69075 10.8907 9.02654 10.8907 9.44075V14.1981L9.33382 12.634C9.0416 12.3405 8.56673 12.3394 8.27316 12.6316C7.9796 12.9238 7.97852 13.3987 8.27074 13.6923L11.1087 16.5433C11.2495 16.6847 11.4407 16.7641 11.6402 16.7642C11.8296 16.7642 12.0116 16.6926 12.15 16.5644C12.1589 16.5561 12.1676 16.5477 12.1761 16.539L15.0107 13.6924C15.303 13.3989 15.302 12.924 15.0085 12.6317C14.715 12.3394 14.2401 12.3404 13.9478 12.634L12.3907 14.1976V9.44075Z"
        fill={props.color ? props.color : '#200E32'}
      />
    </svg>
  );
};

export const IconMessage = (props) => {
  return (
    <svg
      width={props.size ? props.size : 24}
      height={props.size ? props.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M17.2677 8.56113L13.0023 11.9954C12.1951 12.6283 11.0635 12.6283 10.2563 11.9954L5.95422 8.56113"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.88787 3H16.3158C17.6752 3.01525 18.969 3.58993 19.896 4.5902C20.823 5.59048 21.3022 6.92903 21.222 8.29412V14.822C21.3022 16.1871 20.823 17.5256 19.896 18.5259C18.969 19.5262 17.6752 20.1009 16.3158 20.1161H6.88787C3.96796 20.1161 2 17.7407 2 14.822V8.29412C2 5.37545 3.96796 3 6.88787 3Z"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconLogout = (props) => {
  return (
    <svg
      width={props.size ? props.size : 24}
      height={props.size ? props.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M15.016 7.3895V6.4565C15.016 4.4215 13.366 2.7715 11.331 2.7715H6.456C4.422 2.7715 2.772 4.4215 2.772 6.4565V17.5865C2.772 19.6215 4.422 21.2715 6.456 21.2715H11.341C13.37 21.2715 15.016 19.6265 15.016 17.5975V16.6545"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8095 12.0214H9.76851"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8812 9.1063L21.8092 12.0213L18.8812 14.9373"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconKey = (props) => {
  return (
    <svg
      width={props.size ? props.size : 24}
      height={props.size ? props.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 18L10 16H12L13.36 14.64C14.7502 15.1243 16.2636 15.1224 17.6525 14.6346C19.0414 14.1467 20.2236 13.2018 21.0056 11.9546C21.7876 10.7073 22.123 9.23157 21.957 7.76885C21.791 6.30612 21.1334 4.94306 20.0918 3.90277C19.0502 2.86248 17.6863 2.20657 16.2234 2.04239C14.7604 1.87821 13.2851 2.21549 12.0388 2.99903C10.7926 3.78257 9.84917 4.96595 9.36307 6.35549C8.87696 7.74504 8.87694 9.25844 9.363 10.648L2 18V22H6L8 20V18Z"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 8C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6C16.4477 6 16 6.44772 16 7C16 7.55228 16.4477 8 17 8Z"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconDocument = (props) => {
  return (
    <svg
      width={props.size ? props.size : 24}
      height={props.size ? props.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M15.7162 16.2234H8.49622"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M15.7162 12.0369H8.49622"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M11.2513 7.8601H8.49634"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9086 2.7498C15.9086 2.7498 8.23161 2.7538 8.21961 2.7538C5.45961 2.7708 3.75061 4.5868 3.75061 7.3568V16.5528C3.75061 19.3368 5.47261 21.1598 8.25661 21.1598C8.25661 21.1598 15.9326 21.1568 15.9456 21.1568C18.7056 21.1398 20.4156 19.3228 20.4156 16.5528V7.3568C20.4156 4.5728 18.6926 2.7498 15.9086 2.7498Z"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconPhone = (props) => {
  return (
    <svg
      width={props.size ? props.size : 24}
      height={props.size ? props.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99033 4.87254C3.30665 4.34878 5.0495 2.44376 6.29322 2.50127C6.665 2.53208 6.99364 2.75699 7.26067 3.01784C7.87379 3.61656 9.62897 5.88101 9.72859 6.35753C9.97096 7.52621 8.57833 8.1999 9.00454 9.37783C10.0911 12.0366 11.9634 13.9088 14.6233 14.9943C15.8003 15.4205 16.474 14.0279 17.6428 14.2713C18.1183 14.3709 20.3839 16.126 20.9826 16.7391C21.2425 17.0051 21.4684 17.3347 21.4992 17.7065C21.5454 19.0159 19.5222 20.7833 19.1278 21.0092C18.1974 21.6747 16.9834 21.6634 15.5035 20.9753C11.3739 19.2572 4.77426 12.7822 3.02422 8.49669C2.35461 7.02505 2.30839 5.80297 2.99033 4.87254Z"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M4.3829 3.7943L5.8519 5.9733"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M17.8643 18.0668L20.0523 19.7768"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconCheck = (props) => {
  return (
    <svg
      width={props.size ? props.size : 24}
      height={props.size ? props.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3344 2.7502H7.6654C4.6444 2.7502 2.7504 4.8892 2.7504 7.9162V16.0842C2.7504 19.1112 4.6354 21.2502 7.6654 21.2502H16.3334C19.3644 21.2502 21.2504 19.1112 21.2504 16.0842V7.9162C21.2504 4.8892 19.3644 2.7502 16.3344 2.7502Z"
        stroke={props.stroke ? props.stroke : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity={props.opacity ? props.opacity : '0.5'}
        d="M8.43991 12.0002L10.8139 14.3732L15.5599 9.6272"
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconError = (props) => {
  return (
    <svg
      width={props.size ? props.size : 24}
      height={props.size ? props.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={props.opacity ? props.opacity : '0.5'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1314 9.06257C9.83855 8.76965 9.36368 8.7696 9.07075 9.06246C8.77783 9.35533 8.77778 9.8302 9.07064 10.1231L10.9385 11.9913L9.0732 13.8566C8.78031 14.1495 8.78031 14.6244 9.0732 14.9173C9.36609 15.2101 9.84097 15.2101 10.1339 14.9173L11.999 13.0521L13.8666 14.9201C14.1595 15.213 14.6344 15.2131 14.9273 14.9202C15.2202 14.6274 15.2203 14.1525 14.9274 13.8596L13.0597 11.9914L14.9259 10.1253C15.2188 9.83236 15.2188 9.35749 14.9259 9.06459C14.633 8.7717 14.1581 8.7717 13.8652 9.06459L11.9991 10.9307L10.1314 9.06257Z"
        fill={props.color ? props.color : '#200E32'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3345 2.7502H7.6655C4.6445 2.7502 2.7505 4.8892 2.7505 7.9162V16.0842C2.7505 19.1112 4.6355 21.2502 7.6655 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.9162C21.2505 4.8892 19.3645 2.7502 16.3345 2.7502Z"
        stroke={props.stroke ? props.stroke : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconHeart = (props) => {
  return (
    <svg
      width={props.size ? props.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7484 20.8538C9.46358 19.5179 7.33801 17.9456 5.40978 16.1652C4.05414 14.8829 3.02211 13.3198 2.39273 11.5954C1.26015 8.25031 2.58308 4.42083 6.28539 3.28752C8.23117 2.69243 10.3563 3.03255 11.9959 4.20148C13.6361 3.03398 15.7605 2.69398 17.7064 3.28752C21.4087 4.42083 22.7411 8.25031 21.6086 11.5954C20.9792 13.3198 19.9471 14.8829 18.5915 16.1652C16.6633 17.9456 14.5377 19.5179 12.2529 20.8538L12.0054 21L11.7484 20.8538Z"
        stroke={props.color ? props.color : '#F10542'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M15.9361 7.05298C17.0575 7.39328 17.8543 8.34968 17.9538 9.47496"
        stroke={props.color ? props.color : '#F10542'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconWallet = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={props.color ? props.color : '#200E32'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21.639 14.396H17.59a2.693 2.693 0 010-5.383h4.048M18.049 11.643h-.312"
      ></path>
      <path
        stroke={props.color ? props.color : '#200E32'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.748 3h8.643a5.248 5.248 0 015.248 5.248v7.177a5.248 5.248 0 01-5.248 5.247H7.748A5.248 5.248 0 012.5 15.425V8.248A5.248 5.248 0 017.748 3z"
        clipRule="evenodd"
      ></path>
      <path
        stroke={props.color ? props.color : '#200E32'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.036 7.538h5.399"
        opacity="0.4"
      ></path>
    </svg>
  );
};

export const IconChat = (props) => {
  return (
    <svg
      width={props.size ? props.size : '24'}
      height={props.size ? props.size : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0568 2.00016C8.54687 1.98597 5.28557 3.80711 3.4605 6.80042C1.63543 9.79373 1.51292 13.5224 3.13757 16.6287L3.33789 17.0192C3.50209 17.3264 3.53644 17.6865 3.43329 18.0192C3.14742 18.7784 2.90849 19.5545 2.71784 20.343C2.71784 20.743 2.83231 20.9716 3.26158 20.962C4.0219 20.7941 4.77068 20.5778 5.50332 20.3144C5.81886 20.2275 6.15437 20.2476 6.45725 20.3716C6.73389 20.5049 7.2967 20.8477 7.31578 20.8477C10.9915 22.7805 15.4808 22.2473 18.5998 19.5075C21.7187 16.7677 22.8199 12.3901 21.3676 8.50411C19.9153 4.61817 16.2111 2.03061 12.0568 2.00016V2.00016Z"
        stroke={props.border ? props.border : '#200E32'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        opacity="0.7"
        cx="7.28716"
        cy="12.0001"
        rx="0.476965"
        ry="0.47619"
        fill={props.color ? props.color : '#200E32'}
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        opacity="0.7"
        cx="12.0568"
        cy="12.0001"
        rx="0.476965"
        ry="0.47619"
        fill={props.color ? props.color : '#200E32'}
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        opacity="0.7"
        cx="16.8265"
        cy="12.0001"
        rx="0.476965"
        ry="0.47619"
        fill={props.color ? props.color : '#200E32'}
        stroke={props.color ? props.color : '#200E32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconClose = (props) => {
  return (
    <svg
      width={props.size ? props.size : '16'}
      height={props.size ? props.size : '16'}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8249 1.70711C16.2154 1.31658 16.2154 0.683417 15.8249 0.292893C15.4344 -0.0976311 14.8012 -0.0976311 14.4107 0.292893L8.05891 6.64469L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L6.64469 8.05891L0.292893 14.4107C-0.0976311 14.8012 -0.0976311 15.4344 0.292893 15.8249C0.683417 16.2154 1.31658 16.2154 1.70711 15.8249L8.05891 9.47312L14.4107 15.8249C14.8012 16.2154 15.4344 16.2154 15.8249 15.8249C16.2154 15.4344 16.2154 14.8012 15.8249 14.4107L9.47312 8.05891L15.8249 1.70711Z"
        fill={props.color ? props.color : '#200E32'}
      />
    </svg>
  );
};

export const IconPIX = (props) => {
  return (
    <svg
      width="90"
      height="91"
      viewBox="0 0 90 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.1888 69.3443C68.4532 69.3505 66.7335 69.0128 65.1292 68.3506C63.5249 67.6885 62.0677 66.7149 60.8418 65.4863L47.3402 51.9762C46.8627 51.5195 46.2274 51.2646 45.5666 51.2646C44.9059 51.2646 44.2706 51.5195 43.7931 51.9762L30.2459 65.5233C29.0208 66.7538 27.5639 67.7293 25.9596 68.3934C24.3553 69.0576 22.6353 69.3972 20.8989 69.3927H18.2556L35.3642 86.5013C37.9302 89.0618 41.4071 90.4999 45.032 90.4999C48.6569 90.4999 52.1338 89.0618 54.6998 86.5013L71.8426 69.3557L70.1888 69.3443Z"
        fill="#32BCAD"
      />
      <path
        d="M20.9017 21.5999C22.6381 21.5953 24.3582 21.9349 25.9626 22.599C27.5669 23.2632 29.0237 24.2388 30.2488 25.4693L43.7959 39.0194C44.2668 39.4888 44.9046 39.7524 45.5695 39.7524C46.2344 39.7524 46.8722 39.4888 47.3431 39.0194L60.8418 25.5207C62.0659 24.2885 63.5224 23.3118 65.127 22.6471C66.7315 21.9823 68.4521 21.6429 70.1888 21.6484H71.8142L54.6713 4.50558C53.4019 3.23569 51.8947 2.22832 50.2359 1.54104C48.5771 0.853747 46.7991 0.5 45.0035 0.5C43.2079 0.5 41.43 0.853747 39.7711 1.54104C38.1123 2.22832 36.6051 3.23569 35.3357 4.50558L18.2556 21.5999H20.9017Z"
        fill="#32BCAD"
      />
      <path
        d="M85.9944 35.8316L75.6352 25.4724C75.4015 25.5679 75.1519 25.6182 74.8995 25.6207H70.1889C67.7377 25.6268 65.3879 26.6003 63.6506 28.3295L50.1519 41.8197C48.9362 43.0331 47.2887 43.7147 45.571 43.7147C43.8534 43.7147 42.2059 43.0331 40.9902 41.8197L27.4402 28.2782C25.7033 26.5479 23.3535 25.5734 20.9018 25.5665H15.1191C14.8808 25.5611 14.6453 25.5138 14.4234 25.4268L3.99851 35.8316C1.43801 38.3976 0 41.8745 0 45.4994C0 49.1244 1.43801 52.6013 3.99851 55.1672L14.4005 65.5693C14.622 65.4806 14.8578 65.4333 15.0963 65.4296H20.9018C23.3534 65.4224 25.7031 64.4478 27.4402 62.7178L40.9873 49.1707C43.4367 46.7241 47.7053 46.7241 50.1519 49.1707L63.6506 62.6665C65.3879 64.3957 67.7377 65.3692 70.1889 65.3754H74.8995C75.1519 65.3771 75.4017 65.4275 75.6352 65.5237L85.9944 55.1644C87.2643 53.895 88.2717 52.3878 88.959 50.729C89.6463 49.0701 90 47.2922 90 45.4966C90 43.701 89.6463 41.923 88.959 40.2642C88.2717 38.6054 87.2643 37.0982 85.9944 35.8288"
        fill="#32BCAD"
      />
    </svg>
  );
};

export const IconCreditCard = (props) => {
  return (
    <svg
      width="80"
      height="55"
      viewBox="0 0 80 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.32698 2.47152C0 3.11326 0 3.95334 0 5.63349V10.8335H80V5.6335C80 3.95334 80 3.11326 79.673 2.47152C79.3854 1.90704 78.9265 1.4481 78.362 1.16048C77.7202 0.833496 76.8802 0.833496 75.2 0.833496H4.8C3.11984 0.833496 2.27976 0.833496 1.63803 1.16048C1.07354 1.4481 0.614601 1.90704 0.32698 2.47152ZM80 20.8335H0V49.3668C0 51.047 0 51.8871 0.32698 52.5288C0.614601 53.0933 1.07354 53.5522 1.63803 53.8399C2.27976 54.1668 3.11984 54.1668 4.8 54.1668H75.2C76.8802 54.1668 77.7202 54.1668 78.362 53.8399C78.9265 53.5522 79.3854 53.0933 79.673 52.5288C80 51.8871 80 51.047 80 49.3668V20.8335ZM6.66618 40.8335H26.6662V47.5002H6.66618V40.8335Z"
        fill="#232323"
      />
    </svg>
  );
};
