import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default function ItemsComplementSkeleleton() {
  const repeat = [1, 2, 3];

  return (
    <>
      {repeat.map((_, index) => {
        return (
          <div
            className="box-items"
            key={index}
            style={{
              position: 'relative',
              width: '95%',
              height: 170,
              border: '1px solid #BDBDBD',
              borderRadius: 8,
              padding: 8,
              marginBottom: 16,
            }}
          >
            <div style={{ position: 'absolute', right: 14, top: 6 }}>
              <span className="label-action-item">Excluir complemento</span>
            </div>
            <div
              className="align-sections"
              style={{ marginTop: 12, width: '100%', display: 'flex' }}
            >
              <div className="left-section">
                <div className="column-items">
                  <span className="title-input">
                    Nome do complemento{' '}
                    <span className="required">(Obrigatório)</span>
                  </span>
                  <Skeleton
                    className="input-style"
                    animation="wave"
                    variant="rect"
                    width={'85%'}
                    height={40}
                    style={{ borderRadius: 8, marginTop: 8, border: 'none' }}
                  />
                </div>
                <div className="column-items">
                  <span className="title-input">
                    Preço <span className="required">(Obrigatório)</span>
                  </span>
                  <Skeleton
                    className="input-style"
                    animation="wave"
                    variant="rect"
                    width={'85%'}
                    height={40}
                    style={{ borderRadius: 8, marginTop: 8, border: 'none' }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
