import React, { useState } from 'react';
import { IconCreditCard, IconPIX } from '../../../../../assets/Icons';

function PaymentMethod({ onSelectedPayment }) {
  const [methodSelected, setMethodSelected] = useState('');
  const paymentMethods = [
    {
      selected: false,
      label: 'Pix',
      method: 'PIX',
      icon: <IconPIX />,
    },
    {
      selected: false,
      label: 'Cartão de crédito',
      method: 'CREDIT_CARD',
      icon: <IconCreditCard />,
    },
  ];

  return (
    <div className="payment-method-container">
      <div className="payment-method-container-content">
        {paymentMethods.map((item) => (
          <div key={item.method} className="payment-method-content">
            <button
              onClick={() => setMethodSelected(item)}
              className={
                item.method === methodSelected.method
                  ? 'payment-method-container__method-card  payment-method-container__method-card-selected'
                  : 'payment-method-container__method-card'
              }
            >
              {item.icon}
            </button>
            <span
              onClick={() => setMethodSelected(item)}
              className={
                item.method === methodSelected.method
                  ? 'payment-method-container-label  payment-method-container-label-selected'
                  : 'payment-method-container-label'
              }
            >
              {item.label}
            </span>
          </div>
        ))}
      </div>

      <button
        onClick={() => onSelectedPayment(methodSelected)}
        className="payment-method-container-confirm-method"
        disabled={!methodSelected}
        style={{ opacity: methodSelected ? 1 : 0.75 }}
      >
        Confirmar
      </button>
    </div>
  );
}

export default PaymentMethod;
