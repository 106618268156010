import { httpService } from './HttpService';

export const getResumeGridOrders = async (dh_inicio, dh_final, page, limit) => {
  const data = await httpService('painel/gestor', 'getResumeGridOrders', {
    dh_inicio,
    dh_final,
    page,
    limit,
  });
  return data;
};

export const getResumeCards = async (dh_inicio, dh_final) => {
  const data = await httpService('painel/gestor', 'getResumeCards', {
    dh_inicio,
    dh_final,
  });
  return data;
};

export const getTopProducts = async (dh_inicio, dh_final) => {
  const data = await httpService('painel/gestor', 'getTopProducts', {
    dh_inicio,
    dh_final,
  });
  return data;
};

export const getChartInvoicingOrders = async (dh_inicio, dh_final) => {
  const data = await httpService('painel/gestor', 'getChartInvoicingOrders', {
    dh_inicio,
    dh_final,
  });
  return data;
};

export const getOrderDetails = async (order) => {
  const data = await httpService('painel/gestor', 'getOrder', {
    order,
  });
  return data;
};
