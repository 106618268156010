import React, { useState, useEffect } from 'react';

function ProgressBar({ totalSteps, currentStep }) {
  const [current, setCurrent] = useState(1);
  const [steps, setSteps] = useState([1, 2, 3]);

  useEffect(() => {
    setCurrent(currentStep);
  }, [currentStep]);

  useEffect(() => {
    const addSteps = [];
    for (let index = 1; index <= totalSteps; index++) addSteps.push(index);
    setSteps(addSteps);
  }, [totalSteps]);

  return (
    <div className="w-100 d-flex flex-column align-items-start justify-content-center gap-1">
      <div className="hstack gap-1">
        <span className="fs-8">Etapa: </span>
        <span className="text-primary fw-bold fs-7">
          {current} / {steps.length}
        </span>
      </div>

      <div className="w-100 d-flex flex-row bg-body-secondary px-1 py-2 rounded-1 gap-2">
        {steps.map((value) => {
          return (
            <div
              key={value}
              className={`w-100 rounded-3 shadow-sm bg-primary py-1 ${
                current >= value ? '' : 'opacity-25'
              }`}
            ></div>
          );
        })}
      </div>
    </div>
  );
}

export default ProgressBar;
