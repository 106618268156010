import Configuracao from '../../../painel/Configuracao/Configuracao';
import OrderProvider from '../../../painel/Pedidos/OrderProvider/OrderProvider';
import Pedidos from '../../../painel/Pedidos/Pedidos';
import ProductProvider from '../../../painel/Produtos/ContextProduct/ContextProduct';
import Produtos from '../../../painel/Produtos/Produtos';
import QRCodePage from '../../../painel/QRCodePage/QRCodePage';
import Resumo from '../../../painel/Resumo/Resumo';
import StoreData from '../../../painel/StoreData/StoreData';
import Documentation from '../../../Support/Documentation';

export const GESTOR_TABS = [
  {
    component: (
      <OrderProvider>
        <Pedidos />
      </OrderProvider>
    ),
    label: 'Gestor de pedidos',
    soon: false,
    active: false,
    url: '/painel/pedidos',
    id: 'pedidos',
  },
  {
    component: (
      <ProductProvider>
        <Produtos />
      </ProductProvider>
    ),
    label: 'Cardápio',
    soon: false,
    active: true,
    url: '/painel/cardapio',
    id: 'cardapio',
  },
  {
    component: <Configuracao />,
    label: 'Configurações',
    soon: false,
    active: false,
    url: '/painel/configuracao',
    id: 'configuracao',
  },
  {
    component: null,
    label: 'Integração iFood',
    soon: true,
    active: false,
    url: '/painel/integracao',
    id: 'integracao',
  },
  {
    component: <QRCodePage />,
    label: 'QRCode de mesa',
    soon: false,
    active: false,
    url: '/painel/qrcode',
    id: 'qrcode',
  },
  {
    component: <Resumo />,
    label: 'Resumo geral',
    soon: false,
    active: false,
    url: '/painel/resumo',
    id: 'resumo',
  },
  {
    component: <StoreData />,
    label: 'Meu cadastro',
    soon: false,
    active: false,
    url: '/painel/dados-estabelecimento',
    id: 'estabelecimento',
  },
  {
    component: <Documentation />,
    label: 'Suporte',
    soon: false,
    active: false,
    url: '/painel/suporte',
    id: 'suporte',
  },
  {
    component: null,
    label: 'Sair',
    soon: false,
    active: false,
    url: 'login',
    id: 'login',
  },
];
