import React, { createContext, useContext, useState } from 'react';

const FormContent = createContext();

export default function ProductProvider({ children }) {
  const [productTempData, setProductTempData] = useState(null);
  const [idCategory, setIdCategory] = useState(null);
  const [productData, setProductData] = useState(null);
  const [idGroupComplement, setIdGroupComplement] = useState(null);
  const [complementsAssignedInProduct, setComplementsAssignedInProduct] =
    useState(null);

  const [sidebar, setSidebar] = useState(false);
  const [newItem, setNewItem] = useState(false);

  const [saveImage, setSaveImage] = useState({
    imagem_local: null,
    imagem_token: null,
  });

  return (
    <FormContent.Provider
      value={{
        productTempData,
        setProductTempData,
        sidebar,
        setSidebar,
        idCategory,
        setIdCategory,
        newItem,
        setNewItem,
        productData,
        setProductData,
        idGroupComplement,
        setIdGroupComplement,
        saveImage,
        setSaveImage,
        complementsAssignedInProduct,
        setComplementsAssignedInProduct,
      }}
    >
      {children}
    </FormContent.Provider>
  );
}

export function useFormProduct() {
  const context = useContext(FormContent);
  const {
    productTempData,
    setProductTempData,
    sidebar,
    setSidebar,
    idCategory,
    setIdCategory,
    newItem,
    setNewItem,
    productData,
    setProductData,
    idGroupComplement,
    setIdGroupComplement,
    saveImage,
    setSaveImage,
    complementsAssignedInProduct,
    setComplementsAssignedInProduct,
  } = context;
  return {
    productTempData,
    setProductTempData,
    sidebar,
    setSidebar,
    idCategory,
    setIdCategory,
    newItem,
    setNewItem,
    productData,
    setProductData,
    idGroupComplement,
    setIdGroupComplement,
    saveImage,
    setSaveImage,
    complementsAssignedInProduct,
    setComplementsAssignedInProduct,
  };
}
