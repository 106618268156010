import React, { useState, useContext, useEffect } from 'react';
import {
  MenuContext,
  StoreContext,
  userDataContext,
} from '../../MenuProvider/MenuProvider';
import './ConclueOrder.scss';
import { verifyCoupon } from '../../../services/Consumer';
import { toast } from 'react-toastify';
import {
  getDeliveryPrice,
  getItemInBrowserStorage,
  getOpeningHours,
  maskCEP,
  maskCurrencyBRLWithoutSymbol,
  maskPhoneBR,
  removeItemFromSession,
  removeMaskPrice,
  saveInBrowserStorage,
  verifyHasUserToken,
  verifyStoreIsOpen,
} from '../../../Utils/Index';
import HeaderInformations from '../../HeaderInformations/HeaderInformations';
import { useHistory } from 'react-router-dom';
import { getStore, newOrder } from '../../../services/Menu';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { IconPaymentMethod } from '../../../Utils/IconPaymentMethod';
import LoadingContent from '../../LoadingStoreInfos/LoadingContent';

function ConclueOrder() {
  const { userData, setUserData } = useContext(userDataContext);
  const { storeSettingsProvider, setStoreSettingsProvider, storePayments } =
    useContext(StoreContext);
  const {
    setProductsOrders,
    productsOrders,
    setTotalPrice,
    totalPrice,
    setPaymentMethod,
    paymentMethod,
  } = useContext(MenuContext);

  const { id } = useParams();

  const history = useHistory();

  const [userInfsFormated, setUserInfsFormated] = useState({
    phone: '',
    cep: '',
  });

  const [loadedUserInfs, setLoadedUserInfs] = useState(false);
  const [loadedOrderProduct, setLoadedOrderProduct] = useState(false);
  const [loadedPayment, setLoadedPayment] = useState(false);
  const [loadedStoreInfs, seLoadedStoreInfs] = useState(false);

  const [coupon, setCoupon] = useState('');
  const [lastCouponVerify, setLastCouponVerify] = useState('');
  const [activeCoupon, setActiveCoupon] = useState('');

  const [couponDiscount, setCouponDiscount] = useState('');
  const [totalOrderValue, setTotalOrderValue] = useState('');
  const [finalOrderValue, setFinalOrderValue] = useState('');
  const [deliveryPrice, setDeliveryPrice] = useState('');
  const [
    lastAddressSearchToVerifyDeliveryPrice,
    setLastAddressSearchToVerifyDeliveryPrice,
  ] = useState(null);

  const [note, setNote] = useState('');

  const [orderDispatched, setOrderDispatched] = useState(false);

  const [errorToSendOrder, setErrorToSendOrder] = useState(false);
  let [qtyRetry, setQtyRetry] = useState(1);
  let [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    getOrderProducts();
    getPayment();
    getUserInfs();
    getStoreSettings();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loadedUserInfs && loadedStoreInfs) callGetDeliveryPrice();
  }, [loadedUserInfs, loadedStoreInfs]); //eslint-disable-line react-hooks/exhaustive-deps

  async function callGetDeliveryPrice() {
    const { address } = userData;
    const { codigo } = storeSettingsProvider.settings.store;
    const { error, deliveryInfs } = await getDeliveryPrice(
      address.cep.replace(/\D/, ''),
      codigo,
      lastAddressSearchToVerifyDeliveryPrice
    );

    if (error) {
      backToCatalog();
      return;
    }

    insertPriceDelivery(deliveryInfs);
    setLastAddressSearchToVerifyDeliveryPrice(address.cep);
  }

  function insertPriceDelivery(deliverySettings) {
    const updatedPrice = totalPrice + deliverySettings.preco;
    setTotalPrice(updatedPrice);
    setFinalOrderValue(maskCurrencyBRLWithoutSymbol(updatedPrice));
    setDeliveryPrice(maskCurrencyBRLWithoutSymbol(deliverySettings.preco));
  }

  function getUserInfs() {
    if (!userData) verifyUserDataInStorage();
    else formatUserPersonalDetails(userData);
  }

  async function verifyUserDataInStorage() {
    const userDetails = await verifyHasUserToken();
    if (userDetails.validToken) {
      setUserData(userDetails.userData);
      formatUserPersonalDetails(userDetails.userData);
    } else {
      toast.warning('Por favor faça o login novamente.');
      backToCatalog();
    }
  }

  function formatUserPersonalDetails(userInfs) {
    setUserInfsFormated({
      phone: maskPhoneBR(userInfs.personal.phone),
      cep: maskCEP(userInfs.address.cep),
    });
    setLoadedUserInfs(true);
  }

  function getOrderProducts() {
    if (!productsOrders.length || !totalPrice) verifyOrderDetailsInStorage();
    else {
      const orderValue = maskCurrencyBRLWithoutSymbol(totalPrice);
      setTotalOrderValue(orderValue);
      setFinalOrderValue(orderValue);
      setLoadedOrderProduct(true);
    }
  }

  function verifyOrderDetailsInStorage() {
    const userCart = getItemInBrowserStorage('CART');

    if (userCart) {
      setProductsOrders(userCart.products);
      setTotalPrice(userCart.estimatedPrice);
      const orderValue = maskCurrencyBRLWithoutSymbol(userCart.estimatedPrice);
      setFinalOrderValue(orderValue);
      setTotalOrderValue(orderValue);
      setLoadedOrderProduct(true);
    } else {
      toast.error(
        'Ocorreu um erro ao buscar seu pedido, tente novamente ou entre em contato conosco.'
      );
      backToCatalog();
    }
  }

  function getPayment() {
    if (paymentMethod.length) setLoadedPayment(true);
    else verifyPaymentInStorage();
  }

  function verifyPaymentInStorage() {
    const storagePayment = getItemInBrowserStorage('PAYMENT');
    if (storagePayment) {
      setPaymentMethod(storagePayment);
      setLoadedPayment(true);
    } else {
      backToCatalog();
      toast.warning(
        'Ocorreu um erro ao buscar a opção de pagamento, tente novamente ou entre em contato conosco.'
      );
    }
  }

  function getStoreSettings() {
    if (storeSettingsProvider.settings) seLoadedStoreInfs(true);
    else callGetStore();
  }

  async function callGetStore() {
    if (storedStoreSettings()) return;
    const resp = await getStore(id);
    if (resp.success) {
      setStoreSettingsProvider({ idStore: id, settings: resp.results });
      seLoadedStoreInfs(true);
      saveInBrowserStorage('STORE_DETAILS', {
        idStore: id,
        settings: resp.results,
      });
    } else
      toast.error(
        'Erro ao buscar as informações do estabelecimento, por favor entre em contato conosco.'
      );
  }

  function storedStoreSettings() {
    const storeSettings = getItemInBrowserStorage('STORE_DETAILS');
    if (storeSettings) {
      if (storeSettings.idStore !== id) return false;
      setStoreSettingsProvider({
        idStore: id,
        settings: storeSettings.settings,
      });
      seLoadedStoreInfs(true);
      return true;
    }
    return false;
  }

  function backToCatalog() {
    history.goBack();
  }

  async function verifyIsValidCoupon(coupon) {
    if (coupon === lastCouponVerify) {
      let msg =
        coupon === activeCoupon
          ? 'Cupom já está aplicado ao seu pedido'
          : 'Este cupom já foi verificado';
      toast.warning(msg);
      return;
    }
    setLastCouponVerify(coupon);
    const response = await verifyCoupon({
      cod_estabelecimento: 1,
      cupom: coupon,
    });
    if (response.success) {
      toast.success('Cupom aplicado ao seu pedido.');
      setActiveCoupon(coupon);
      updatePriceOrder(response.result);
    } else if (!response.success && !response.erro)
      toast.error('Cupom é inválido ou está esgotado.');
    else
      toast.error(
        'Ocorreu um erro ao tentar validar este cupom, tente novamente ou entre em contato conosco.'
      );
  }

  function updatePriceOrder(couponDetails) {
    const productsPrice = removeMaskPrice(totalOrderValue);
    if (couponDetails.desconto_valor > 0) {
      const discount = couponDetails.desconto_valor;
      const totalFinalPriceOrder = productsPrice - discount;
      setCouponDiscount(maskCurrencyBRLWithoutSymbol(discount));
      setFinalOrderValue(maskCurrencyBRLWithoutSymbol(totalFinalPriceOrder));
    } else {
      const discount = couponDetails.desconto_percentual;
      const discountValue = (discount / 100) * productsPrice;
      const totalFinalPriceOrder = productsPrice - discountValue;
      setCouponDiscount(maskCurrencyBRLWithoutSymbol(discountValue));
      setFinalOrderValue(maskCurrencyBRLWithoutSymbol(totalFinalPriceOrder));
    }
  }

  function removeCoupon() {
    setLastCouponVerify('');
    setCoupon('');
    setCouponDiscount('');
    setActiveCoupon('');
    setFinalOrderValue(maskCurrencyBRLWithoutSymbol(totalPrice));
  }

  async function storeAvailableToCreateOrder() {
    const { store, hours_store } = storeSettingsProvider.settings;
    const { error, result } = await verifyStoreIsOpen(store.codigo);

    if (error) {
      toast.error(
        'Ocorreu um erro ao verificar o status de funcionamento do estabelecimento, tente novamente ou entre em contato conosco.'
      );
      return false;
    }

    if (!result) {
      backToCatalog();
      const openingHours = getOpeningHours(hours_store);
      toast.warning(
        `O estabelecimento está fechado, pedidos so serão aceitos entre ${openingHours.open} á ${openingHours.closed}.`,
        { autoClose: 0 }
      );
      return false;
    }

    return true;
  }

  async function sendNewOrder() {
    if (!(await storeAvailableToCreateOrder())) return;

    setOrderDispatched(true);
    const orderDetails = {
      cod_estabelecimento: storeSettingsProvider.settings.store.codigo,
      cod_tipo_pagto: paymentMethod.cod_tipo_pagto,
      entrega: true,
      troco: paymentMethod.troco ? removeMaskPrice(paymentMethod.troco) : 0,
      valor_entrega: removeMaskPrice(deliveryPrice),
      obs: note,
      cupom: activeCoupon,
      items: productsOrders,
    };
    const response = await newOrder(orderDetails);
    if (response.success) {
      toast.success('Pedido criado com sucesso');
      setErrorToSendOrder(false);
      clearTempVariables();
      backToCatalog();
      saveInBrowserStorage('NEW-ORDER', true);
    } else {
      toast.error(
        'Ocorreu um erro ao enviar seu pedido, tente novamente ou entre em contato consoco.'
      );
      timeOutToRetry();
      setErrorToSendOrder(true);
    }
  }

  function timeOutToRetry() {
    let tempQtyRetry = qtyRetry++;
    let countRetry = qtyRetry * 5;

    setQtyRetry(tempQtyRetry);
    setRetryCount(countRetry);

    const count = setInterval(() => {
      setRetryCount(countRetry--);
    }, 1000);

    setTimeout(() => {
      clearInterval(count);
      setOrderDispatched(false);
      setErrorToSendOrder(false);
    }, qtyRetry * 5001);
  }

  function clearTempVariables() {
    removeItemFromSession('CART');
    removeItemFromSession('PAYMENT');
    setProductsOrders([]);
  }

  return (
    <>
      {loadedOrderProduct ? (
        <>
          <HeaderInformations
            store={storeSettingsProvider.settings}
            payments={storePayments}
            isStoreMenu={false}
          ></HeaderInformations>

          <div className="w-100 p-2">
            <h2 className="fs-2 fw-bold text-center my-3">Finalizar pedido</h2>

            <div className="complete-order-section">
              <span className="complete-order-section-title">Itens</span>
              <div className="complete-order-padding-section">
                {productsOrders.map((order, index) => {
                  return (
                    <div key={index} className="complete-order__container">
                      <div className="complete-order__container-infs">
                        <span className="complete-order__container-infs-name-product">
                          {order.qtd}x&nbsp;<span>{order.name}</span>
                        </span>
                        <span className="complete-order__container-infs-price">
                          <small>R$</small>
                          {order.price.formated}
                        </span>
                      </div>
                      {order.complementos.length > 1 && (
                        <div className="complete-order__container complements">
                          {order.complementos.map((complement, indexC) => {
                            return (
                              <div
                                key={indexC}
                                className="complete-order__container-infs"
                              >
                                <span>
                                  {complement.qtd}x{' '}
                                  <span>{complement.name}</span>
                                </span>
                                <span className="complete-order__container-infs-price">
                                  {' '}
                                  <small>R$</small>{' '}
                                  {complement.price.formated.replace('R$', '')}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="complete-order-section">
              <span className="complete-order-section-title">Observação</span>
              <div className="complete-order-padding-section">
                <textarea
                  style={{ maxHeight: 80, minHeight: 80 }}
                  className="complete-order-textarea"
                  type="text"
                  placeholder="Observações do seu pedido"
                  required={true}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  maxLength="200"
                />
              </div>
            </div>

            {loadedPayment && (
              <div className="complete-order-section">
                <span className="complete-order-section-title">
                  Forma de pagamento
                </span>
                <div className="complete-order-padding-section">
                  <div className="complete-order-user-data">
                    {paymentMethod.cod_tipo_pagto !== 15 &&
                      paymentMethod.cod_tipo_pagto !== 32 && (
                        <span className="complete-order__payment">
                          <span className="complete-order__payment-icon">
                            <IconPaymentMethod
                              idPayment={paymentMethod.cod_tipo_pagto}
                            />
                          </span>
                          Cartão de{' '}
                          {paymentMethod.pagamento_grupo.toLowerCase()}{' '}
                          {paymentMethod.pagamento_tipo.toLowerCase()}
                        </span>
                      )}
                    {paymentMethod.cod_tipo_pagto === 15 && (
                      <span className="complete-order__payment">
                        <span className="complete-order__payment-icon">
                          <IconPaymentMethod idPayment={15} />
                        </span>
                        Dinheiro
                      </span>
                    )}
                    {paymentMethod.cod_tipo_pagto === 32 && (
                      <span className="complete-order__payment">
                        <span className="complete-order__payment-icon">
                          <IconPaymentMethod idPayment={32} />
                        </span>
                        PIX
                      </span>
                    )}
                    {paymentMethod.cod_tipo_pagto === 15 &&
                      paymentMethod.troco && (
                        <span className="complete-order__payment">
                          Troco para R$ {paymentMethod.troco}
                        </span>
                      )}
                  </div>
                </div>
              </div>
            )}

            {loadedUserInfs && (
              <div className="complete-order-section">
                <span className="complete-order-section-title">Entrega</span>
                <div className="complete-order-padding-section">
                  <div className="complete-order-user-data">
                    <span>
                      {userData.personal.name}&nbsp;({userInfsFormated.phone})
                    </span>
                    <span>
                      {userData.address.street}, {userData.address.number}
                    </span>
                    <span>{userInfsFormated.cep}</span>
                  </div>
                </div>
              </div>
            )}

            <div className="complete-order-no-card">
              <span className="complete-order-section-title">
                Possui cupom?
              </span>
              <div className="d-flex flex-column gap-2 w-100">
                <input
                  className="form-control"
                  placeholder="Insira aqui o seu cupom de desconto."
                  type="text"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value.toUpperCase())}
                />
                <button
                  type="button"
                  onClick={() => verifyIsValidCoupon(coupon)}
                  className={`btn btn-secondary-dark w-100 ${
                    Boolean(coupon) ? '' : 'pe-none opacity-50'
                  }`}
                  disabled={!Boolean(coupon)}
                >
                  Validar cupom
                </button>
                {Boolean(coupon) && Boolean(activeCoupon) && (
                  <button
                    type="button"
                    onClick={() => removeCoupon(coupon)}
                    className="btn btn-primary-fill text-primary fs-7"
                  >
                    Remover cupom
                  </button>
                )}
              </div>
            </div>

            <div className="complete-order-no-card">
              <div className="complete-order-no-card-order-value">
                <span className="complete-order-no-card-order-value-value-type">
                  Subtotal
                  <span className="complete-order-no-card-order-value-value-type-price">
                    <small>R$</small>
                    {totalOrderValue}
                  </span>
                </span>
                <div className="complete-order-no-card-order-value-value-type">
                  Taxa de entrega
                  <span className="complete-order-no-card-order-value-value-type-price">
                    <small>R$</small>&nbsp;{deliveryPrice}
                  </span>
                </div>
                {Boolean(couponDiscount) && (
                  <span className="complete-order-no-card-order-value-value-type">
                    Valor desconto
                    <span className="complete-order-no-card-order-value-value-type-price">
                      <small>R$</small>
                      {couponDiscount}
                    </span>
                  </span>
                )}
                {Boolean(finalOrderValue) && (
                  <span className="complete-order-no-card-order-value-value-type">
                    Total
                    <span className="complete-order-no-card-order-value-value-type-price">
                      <small>R$</small>
                      {finalOrderValue}
                    </span>
                  </span>
                )}
              </div>
            </div>

            <div className="d-flex flex-column w-100 gap-2">
              <button
                type="submit"
                className={`btn btn-primary py-3 fw-bold mb-3`}
                onClick={() => sendNewOrder()}
                disabled={orderDispatched}
              >
                Concluir pedido
              </button>

              <button
                type="button"
                className="btn btn-primary-fill"
                onClick={() => backToCatalog()}
              >
                <span>Voltar para o cardápio</span>
              </button>
            </div>

            {errorToSendOrder && (
              <div
                className="bg-dark bg-opacity-75 d-flex align-items-center justify-content-center left-0 posit position-fixed tion-fixed top-0 vh-100 w-100 z-3"
                style={{ left: 0 }}
              >
                <span className="fw-bold text-white">
                  Tente novamente em {retryCount} segundos
                </span>
              </div>
            )}
          </div>
        </>
      ) : (
        <LoadingContent />
      )}
    </>
  );
}

export default ConclueOrder;
