import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import React, { useEffect, useState, useRef, useContext } from 'react';
import * as Yup from 'yup';
import ProgressBar from '../../../shared/ProgressBar/ProgressBar';
import { toast } from 'react-toastify';
import { httpService } from '../../../services/HttpService';
import { StoreContext, userDataContext } from '../../MenuProvider/MenuProvider';
import {
  createAccountConsumer,
  loginConsumer,
} from '../../../services/Consumer';
import InputV2 from '../../../shared/InputV2/InputV2';
import {
  getDataBrasilApi,
  getDeliveryPrice,
  getStateByInitials,
  maskCEP,
  onlyNumbers,
  validCpf,
  validStepsPassword,
} from '../../../Utils/Index';
import ReCAPTCHA from 'react-google-recaptcha';

function CreateClientAccount({ backToSignIn, onCreateAccount }) {
  const [formValues, setFormValues] = useState(null);
  const [accountCreated, setAccountCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  const [step, setStep] = useState(1);

  const [disableFieldsAddress, setDisableFieldsAddress] = useState(true);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);

  const [passwordSecurityStatus, setPasswordSecurityStatus] = useState({
    label: null,
    color: null,
  });
  const [buttonLabel, setButtonLabel] = useState({
    next: 'Avançar',
    prev: 'Voltar',
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [lastToastID, setLastToastID] = useState('');

  const formRef = useRef();
  const recaptchaRef = useRef();

  const { setUserData } = useContext(userDataContext);
  const { storeSettingsProvider } = useContext(StoreContext);

  useEffect(() => {
    if (step === 3) {
      setButtonLabel({ next: 'Cadastrar', prev: 'Voltar' });
      return;
    }
    if (step === 4) {
      setButtonLabel({
        next: accountCreated ? 'Ir para pagamento' : 'Tentar novamente',
        prev: accountCreated ? 'Continuar comprando' : 'Entrar em contato',
      });
      return;
    }
    setButtonLabel({ next: 'Avançar', prev: 'Voltar' });
  }, [step, accountCreated]);

  async function handleSubmit(data) {
    if (step === 4 && accountCreated) {
      authenticated ? onCreateAccount() : backToSignIn();
      return;
    }

    verifyIsValidField(step, data).then((isValid) => {
      if (!isValid) return;
      if (step === 3) {
        callRegisterUser({ ...formValues, ...data });
        return;
      }
      saveDataForm(step, data);
      handleToNextStep();
    });
  }

  function saveDataForm(step, data) {
    if (step === 1 && !Boolean(formValues)) setFormValues(data);
    else if (step === 1 && Boolean(formValues))
      setFormValues({ ...formValues, ...data });
    else setFormValues({ ...formValues, ...data });
  }

  function handleToNextStep() {
    const nextStep = step + 1;
    if (step < 3) setStep(nextStep);
    setTimeout(() => updateFormValue(nextStep, formValues), 50);
  }

  async function callRegisterUser(params) {
    setIsLoading(true);
    const account = handleInfosToCreateAccount(params);
    const response = await createAccountConsumer(account);
    if (response) {
      setAccountCreated(true);
      authenticate(account);
      callGetDeliveryPrice();
      setStep(4);
    } else setAccountCreated(false);
    setIsLoading(false);
  }

  function handleInfosToCreateAccount(infos) {
    const { personal, address } = infos;
    personal.cpf = onlyNumbers(personal.cpf);
    personal.phone = onlyNumbers(personal.phone);
    address.cep = onlyNumbers(address.cep);
    return infos;
  }

  function authenticate(params) {
    const {
      personal: { phone },
      password: { password },
    } = params;
    loginConsumer(phone, password).then((response) => {
      if (!response || !response.success) {
        setAuthenticated(false);
        return;
      }
      setAuthenticated(true);
      setUserData(response.result);
    });
  }

  async function callGetDeliveryPrice() {
    const { address } = formValues;
    const { codigo } = storeSettingsProvider.settings.store;
    await getDeliveryPrice(onlyNumbers(address.cep), codigo, '');
  }

  Yup.addMethod(Yup.string, 'verifyIsUniqueData', function (errorMessage) {
    return this.test(`verify-is-unique-data`, errorMessage, function (value) {
      const { path } = this;
      let inputValue = value;

      if (!Boolean(value) || !verifyIsValidyData(inputValue)) return true;

      return new Promise((resolve) => {
        const field = path.split('.')[1].toLocaleUpperCase();

        if (field.includes('CPF') || field.includes('PHONE'))
          inputValue = onlyNumbers(inputValue);

        const validInfs = { field: field, value: inputValue };
        httpService('consumer/user', 'checkRegister', validInfs)
          .then((reponse) => {
            if (reponse.erro) throw reponse;
            resolve(reponse.success);
          })
          .catch(() => {
            toast.error(
              `Ocorreu um erro ao realizar a validação do campo, por favor tente novamente ou entre em contato conosco`
            );
            resolve(false);
          });
      });
    });
  });

  Yup.addMethod(Yup.string, 'verifyIsValidCpf', function (errorMessage) {
    return this.test(`verify-is-valid-cpf`, errorMessage, function (cpf) {
      return validCpf(cpf);
    });
  });

  async function verifyIsValidField(step, field) {
    try {
      const PersonalSchema = Yup.object().shape({
        personal: Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          cpf: Yup.string()
            .required('CPF é obrigatório')
            .verifyIsUniqueData('CPF já cadastrado')
            .verifyIsValidCpf('CPF inválido'),
          phone: Yup.string()
            .required('Telefone é obrigatório')
            .verifyIsUniqueData('Número já cadastrado')
            .matches(/[0-9]{2}\s[0-9]{5}-[0-9]{4}/, 'Número inválido'),
          email: Yup.string()
            .required('Email é obrigatório')
            .verifyIsUniqueData('Email já cadastrado')
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              'Email inválido'
            ),
        }),
      });

      const AddressSchema = Yup.object().shape({
        address: Yup.object().shape({
          cep: Yup.string()
            .required('CEP é obrigatório')
            .matches(/^[0-9]{5}-[0-9]{3}$/, 'CEP inválido'),
          street: Yup.string().required('Logradourdo é obrigatório'),
          number: Yup.string().required('Número é obrigatório'),
          state: Yup.string().required('Estado é obrigatório'),
          city: Yup.string().required('Cidade é obrigatório'),
          neighborhood: Yup.string().required('Bairro é obrigatório'),
          complement: Yup.string(),
        }),
      });

      const PasswordSchema = Yup.object().shape({
        password: Yup.object().shape({
          password: Yup.string().required('Senha é obrigatória'),
          passwordConfirmation: Yup.string()
            .required('Confirme sua senha')
            .oneOf([Yup.ref('password'), 'Teste'], 'As senhas não conferem'),
        }),
      });

      if (!recaptchaToken) {
        if (lastToastID && toast.isActive(lastToastID)) return false;
        const toastID = toast.error('Preencha o recaptcha', {
          autoClose: 7000,
        });
        setLastToastID(toastID);
        return false;
      }

      let schema;
      if (step === 1) schema = PersonalSchema;
      if (step === 2) schema = AddressSchema;
      if (step === 3) schema = PasswordSchema;
      await schema.validate(field, { abortEarly: false });
      formRef.current.setErrors({});
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  function verifyIsValidyData(userData) {
    const regexs = [
      /(?!(\d)\1{2}.\1{3}.\1{3}-\1{2})\d{3}\.\d{3}\.\d{3}\d{2}/gm,
      /[0-9]{2}\s[0-9]{5}[0-9]{4}/,
      /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w\w+)+$/,
    ];
    let test = false;
    test = regexs.map((regex) => {
      return regex.test(userData);
    });
    return test.some((el) => el === true);
  }

  function backStep() {
    if (step === 4) {
      if (!accountCreated) {
        window.open(`${window.location.host}/suporte`);
        return;
      }
      onCreateAccount();
      return;
    }

    setPasswordSecurityStatus({ label: null, color: null });
    const nextRegisterStep = step - 1;
    if (step === 1) {
      backToSignIn();
      return;
    }
    setTimeout(() => updateFormValue(nextRegisterStep, formValues), 50);
    setStep(nextRegisterStep);
  }

  function getScopeForm(step) {
    let scope = 'personal';

    if (step === 1) scope = 'personal';
    else if (step === 2) scope = 'address';
    else if (step === 3) scope = 'password';

    return scope;
  }

  function updateFormValue(step, datas) {
    const fieldsValue = datas;
    const scope = getScopeForm(step);

    if (
      !Boolean(scope) ||
      !Boolean(fieldsValue) ||
      !Boolean(fieldsValue[scope])
    )
      return;

    Object.keys(fieldsValue[scope]).forEach((field) => {
      formRef.current.setFieldValue(
        `${scope}.${field}`,
        fieldsValue[scope][field]
      );
    });
  }

  function resetFieldForm(step) {
    const scope = getScopeForm(step);
    const form = formRef.current.getData();

    Object.keys(form[scope]).forEach((field) => {
      formRef.current.clearField(`${scope}.${field}`);
    });
  }

  async function getAddressByCep(cep) {
    const cepOnlyNumber = onlyNumbers(cep);
    if (cepOnlyNumber.length < 8) return;
    const reponse = await getDataBrasilApi('cep', cepOnlyNumber);
    if (Boolean(reponse.cep)) {
      reponse.cep = maskCEP(reponse.cep);
      reponse.state = getStateByInitials(reponse.state).nome;
      setDisableFieldsAddress(true);
      setFormValues({ ...formValues, ...{ address: reponse } });
      updateFormValue(2, { address: reponse });
    } else {
      resetFieldForm(2);
      setDisableFieldsAddress(false);
      toast.error('Não foi possível encontrar um endereço para este CEP');
    }
  }

  function setStatusSecurityPassword(password) {
    if (!password?.length) {
      setPasswordSecurityStatus({ label: '', color: '' });
      return;
    }

    let acmSecurity = 0;
    const validatorsPassword = validStepsPassword(password, true);
    Object.keys(validatorsPassword).forEach((validator) => {
      if (validatorsPassword[validator]) acmSecurity++;
    });
    setPasswordSecurityStatus(returnPasswordStatus(acmSecurity));
  }

  function returnPasswordStatus(qtyStepsSecurity) {
    let status = { label: 'Senha frágil', color: '#E4001B' };
    if (qtyStepsSecurity < 2) return status;
    else if (qtyStepsSecurity <= 4)
      status = { label: 'Senha razoável', color: '#E46E00' };
    else if (qtyStepsSecurity === 5)
      status = { label: 'Senha forte', color: '#27AE60' };

    return status;
  }

  return (
    <div className="w-100 vstack justify-content-center align-items-center p-2">
      {step !== 4 && (
        <div className="w-100 vstack ">
          <h1 className="fs-5 fw-bold mb-2">
            Faça seu cadastro para realizar e acomapanhar seus pedidos.
          </h1>
          <ProgressBar
            totalSteps={3}
            currentStep={step}
            customColor={storeSettingsProvider.settings.store.cor}
          />
        </div>
      )}

      <Form
        ref={formRef}
        onSubmit={(path) => handleSubmit(path)}
        className="w-100 vstack justify-content-center align-items-center mt-3"
      >
        <div className="w-100 d-flex flex-column gap-2">
          {step === 1 && (
            <Scope path="personal">
              <InputV2
                label="Nome completo"
                name="name"
                type="text"
                placeholder="Nome completo"
                maxLength={30}
              />
              <InputV2
                name="cpf"
                type="text"
                label="CPF"
                mask="CPF"
                placeholder="CPF"
                maxLength={14}
              />
              <InputV2
                name="phone"
                type="text"
                label="Telefone"
                mask={'PHONE'}
                placeholder="Telefone"
                maxLength={13}
              />
              <InputV2
                name="email"
                type="email"
                label="E-mail"
                placeholder="E-mail"
                maxLength={40}
              />

              <div className="w-100 hstack justify-content-center py-2">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="normal"
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_LOGIN}
                  onExpired={() => setRecaptchaToken()}
                  onError={() => setRecaptchaToken()}
                  onChange={(token) => setRecaptchaToken(token)}
                ></ReCAPTCHA>
              </div>
            </Scope>
          )}

          {step === 2 && (
            <Scope path="address">
              <InputV2
                name="cep"
                type="text"
                label="CEP"
                mask="CEP"
                maxLength={9}
                placeholder="CEP"
                onUpdateValue={(value) => getAddressByCep(value)}
              />

              <InputV2
                name="street"
                type="text"
                label="Endereço"
                placeholder="Endereço"
                disabled={disableFieldsAddress}
              />

              <div className="w-100 d-flex flex-row justify-content-between gap-1">
                <InputV2
                  name="number"
                  type="text"
                  label="Número"
                  placeholder="Número"
                />

                <InputV2
                  name="neighborhood"
                  type="text"
                  label="Bairro"
                  placeholder="Bairro"
                  disabled={disableFieldsAddress}
                />
              </div>

              <div className="w-100 d-flex flex-row justify-content-between gap-1">
                <InputV2
                  name="city"
                  type="text"
                  label="Cidade"
                  placeholder="Cidade"
                  disabled={disableFieldsAddress}
                />

                <InputV2
                  name="state"
                  type="text"
                  label="Estado"
                  placeholder="Estado"
                  disabled={disableFieldsAddress}
                />
              </div>

              <InputV2
                name="complement"
                type="text"
                label="Complemento"
                placeholder="Complemento"
                maxLength={200}
              />
            </Scope>
          )}

          {step === 3 && (
            <Scope path="password">
              <InputV2
                name="password"
                label="Senha"
                placeholder="Senha"
                containerClass="position-relative"
                type={displayPassword ? 'text' : 'password'}
                onUpdateValue={(value) => setStatusSecurityPassword(value)}
              >
                <button
                  type="button"
                  className="z-1 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
                  style={{ top: 30, right: 4 }}
                  onClick={() => setDisplayPassword(!displayPassword)}
                >
                  <span className="material-symbols-outlined fs-5">
                    {displayPassword ? 'visibility' : 'visibility_off'}
                  </span>
                </button>
              </InputV2>

              <InputV2
                name="passwordConfirmation"
                label="Confirmação de senha"
                placeholder="Confirmação de senha"
                containerClass="position-relative"
                type={displayConfirmPassword ? 'text' : 'password'}
              >
                <button
                  type="button"
                  className="z-1 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
                  style={{ top: 30, right: 4 }}
                  onClick={() =>
                    setDisplayConfirmPassword(!displayConfirmPassword)
                  }
                >
                  <span className="material-symbols-outlined fs-5">
                    {displayConfirmPassword ? 'visibility' : 'visibility_off'}
                  </span>
                </button>
              </InputV2>
              {passwordSecurityStatus.label && (
                <span
                  className="fs-8 w-100 text-center fw-bold d-block py-2"
                  style={{ color: passwordSecurityStatus.color }}
                >
                  {passwordSecurityStatus.label}
                </span>
              )}
            </Scope>
          )}

          {step === 4 && (
            <div className="w-100 h-100">
              {accountCreated.error ? (
                <div className="vstack gap-2">
                  <div className="vstack gap-2">
                    <h2 className="fw-bold fs-5 m-0 p-0 text-center">
                      Oh no 😔, ocorreu um erro ao cadastrar seus dados...
                    </h2>
                    <span className="fs-7 text-secondary">
                      mas não fique triste! Tente novamente ou entre em contato
                      conosco.
                    </span>
                    <span className="sub-message">{accountCreated.label}</span>
                  </div>
                </div>
              ) : (
                <div className="vstack gap-2">
                  <div className="vstack gap-2">
                    <h2 className="fw-bold fs-5 m-0 p-0 text-center">
                      Conta criada com sucesso.
                    </h2>
                    <span className="fs-7 text-secondary">
                      Agora você pode acessar sua conta para finalizar seu
                      pedido e aproveitar todas as funcionalidades da nossa
                      plataforma.
                    </span>
                    <span className="sub-message">{accountCreated.label}</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="w-100 hstack justify-content-between mt-3 gap-2">
          <button
            type="button"
            className={`btn btn-outline-dark ${
              isLoading ? 'pe-none opacity-50' : ''
            }`}
            disabled={isLoading}
            onClick={() => backStep()}
          >
            {buttonLabel.prev}
          </button>
          <button
            type="submit"
            className={`btn btn-primary w-50 ${
              isLoading ? 'pe-none opacity-50' : ''
            }`}
            disabled={isLoading}
          >
            {buttonLabel.next}
          </button>
        </div>
      </Form>
    </div>
  );
}

export default CreateClientAccount;
