import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { getGeral } from '../../services/Configuration';

export default function QRCodePage() {
  const [link, setLink] = useState(null);

  useEffect(() => {
    getLink();
  }, []);

  async function getLink() {
    const response = await getGeral();
    if (response.success && response.results.link) {
      setLink(response.results.link);
    }
  }

  const downloadQR = (linkurl) => {
    const svgElement = document.getElementById(linkurl);
    const svgString = new XMLSerializer()
      .serializeToString(svgElement)
      .replace(`height="256"`, `height="800"`)
      .replace(`width="256"`, `width="800"`);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      const size = 800;
      canvas.width = size;
      canvas.height = size;
      context.drawImage(img, 0, 0);

      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `qrcode_${linkurl}.png`;
      link.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgString)}`;
  };

  return (
    <div className="vstack p-2" style={{ maxWidth: 550 }}>
      <div className="vstack pb-3">
        <h3 className="fs-3 fw-bold m-0 p-0 text-primary">QRCode de mesa</h3>
        <span className="fs-7 text-body-secondary">
          Com este QRCode, seus clientes podem escaneá-lo usando seus celulares
          e acessar virtualmente seu cardápio.
        </span>
      </div>

      {link && (
        <div className="gap-3 vstack">
          <div className="hstack justify-content-center">
            <div className="max-w-fit">
              <QRCode
                id={link}
                size={60}
                style={{ height: 240, width: 240 }}
                value={`https://www.delivery.legal/${link}?qrcode=true&canShop=false`}
                viewBox={`0 0 60 60`}
              />
            </div>
          </div>
          <button
            className="btn btn-primary w-100"
            style={{ margin: 0 }}
            onClick={() => downloadQR(link)}
          >
            Baixar
          </button>
        </div>
      )}
    </div>
  );
}
