import React from 'react';
import './Plans.scss';
import AnnualPlanCard from './components/AnnualPlanCard';
import MonthlyPlanCard from './components/MonthlyPlanCard';

function Plans({ showCurrentPlan, onSelectPlan }) {
  return (
    <div className="d-flex flex-column-reverse flex-md-column gap-4">
      <div className="align-items-center d-flex flex-column flex-md-row gap-5 w-100 justify-content-md-center">
        <AnnualPlanCard onSelectPlan={() => onSelectPlan('ANNUAL')} />
        <MonthlyPlanCard onSelectPlan={() => onSelectPlan('MONTHLY')} />
      </div>

      <button
        className="btn btn-outline-primary "
        style={{ maxWidth: 'fit-content' }}
        type="submit"
        onClick={() => showCurrentPlan()}
      >
        Ver plano atual
      </button>
    </div>
  );
}

export default Plans;
