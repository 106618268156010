import React, { createContext, useState } from 'react';

export const SalesRepresentativeInfs = createContext({});

export default function SalesRepresentativeProvider(props) {
  const [billingDataContext, setBillingDataContext] = useState(null);
  const [proposalsHistory, setProposalsHistory] = useState(null);
  const [personalDataContext, setPersonalDataContext] = useState(null);
  const [representativePixContext, setRepresentativePixContext] =
    useState(null);
  const [financialHistory, setFinancialHistory] = useState(null);

  return (
    <SalesRepresentativeInfs.Provider
      value={{
        billingDataContext,
        setBillingDataContext,
        proposalsHistory,
        setProposalsHistory,
        personalDataContext,
        setPersonalDataContext,
        representativePixContext,
        setRepresentativePixContext,
        financialHistory,
        setFinancialHistory,
      }}
    >
      {props.children}
    </SalesRepresentativeInfs.Provider>
  );
}
