import React, { useState, useEffect } from 'react';
import './PaymentsAccept.scss';
import { IconPaymentMethod } from '../../../../Utils/IconPaymentMethod';
import { Checkbox } from '@material-ui/core';
import {
  deepClone,
  getItemInBrowserStorage,
  groupItems,
} from '../../../../Utils/Index';
import { toast } from 'react-toastify';
import { allPaymentsMethod } from '../../../../services/Configuration';
import { updatePaymentsAccept } from '../../../../services/Configuration';

function PaymentsAccept({ firstAccess = false, handleConfigureStore }) {
  const [paymentsType, setPaymentsType] = useState([]);
  const [loadedPayments, setLoadedPayments] = useState(false);

  useEffect(() => {
    getStoreSettings();
  }, []);

  function getStoreSettings() {
    const storeDetails = getItemInBrowserStorage('CLIENT_DATA', false);
    if (!storeDetails) {
      toast.error(
        'Ocorreu um erro ao buscar as informações do seu estabelecimento, por favor faça o login novamente.'
      );
      window.open('/login', '_self');
      return false;
    }
    callGetPaymentsAccept(storeDetails.cod_estabelecimento);
    return true;
  }

  async function callGetPaymentsAccept(idStore) {
    const response = await allPaymentsMethod(idStore);
    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao buscar os pagamentos aceitos pelo estabelecimento, por favor tente novamente ou entre em contato conosco.'
      );
      return;
    }
    handlerPaymentsActived(response.results);
  }

  function handlerPaymentsActived(acceptedPayments) {
    const paymentsAccept = acceptedPayments.map((payment) => {
      payment.ativo = Boolean(payment.ativo);
      return payment;
    });
    groupPayments(paymentsAccept);
  }

  function groupPayments(payments) {
    let groupPaymentMethod = groupItems(payments, 'tipo');
    groupPaymentMethod.sort((a, b) => {
      if (a.length > b.length) return -1;
      else return 1;
    });
    setPaymentsType(groupPaymentMethod);
    setLoadedPayments(true);
  }

  function handlePaymentMethod(paymentSelected, indexGroup) {
    let updatePaymentSelected = paymentsType[indexGroup].map((payment) => {
      if (payment.codigo === paymentSelected.codigo) {
        if (payment.first_value === undefined)
          payment.first_value = payment.ativo;
        payment.ativo = !payment.ativo;
        payment.dont_change =
          payment.first_value !== undefined &&
          payment.first_value === payment.ativo;
      }
      return payment;
    });
    paymentsType[indexGroup] = updatePaymentSelected;
    updatePaymentSelected = deepClone(paymentsType);
    setPaymentsType(updatePaymentSelected);
  }

  function somePaymentSelected() {
    const groupWithSomePaymentSelected = [];
    paymentsType.forEach((groupPayments) => {
      groupWithSomePaymentSelected.push(
        groupPayments.some((payment) => payment.ativo)
      );
    });
    return groupWithSomePaymentSelected.some((el) => el);
  }

  function hasSomeChange() {
    const groupWithChangesInPaymetsActive = [];
    paymentsType.forEach((groupPayments) => {
      groupWithChangesInPaymetsActive.push(
        groupPayments.some(
          (payment) =>
            payment.first_value !== undefined &&
            payment.first_value !== payment.ativo &&
            payment.dont_change !== undefined &&
            !payment.dont_change
        )
      );
    });
    return groupWithChangesInPaymetsActive.some((el) => el);
  }

  function getPaymentsWithActiveChanged() {
    const paymetsWithActiveChanged = [];
    paymentsType.forEach((groupPayments) => {
      groupPayments.forEach((payment) => {
        if (
          payment.first_value !== undefined &&
          payment.first_value !== payment.ativo &&
          payment.dont_change !== undefined &&
          !payment.dont_change
        )
          paymetsWithActiveChanged.push({
            codigo: payment.codigo,
            ativo: payment.ativo,
          });
      });
    });

    return paymetsWithActiveChanged;
  }

  function savePaymentsAccept() {
    if (!somePaymentSelected()) {
      toast.error('Selecione ao menos um método de pagamento');
      return;
    }

    if (!hasSomeChange()) {
      toast.warning('Não há alterações a serem salvas.');
      return;
    }

    saveChanges(getPaymentsWithActiveChanged());
    if (firstAccess) handleConfigureStore(true);
  }

  async function saveChanges(payments) {
    let responses = [];
    await Promise.all(
      payments.map(async (payment) => {
        const response = await updatePaymentsAccept(payment);
        responses.push({
          success: response.success,
          cod_payment: payment.codigo,
        });
      })
    );

    if (responses.every((el) => el.success)) {
      toast.success('Pagamentos aceitos atualizado com sucesso.');
      return;
    }

    toast.success('Ocorreu um erro ao atualizar o método de pagamento.');
  }

  return (
    loadedPayments && (
      <div className="conf-store">
        <div className="vstack pb-3">
          <h3 className="fs-3 fw-bold m-0 p-0 text-primary">
            Formas de pagamento
          </h3>
          <span className="fs-7 text-body-secondary">
            Escolha quais formas de pagamento seus clientes poderão utilizar.
          </span>
        </div>

        <div className="payments-methods">
          {paymentsType.map((groupPayments, indexGroup) => {
            return (
              <div key={indexGroup} className="payments-methods-column">
                <span className="payments-methods-group">
                  {groupPayments[0].tipo}
                </span>
                <div className="payments-methods-column">
                  {groupPayments.map((payment, indexPayment) => {
                    return (
                      <div
                        key={indexPayment}
                        className="payments-methods-payment"
                      >
                        <Checkbox
                          type="checkbox"
                          checked={payment.ativo}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          onClick={() =>
                            handlePaymentMethod(payment, indexGroup)
                          }
                        />
                        <IconPaymentMethod
                          idPayment={payment.cod_forma_pagto}
                        />
                        <span className="payments-methods-label-method">
                          {payment.bandeira}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <button
          type="button"
          onClick={() => savePaymentsAccept()}
          className="Btn add-items save-updates"
        >
          Salvar alterações
        </button>
      </div>
    )
  );
}

export default PaymentsAccept;
