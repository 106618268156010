import { httpService } from './HttpService';

export const paymentsHistory = async () => {
  const response = await httpService('painel/gestor', 'paymentsHistory', {});
  return response;
};

export const changePassword = async (passwords) => {
  const response = await httpService('painel/gestor', 'changePassword', {
    ...passwords,
  });
  return response;
};
