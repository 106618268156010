import React from 'react';

export default function ConfirmOption({ option }) {
  return (
    <div className="vstack h-100 w-100 align-items-center justify-content-center gap-3">
      <span className="fw-bold fs-6 text-center">
        Deseja realmente deleter este item?
      </span>

      <div className="w-100 hstack align-items-center justify-content-center gap-5">
        <button
          className="btn btn-outline-secondary"
          onClick={() => option(true)}
        >
          Sim
        </button>
        <button
          className="btn btn-primary"
          style={{ margin: 8 }}
          onClick={() => option(false)}
        >
          Não
        </button>
      </div>
    </div>
  );
}
