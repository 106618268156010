import React, { useEffect, useState } from 'react';
import {
  getAddressStore,
  getCompletAddress,
  updateAddressStore,
} from '../../../services/Configuration';
import { getStateByInitials, maskCEP } from '../../../Utils/Index';
import { toast } from 'react-toastify';
import Checkbox from '@material-ui/core/Checkbox';

export default function Endereco({
  firstAccess = false,
  handleConfigureStore,
}) {
  const [cep, setCep] = useState('');
  const [nro, setNro] = useState('');
  const [street, setStreet] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState({
    codigo: 0,
    initials: 'SP',
    nome: 'São Paulo',
  });
  const [retirada, setRetirada] = useState(false);

  const [listStates, setListStates] = useState([]);
  const [previusSearchCEP, setPreviusSearchCEP] = useState(false);

  const [completAddress, setCompletAddress] = useState([
    { input: 'cep', value: '' },
    { input: 'nro', value: '' },
    { input: 'street', value: '' },
    { input: 'city', value: '' },
    { input: 'state', value: '' },
    { input: 'retirada', value: false },
  ]);

  useEffect(() => {
    getAddress();
    setListStates(getStateByInitials('', true));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  async function checkCEP() {
    const numberCEP = cep.replace(/\D/g, '');
    if (numberCEP.length === 8 && numberCEP !== previusSearchCEP) {
      let resp = await getCompletAddress(numberCEP);
      if (resp.errors === undefined) {
        setStreet(resp.street);
        setNeighborhood(resp.neighborhood);
        setCity(resp.city);
        setState(getStateByInitials(resp.state));
        setPreviusSearchCEP(numberCEP);
        validAddress('street', resp.street);
        validAddress('city', resp.city);
        validAddress('state', resp.state);
      } else {
        toast.error('Não foi possível localizar um endereço com este CEP.');
        setPreviusSearchCEP(numberCEP);
      }
    } else if (numberCEP === previusSearchCEP) {
      toast.warning('CEP informado já foi pesquisado, por favor tente outro.');
    } else {
      toast.error('CEP inválido.');
    }
  }

  async function getAddress() {
    let resp = await getAddressStore();
    if (resp.success) {
      setStreet(resp.results.logradouro);
      setNro(resp.results.logradouro_nro);
      setNeighborhood(resp.results.bairro);
      setCity(resp.results.cidade);
      setState(getStateByInitials(resp.results.uf));
      setCep(maskCEP(resp.results.cep));
      setRetirada(resp.results.aceita_retirada === 1);
      setAllValid(
        resp.results.cep,
        resp.results.cidade,
        resp.results.uf,
        resp.results.logradouro_nro,
        resp.results.logradouro,
        resp.results.aceita_retirada
      );
    }
  }

  async function saveAddress() {
    const numberCEP = cep.replace(/\D/g, '');
    let resp = await updateAddressStore(
      street,
      nro,
      neighborhood,
      city,
      state.initials,
      numberCEP,
      retirada
    );
    if (resp.success) {
      toast.success('Endereço salvo com sucesso.');
    }

    if (firstAccess) handleConfigureStore(true);
  }

  function getStateByCode(code) {
    const selected = listStates.find((state) => state.codigo === code);
    setState(selected);
  }

  function callSaveAddress() {
    if (
      completAddress.some(
        (field) =>
          field.value === null ||
          field.value === undefined ||
          field.value === '-'
      )
    ) {
      toast.error('Por favor preencha o endereço corretamente.');
      return;
    }
    if (firstAccess) handleConfigureStore(true);
    saveAddress();
  }

  function validAddress(input, value) {
    let temp = completAddress.map((item) => {
      if (item.input === input) {
        item.value = value;
      }
      return item;
    });
    setCompletAddress(temp);
  }

  function setAllValid(cep, city, state, nro, street, retirada) {
    setCompletAddress([
      { input: 'cep', value: cep },
      { input: 'nro', value: nro },
      { input: 'street', value: street },
      { input: 'city', value: city },
      { input: 'state', value: state },
      { input: 'retirada', value: retirada === 0 },
    ]);
  }

  return (
    <div className="w-100" style={{ maxWidth: 550 }}>
      <div className="vstack pb-3">
        <h3 className="fs-3 fw-bold m-0 p-0 text-primary">Endereço</h3>
        <span className="fs-7 text-body-secondary">
          Informe o endereço do seu estabelecimento para calcularmos a região de
          atendimento com precisão.
        </span>
      </div>

      <div className="w-100 pb-3">
        <div className="hstack align-items-center gap-2">
          <input
            className="form-control py-2"
            required={true}
            value={cep}
            onChange={(e) => setCep(maskCEP(e.target.value))}
            onBlur={() => validAddress('cep', cep)}
            placeholder="CEP"
            maxLength={9}
          />
          <button
            className="btn btn-outline-primary w-25"
            onClick={() => checkCEP()}
          >
            Buscar
          </button>
        </div>
      </div>

      <div className="w-100 p-2 rounded-3 shadow-sm border border-danger-subtle">
        <div className="vstack gap-2">
          <input
            className="form-control"
            required={true}
            type="text"
            placeholder="Endereço"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            onBlur={() => validAddress('street', street)}
            maxLength={70}
          />

          <div className="w-100 hstack justify-content-between gap-2">
            <input
              className="form-control"
              required={true}
              type="number"
              placeholder="Número"
              value={nro}
              onChange={(e) => setNro(e.target.value)}
              onBlur={() => validAddress('nro', nro)}
              maxLength={10}
            />

            <input
              className="form-control"
              required={true}
              type="text"
              placeholder="Cidade"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              onBlur={() => validAddress('city', city)}
              maxLength={10}
            />
          </div>

          <select
            className="form-select"
            aria-label="Estados"
            onChange={(event) => getStateByCode(+event.target.value)}
          >
            {listStates.map((item) => (
              <option
                key={item.codigo}
                value={item.codigo}
                selected={item.codigo === state?.codigo}
              >
                {item.nome}
              </option>
            ))}
          </select>

          <div>
            <Checkbox
              type="checkbox"
              value={retirada}
              checked={retirada}
              onChange={() => validAddress('retirada', !retirada)}
              onClick={() => setRetirada(!retirada)}
              color="default"
            />
            <span
              style={{ color: '#3E3E3E', fontWeight: 'bold', fontSize: 16 }}
            >
              Aceita retirada no local?
            </span>
          </div>
        </div>
      </div>

      <button
        className="btn btn-primary fw-bold mt-4 w-100"
        onClick={() => callSaveAddress()}
      >
        Salvar alterações
      </button>
    </div>
  );
}
