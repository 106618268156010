import React from 'react';

function Loader({ title }) {
  return (
    <div className="subscription-process-flex-column subscription-process__pix-loading">
      <span>
        {title
          ? title
          : 'Carregando informações do pagamento, por favor aguarde.'}
      </span>
      <div className="spinner-border spinner-border-sm" role="status"></div>
    </div>
  );
}

export default Loader;
