import React, { useEffect, useState } from 'react';
import Complements from './Complements/Complements';
import Primary from './Primary/Primary';
import { toast } from 'react-toastify';
import EventEmitter from '../../../services/Event';

export default function CreateProduct({
  product,
  categories,
  categoryToCreateProduct,
}) {
  const [currentSection, setCurrentSection] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);

  const [nameProduct, setNameProduct] = useState('Novo Produto');
  const [saveProductData, setSaveProductData] = useState(null);

  const [enableComplementSection, setEnableComplementSection] = useState(false);

  useEffect(() => {
    if (product) setNameProduct(product.nome);
    EventEmitter.on('goToProduct', (sectionNumber) =>
      setCurrentSection(sectionNumber)
    );
    return () => EventEmitter.off('goToProduct');
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!product) return;
    setEnableComplementSection(true);
    setSaveProductData(product);
    return () => {
      setEnableComplementSection(false);
      setSaveProductData(null);
    };
  }, [product]);

  function showComplements(step) {
    if (enableComplementSection) setCurrentSection(step);
    else toast.error('Crie um produto para poder adicionar complementos.');
  }

  function saveProductDataAndEnableComplementSection(productData) {
    setSaveProductData(productData);
    setEnableComplementSection(true);
  }

  const tabs = ['Principal', 'Complementos'];

  function onChangeTab(tabIndex) {
    if (tabIndex === 1) {
      setCurrentSection(1);
      return;
    }
    showComplements(2);
  }

  return (
    <div className="d-flex flex-column p-2">
      <span className="text-dark fw-bold fs-2 pb-2">{nameProduct}</span>
      <div className="d-inline-flex justify-content-between fw-bold text-black fs-5 border-bottom border-2">
        <div className="d-inline-flex gap-3">
          {tabs.map((tab, index) => (
            <span
              key={index}
              className={`pb-2 pointer ${
                currentSection === index + 1 &&
                'text-primary border-bottom border-3 h-100 border-primary'
              }`}
              onClick={() => onChangeTab(index + 1)}
            >
              {tab}
            </span>
          ))}
        </div>
        {currentSection === 2 && (
          <span className="fw-bold text-primary">{currentStep} / 2</span>
        )}
      </div>
      {currentSection === 1 && (
        <Primary
          productData={saveProductData}
          categories={categories}
          idCategoryToCreateProduct={categoryToCreateProduct}
          saveProductData={(productData) =>
            saveProductDataAndEnableComplementSection(productData)
          }
        />
      )}
      {currentSection === 2 && (
        <Complements
          productData={saveProductData}
          pageComplement={setCurrentStep}
        />
      )}
    </div>
  );
}
