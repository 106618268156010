import { httpServiceUpload } from './HttpService';

export async function uploadStoreLogo(image) {
  if (!image) throw new Error('Por favor, selecione um arquivo.');
  const formData = new FormData();
  formData.append('image', image);
  return await httpServiceUpload('painel/firebase', 'logo', formData);
}

export async function uploadProductImage(image, idProduct, idCategory) {
  if (!image) throw new Error('Por favor, selecione um arquivo.');
  const formData = new FormData();
  formData.append('image', image);
  formData.append('idProduct', idProduct);
  formData.append('idCategory', idCategory);
  return await httpServiceUpload('painel/firebase', 'product', formData);
}
