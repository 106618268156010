import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {
  updateTimeDelivery,
  getTimeDelivery,
} from '../../../services/Configuration';
import { toast } from 'react-toastify';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function TempoEstimado({
  firstAccess = false,
  handleConfigureStore,
}) {
  const [segunda, setSegunda] = React.useState({
    delivery: '00:30',
    retirada: '00:20',
  });
  const [terca, setTerca] = React.useState({
    delivery: '00:30',
    retirada: '00:20',
  });
  const [quarta, setQuarta] = React.useState({
    delivery: '00:30',
    retirada: '00:20',
  });
  const [quinta, setQuinta] = React.useState({
    delivery: '00:30',
    retirada: '00:20',
  });
  const [sexta, setSexta] = React.useState({
    delivery: '00:30',
    retirada: '00:20',
  });
  const [sabado, setSabado] = React.useState({
    delivery: '00:30',
    retirada: '00:20',
  });
  const [domingo, setDomingo] = React.useState({
    delivery: '00:30',
    retirada: '00:20',
  });
  const [feriado, setFeriado] = React.useState({
    delivery: '00:30',
    retirada: '00:20',
  });

  React.useEffect(() => {
    obterTempoEstimado();
    return () => {
      setSegunda(null);
      setQuarta(null);
      setQuinta(null);
      setSexta(null);
      setSabado(null);
      setDomingo(null);
      setFeriado(null);
    };
  }, []);

  async function salvarTempoEstimado() {
    let item = {
      obj: {
        segunda: segunda,
        terca: terca,
        quarta: quarta,
        quinta: quinta,
        sexta: sexta,
        sabado: sabado,
        domingo: domingo,
        feriado: feriado,
      },
    };

    let resp = await updateTimeDelivery(item);
    if (!resp || !resp.success) {
      toast.error(
        'Erro ao atualizar tempo estimado, tente novamente ou entre em contato conosco.'
      );
      return;
    }

    toast.success('Tempo estimado atualizado com sucesso.');
    if (firstAccess) handleConfigureStore(true);
  }

  async function obterTempoEstimado() {
    let resp = await getTimeDelivery();
    if (resp.success) {
      let temp = resp.results[0];
      setSegunda({
        delivery: temp.segunda_delivery,
        retirada: temp.segunda_retirada,
      });
      setTerca({
        delivery: temp.terca_delivery,
        retirada: temp.terca_retirada,
      });
      setQuarta({
        delivery: temp.quarta_delivery,
        retirada: temp.quarta_retirada,
      });
      setQuinta({
        delivery: temp.quinta_delivery,
        retirada: temp.quinta_retirada,
      });
      setSexta({
        delivery: temp.sexta_delivery,
        retirada: temp.sexta_retirada,
      });
      setSabado({
        delivery: temp.sabado_delivery,
        retirada: temp.sabado_retirada,
      });
      setDomingo({
        delivery: temp.domingo_delivery,
        retirada: temp.domingo_retirada,
      });
      setFeriado({
        delivery: temp.feriado_delivery,
        retirada: temp.feriado_retirada,
      });
    }
  }

  return (
    <div className="conf-store">
      <div className="vstack pb-2">
        <h3 className="fs-3 fw-bold m-0 p-0 text-primary">Tempo estimado</h3>
        <span className="fs-7 text-body-secondary">
          Configure o tempo estimado para entrega/retirada por dias da semana e
          feriados.
        </span>
      </div>

      <TableContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Dia</StyledTableCell>
              <StyledTableCell align="center">Entrega</StyledTableCell>
              <StyledTableCell align="center">Retirada</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow key={1}>
              <StyledTableCell component="th" scope="row">
                Segunda-feira
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={segunda.delivery}
                  onChange={(e) =>
                    setSegunda({ ...segunda, delivery: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={segunda.retirada}
                  onChange={(e) =>
                    setSegunda({ ...segunda, retirada: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={2}>
              <StyledTableCell component="th" scope="row">
                Terça-feira
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={terca.delivery}
                  onChange={(e) =>
                    setTerca({ ...terca, delivery: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={terca.retirada}
                  onChange={(e) =>
                    setTerca({ ...terca, retirada: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={3}>
              <StyledTableCell component="th" scope="row">
                Quarta-feira
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={quarta.delivery}
                  onChange={(e) =>
                    setQuarta({ ...quarta, delivery: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={quarta.retirada}
                  onChange={(e) =>
                    setQuarta({ ...quarta, retirada: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={4}>
              <StyledTableCell component="th" scope="row">
                Quinta-feira
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={quinta.delivery}
                  onChange={(e) =>
                    setQuinta({ ...quinta, delivery: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={quinta.retirada}
                  onChange={(e) =>
                    setQuinta({ ...quinta, retirada: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={5}>
              <StyledTableCell component="th" scope="row">
                Sexta-feira
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={sexta.delivery}
                  onChange={(e) =>
                    setSexta({ ...sexta, delivery: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={sexta.retirada}
                  onChange={(e) =>
                    setSexta({ ...sexta, retirada: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={6}>
              <StyledTableCell component="th" scope="row">
                Sábado
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={sabado.delivery}
                  onChange={(e) =>
                    setSabado({ ...sabado, delivery: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={sabado.retirada}
                  onChange={(e) =>
                    setSabado({ ...sabado, retirada: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={7}>
              <StyledTableCell component="th" scope="row">
                Domingo
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={domingo.delivery}
                  onChange={(e) =>
                    setDomingo({ ...domingo, delivery: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={domingo.retirada}
                  onChange={(e) =>
                    setDomingo({ ...domingo, retirada: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={8}>
              <StyledTableCell component="th" scope="row">
                Feriado
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={feriado.delivery}
                  onChange={(e) =>
                    setFeriado({ ...feriado, delivery: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  id="time"
                  label="Tempo"
                  type="time"
                  value={feriado.retirada}
                  onChange={(e) =>
                    setFeriado({ ...feriado, retirada: e.target.value })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div
        className="Btn add-items save-updates"
        onClick={() => salvarTempoEstimado()}
      >
        Salvar alterações
      </div>
    </div>
  );
}
