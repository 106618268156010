import { httpService } from './HttpService';

export const getStoreOptions = async () => {
  const data = await httpService('painel/gestor', 'getStoreOptions', {});
  return data;
};

export const handlerSoundAlert = async (status) => {
  const data = await httpService('painel/gestor', 'setSoundAlert', { status });
  return data;
};

export const handlerAutoPrint = async (status) => {
  const data = await httpService('painel/gestor', 'setAutoPrint', { status });
  return data;
};

export const handlerStoreStatus = async (status) => {
  const data = await httpService('painel/gestor', 'setStoreStatus', { status });
  return data;
};

export const handlerConfirmationModal = async (status) => {
  const data = await httpService('painel/gestor', 'setAlertOnChangeStatus', {
    status,
  });
  return data;
};

export const getQtyOrdersDay = async (dh_inicio, dh_fim) => {
  const data = await httpService('painel/gestor', 'getQtyOrdersDay', {
    dh_inicio,
    dh_fim,
  });
  return data;
};

export const getAllOrders = async (dh_inicio, dh_fim) => {
  const data = await httpService('painel/gestor', 'getAllOrders', {
    dh_inicio,
    dh_fim,
  });
  return data;
};

export const getOrder = async (order) => {
  const data = await httpService('painel/gestor', 'getOrder', { order });
  return data;
};

export const acceptOrder = async (order) => {
  const data = await httpService('painel/gestor', 'acceptOrder', { order });
  return data;
};

export const declineOrder = async (order, is_decline) => {
  const data = await httpService('painel/gestor', 'declineOrder', {
    order,
    is_decline,
  });
  return data;
};

export const setStatusOrder = async (order) => {
  const data = await httpService('painel/gestor', 'setStatusOrder', { order });
  return data;
};

export const concludedOrder = async (order) => {
  const data = await httpService('painel/gestor', 'concludedOrder', { order });
  return data;
};

export const updateOpeningHours = async (hours) => {
  const data = await httpService('painel/gestor', 'updateHoursOpen', {
    ...hours,
  });
  return data;
};

export const aliveStoreHelth = async () => {
  const data = await httpService('painel/gestor', 'aliveStoreHelth ', {});
  return data;
};
