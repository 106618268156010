import React, { useEffect, useState } from 'react';
import { IconPaymentMethod } from '../../../Utils/IconPaymentMethod';
import { getOrder } from '../../../services/OrderManager';
import { formatDateTime, maskCEP, maskCurrencyBRL } from '../../../Utils/Index';
import { Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';

export default function OrderCard({ infsOrder, orderInformation }) {
  const [orderData, setOrderData] = useState({});
  const [blockClick, setBlockClick] = useState(false);

  useEffect(() => {
    setOrderData(infsOrder);
    return () => {
      setOrderData(null);
    };
  }, [infsOrder]);

  async function callInfsOrder(order) {
    setBlockClick(true);
    const resp = await getOrder(order.codigo);
    if (resp.success) {
      formatInfOrder(resp.result, order);
    } else
      toast.error(
        'Ocorreu um erro ao buscar dados do pedido, por favor tente novamente ou entre em contato conosco.'
      );
    setTimeout(() => {
      setBlockClick(false);
    }, 500);
  }

  function formatInfOrder(completDataOrder, orderInf) {
    let orderInfs = completDataOrder;
    orderInfs.payment_method = orderInf.pagamento_grupo.includes('Outros')
      ? 'Dinheiro'
      : `Cartão de ${orderInf.pagamento_grupo}`;
    orderInfs.cep_entrega = maskCEP(orderInfs.endereco_cep);
    orderInfs.valor_total = maskCurrencyBRL(orderInfs.valor_total);
    orderInfs.last_status = getHistoryStatus(orderInfs.historico, orderInf);
    orderInformation(orderInfs);
  }

  function getHistoryStatus(recentStatus, lastStatus) {
    let timeStatus = '';
    if (recentStatus.length) {
      timeStatus = {
        time: formatDateTime(recentStatus[0].datahora),
        status: recentStatus[0].status,
      };
    } else {
      timeStatus = {
        time: formatDateTime(lastStatus.start_time),
        status: lastStatus.status,
      };
    }
    return timeStatus;
  }

  return (
    <div
      className={`border vstack justify-content-between border rounded-2 w-100 p-1 h-100 mb-2 cursor-pointer ${
        orderData.selected ? 'border-primary' : ''
      }`}
      onClick={() =>
        !orderData.selected && !blockClick ? callInfsOrder(orderData) : null
      }
    >
      <div className="hstack w-100 justify-content-between align-items-center gap-1">
        <div className="position-relative hstack gap-2">
          <span className="fw-bold fs-7">#{orderData.codigo}</span>
          <IconPaymentMethod idPayment={orderData.cod_tipo_pagto} />
          {orderData.cupom_ativo && (
            <span className="material-symbols-outlined text-dark bg-warning rounded-circle p-1 fs-6">
              redeem
            </span>
          )}
        </div>

        <div
          className="p-0 border px-2 py-1 fs-7 position-relative rounded-2 text-white fw-bold"
          style={{
            background: orderData.color_status,
            fontSize: orderData.cod_pedido_status > 5 ? 12 : 14,
          }}
        >
          {orderData.status}
        </div>
      </div>

      <span className="fs-7 w-100">{orderData.nome}</span>

      <div className="vstack w-100">
        <div className="w-100 hstack justify-content-between align-items-center">
          <span className="fw-bold fs-7">{orderData.valor_total}</span>
          <button
            className={`btn btn-primary-fill p-0 border border-light px-2 py-1 fs-7 position-relative rounded-2 text-white fw-bold ${
              orderData.entrega ? 'bg-primary' : 'bg-warning'
            }`}
          >
            {orderData.entrega ? 'Entrega' : 'Retirada'}
          </button>
        </div>

        <div className="hstack w-100 gap-1">
          <Tooltip title="Horário que o pedido foi criado">
            <span className="fs-7 text-black">{orderData.dh_criacao}</span>
          </Tooltip>
          <span className="material-symbols-outlined text-primary p-0 fs-7">
            chevron_right
          </span>
          <Tooltip title="Horário que o pedido foi iniciado">
            <span className="fs-7 text-black">{orderData.dh_inicio}</span>
          </Tooltip>
          <span className="material-symbols-outlined text-primary p-0 fs-7">
            chevron_right
          </span>
          <Tooltip title="Horário que o pedido foi finalizado/cancelado">
            <span className="fs-7 text-black">{orderData.dh_concluido}</span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
