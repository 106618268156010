import React, { useContext, useEffect, useState } from 'react';
import './setupMenu.scss';
import { toast } from 'react-toastify';
import {
  getQtyOrdersDay,
  getStoreOptions,
  handlerAutoPrint,
  handlerConfirmationModal,
  handlerSoundAlert,
  handlerStoreStatus,
  updateOpeningHours,
} from '../../../services/OrderManager';
import { formatHours, getActualDay } from '../../../Utils/Index';
import { OrderContext } from '../OrderProvider/OrderProvider';
import moment from 'moment';
import SpringModal from '../../../shared/Modal/Modal';
import { getHourStore } from '../../../services/Configuration';
import EventEmitter from '../../../services/Event';
import SettingCard from './components/SettingCard';
import StoreStatusAlertModal from './components/StoreStatusAlertModal';

export default function SetupMenu() {
  const [HeaderOptionsLoaded, setHeaderOptionsLoaded] = useState(false);
  const [totalRequests, setTotalRequests] = useState(false);
  const [soundAlert, setSoundAlert] = useState(false);
  const [AutoPrint, setAutoPrint] = useState(false);
  const [statusBusiness, setStatusBusiness] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openToday, setOpenToday] = useState(false);
  const [configureNewOpeningHours, setConfigureNewOpeningHours] =
    useState(false);

  const [timeDay, setTimeDay] = useState([]);
  const [hoursStore, setHoursStore] = useState([]);

  const [openingHours, setOpeningHours] = useState({
    timeToOpen: null,
    timeToClose: null,
    currentDay: '',
    openToday: false,
    openedNow: false,
  });

  const {
    setAlertOnChangeStatus,
    setPrintOrder,
    setEnableAudioAlert,
    setTotalOrders,
    totalOrders,
    setStoreName,
    setServiceHours,
  } = useContext(OrderContext);

  useEffect(() => {
    setTotalRequests(totalOrders);
  }, [totalOrders]);

  useEffect(() => {
    callStoreOptions();
    return () => statusStore(false);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  async function callStoreOptions() {
    const resp = await getStoreOptions();
    if (resp.success) {
      callGetHourStore(resp.result);
      getDayTime(resp.result);
      setStatusBusiness(Boolean(resp.result.status_loja));
      setSoundAlert(Boolean(resp.result.alerta_sonoro));
      setEnableAudioAlert(Boolean(resp.result.alerta_sonoro));
      setAutoPrint(Boolean(resp.result.auto_impressao));
      setPrintOrder(Boolean(resp.result.auto_impressao));
      setConfirmationModal(Boolean(resp.result.aviso_mod_status_pedido));
      setAlertOnChangeStatus(Boolean(resp.result.aviso_mod_status_pedido));
      setStoreName(resp.result.nome_estabelecimento);
    } else
      toast.error(
        'Ocorreu um erro ao buscar dados do estabelecimento, por favor tente novamente.'
      );
  }
  const [lastDaySearched, setLastDaySearched] = useState('');

  async function callQtyOrdersDay() {
    const day = moment().format('YYYY-MM-DD');
    verifyIsNewDay(day);
    const resp = await getQtyOrdersDay(
      `${day} ${openingHours.timeToOpen}`,
      `${day} ${openingHours.timeToClose}`
    );
    if (resp.success) {
      setTotalRequests(resp.result.qty_orders_day);
      setTotalOrders(resp.result.qty_orders_day);
    } else
      toast.error(
        'Ocorreu um erro ao buscar os pedidos diários, por favor tente novamente.'
      );
  }

  async function callGetHourStore(confStore) {
    const currentDay = getActualDay(true);
    openingHours.currentDay = currentDay;
    const actualDay = getActualDay();
    const hoursStore = await getHourStore();

    if (hoursStore.success) {
      setHoursStore(hoursStore.results[0]);

      Object.keys(hoursStore.results[0]).forEach((el, index) => {
        switch (el) {
          case `${actualDay}_inicio`:
            openingHours.timeToOpen = Object.values(hoursStore.results[0])[
              index
            ].substring(0, 5);
            break;
          case `${actualDay}_fim`:
            openingHours.timeToClose = Object.values(hoursStore.results[0])[
              index
            ].substring(0, 5);
            break;
          case `${actualDay}_aberto`:
            openingHours.openToday =
              Object.values(hoursStore.results[0])[index] === 1;
            break;
          default:
            break;
        }
      });

      setOpeningHours(openingHours);
      setServiceHours(openingHours);

      if (!openingHours.openToday) {
        if (Boolean(confStore.status_loja)) statusStore(false);
        setConfigureNewOpeningHours(true);
        setOpenModal(true);
        return;
      }

      if (!isOpen(openingHours.timeToOpen, openingHours.timeToClose)) {
        setConfigureNewOpeningHours(true);
        setOpenToday(true);
        setOpenModal(true);
        return;
      }

      handlerStatusOrder();
    } else
      toast.error(
        'Ocorreu um erro ao configurar o hórario de funcionamento, por favor recarregue a página ou entre em contato conosco.'
      );
  }

  function isOpen(timeToOpen, timeToClose) {
    const now = new Date();
    const [openHour, openMinute] = timeToOpen.split(':').map(Number);
    const [closeHour, closeMinute] = timeToClose.split(':').map(Number);
    const openTime = new Date();
    openTime.setHours(openHour, openMinute, 0, 0);
    const closeTime = new Date();
    closeTime.setHours(closeHour, closeMinute, 0, 0);
    if (closeTime < openTime) return now >= openTime || now <= closeTime;
    return now >= openTime && now <= closeTime;
  }

  async function handlerOptions(option, active) {
    let msg = '';
    let msgError = 'Erro ao alterar status d';
    let success, resp;
    const activaded = active ? 'ativad' : 'desativad';
    switch (option) {
      case 'sound':
        resp = await handlerSoundAlert(active);
        success = resp.success;
        if (resp.success) {
          setSoundAlert(active);
          setEnableAudioAlert(active);
          msg = `Alerta sonoro ${activaded}o.`;
        } else msg = `${msgError}o alerta sonoro.`;
        break;
      case 'print':
        resp = await handlerAutoPrint(active);
        success = resp.success;
        if (resp.success) {
          setAutoPrint(active);
          setPrintOrder(active);
          msg = `Impressão automatica ${activaded}a.`;
        } else msg = `${msgError}a impressão automatica.`;
        break;
      case 'status':
        resp = await handlerStoreStatus(active);
        success = resp.success;
        if (resp.success) {
          setStatusBusiness(active);
          openingHours.openedNow = active;
          setServiceHours(openingHours);
          setOpeningHours(openingHours);
          EventEmitter.emit('update-hours-opening', openingHours);
          msg = `Estabelecimento ${active ? 'aberto' : 'fechado'}.`;
        } else msg = `${msgError}e funcionamento do estabelecimento.`;
        break;
      case 'ifood':
        toast.warn('Em breve');
        // setActivatedIfood(active)
        // msg = `Integração com o Ifood ${activaded}a.`
        break;
      case 'modal':
        resp = await handlerConfirmationModal(active);
        success = resp.success;
        if (success) {
          setConfirmationModal(active);
          setAlertOnChangeStatus(active);
          msg = `Modal de confirmação ${activaded}o.`;
        }
        break;
      default:
        break;
    }
    success ? toast.success(msg) : toast.error(msg);
  }

  async function statusStore(status) {
    if (status === statusBusiness) return;
    handlerStoreStatus(status);
  }

  function getDayTime(infsStore) {
    const actualDay = getActualDay();
    let temp = {};
    Object.keys(infsStore).forEach((item, index) => {
      switch (item) {
        case `${actualDay}_retirada`:
          temp = {
            ...temp,
            ...{ retirada: formatHours(Object.values(infsStore)[index]) },
          };
          break;
        case `${actualDay}_delivery`:
          temp = {
            ...temp,
            ...{ delivery: formatHours(Object.values(infsStore)[index]) },
          };
          break;
        case `${actualDay}_aberto`:
          temp = { ...temp, ...{ aberto: Object.values(infsStore)[index] } };
          break;
        default:
          break;
      }
    });
    setTimeDay(temp);
    setHeaderOptionsLoaded(true);
  }

  async function handlerStatusOrder(hours) {
    if (!configureNewOpeningHours) {
      openingHours.openedNow = true;
      statusStore(true);
      setStatusBusiness(true);
      setOpeningHours(openingHours);
      setServiceHours(openingHours);
      EventEmitter.emit('update-hours-opening', openingHours);
      setOpenModal(false);
      callQtyOrdersDay();
      return;
    }

    const today = getActualDay();
    Object.keys(hoursStore).forEach((day) => {
      if (day === `${today}_inicio`) hoursStore[day] = `${hours.timeToOpen}:00`;
      if (day === `${today}_fim`) hoursStore[day] = `${hours.timeToClose}:00`;
      if (day === `${today}_aberto`) hoursStore[day] = 1;
    });

    const response = await updateOpeningHours(hoursStore);

    if (response.success) {
      openingHours.openToday = true;
      setOpeningHours(openingHours);
      setServiceHours(openingHours);
      EventEmitter.emit('update-hours-opening', openingHours);
      handlerOptions('status', true);
      setOpenModal(false);
      toast.success('Horário atualizado com sucesso!');
    } else
      toast.error(
        'Ocorreu um erro ao atualizar o horário de funcionamento, tente novamente ou entre em contato conosco.'
      );
  }

  function verifyIsNewDay(day) {
    if (!Boolean(lastDaySearched)) {
      setLastDaySearched(day);
      return;
    }

    if (lastDaySearched === day) {
      toast.warning(
        `Horário de funcionamento do estabelecimento foi atualizado para ${getActualDay(
          true
        ).toLowerCase()}.`,
        10000
      );
    }
  }

  const cards = [
    {
      title: 'Média tempo de entrega',
      valueField: 'delivery',
      isTotalRequests: false,
    },
    {
      title: 'Média tempo de retirada',
      valueField: 'retirada',
      isTotalRequests: false,
    },
    {
      title: 'Total de pedidos (hoje)',
      valueField: 'totalRequests',
      isTotalRequests: true,
    },
  ];

  return (
    <>
      <div className="bg-light d-grid gap-2 my-3 orders-header-container p-2 rounded-2 shadow-sm">
        {cards.map((item) => (
          <SettingCard
            key={item.valueField}
            title={item.title}
            loaded={HeaderOptionsLoaded}
            value={
              item.isTotalRequests ? totalRequests : timeDay[item.valueField]
            }
          />
        ))}

        <SettingCard
          title="Status do estabelecimento"
          useSwitch={true}
          loaded={HeaderOptionsLoaded}
          value={statusBusiness ? 'Aberto' : 'Fechado'}
          highlight={true}
          booleanValue={statusBusiness}
          onClick={() => handlerOptions('status', !statusBusiness)}
        />

        <SettingCard
          title="Confirmar alterações"
          icon="check_circle"
          loaded={HeaderOptionsLoaded}
          value={confirmationModal ? 'Sim' : 'Não'}
          highlight={true}
          booleanValue={confirmationModal}
          onClick={() => handlerOptions('modal', !confirmationModal)}
        />

        <SettingCard
          title="Alerta sonoro"
          icon="notifications"
          loaded={HeaderOptionsLoaded}
          value={soundAlert ? 'Sim' : 'Não'}
          highlight={true}
          booleanValue={soundAlert}
          onClick={() => handlerOptions('sound', !soundAlert)}
        />

        <SettingCard
          title="Auto impressão"
          icon="print"
          loaded={HeaderOptionsLoaded}
          value={AutoPrint ? 'Sim' : 'Não'}
          highlight={true}
          booleanValue={AutoPrint}
          onClick={() => handlerOptions('print', !AutoPrint)}
        />

        <SettingCard
          title="Receber pedidos através do Ifood"
          icon="hub"
          loaded={HeaderOptionsLoaded}
          value={AutoPrint ? 'Sim' : 'Não'}
          highlight={true}
          booleanValue={false}
          onClick={() => handlerOptions('ifood', !false)}
        />
      </div>

      <SpringModal handleOpen={openModal} handleClose={setOpenModal}>
        <StoreStatusAlertModal
          configureNewHour={configureNewOpeningHours}
          openToday={openToday}
          openingHours={openingHours}
          close={() => setOpenModal(false)}
          openStore={(hours) => handlerStatusOrder(hours)}
        />
      </SpringModal>
    </>
  );
}
