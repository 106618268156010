import React from 'react';
import PlanBillingInfos from '../../PlanBillingInfos/PlanBillingInfos';

function ActivePlan({ plan, displayPlans }) {
  return (
    <div className="active-plan">
      <div className="active-plan__content">
        <div className="active-plan__content__infos">
          <h3 className="active-plan__content__infos-name">
            Plano {plan.nome}
          </h3>
          <span className="active-plan__content__infos-days">
            {plan.dias_restantes}
          </span>
          <span className="active-plan__content__infos-label">
            Dias {plan.dias_restantes > 1 ? 'restantes' : 'restante'}
          </span>
        </div>

        <button
          type="button"
          className="plans-container__card-buy-plan-btn monthly-primary-color"
          onClick={() => displayPlans()}
        >
          {plan.permitir_cobranca ? 'Atualizar plano' : 'Renovar plano'}
        </button>
      </div>

      <PlanBillingInfos plan={plan} />
    </div>
  );
}

export default ActivePlan;
