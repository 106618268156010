import React, { useState } from 'react';
import UserData from './Infos/UserData';
import UpdateUserData from './Infos/UpdateUserData';
import SpringModal from '../../../shared/Modal/Modal';

function AccountInfos({ close }) {
  const [fieldToEdit, setFieldToEdit] = useState('');

  return (
    <SpringModal
      handleOpen={true}
      handleClose={() => close()}
      customStyleModal={{ width: 'calc(100% - 16px)', maxWidth: 500 }}
    >
      {fieldToEdit ? (
        <UpdateUserData type={fieldToEdit} close={() => setFieldToEdit('')} />
      ) : (
        <UserData
          editPassword={() => setFieldToEdit('PASSWORD')}
          editAddress={() => setFieldToEdit('ADDRES')}
          close={() => close()}
        />
      )}
    </SpringModal>
  );
}

export default AccountInfos;
