import React, { useEffect, useState } from 'react';
import './InputRadio.scss';

export default function InputRadio({
  labelInput,
  selected,
  clicked,
  customColor,
}) {
  const [isSelected, setIsSelected] = useState(false);
  const [personalizedStyles, setPersonalizedStyles] = useState(null);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  useEffect(() => {
    if (!customColor) return;
    setPersonalizedStyles(customColor);
  }, [customColor]);

  return (
    <div className="align-input-radio" onClick={() => clicked(true)}>
      <div style={{ borderColor: personalizedStyles }} className="border-radio">
        {isSelected && (
          <div
            style={{ backgroundColor: personalizedStyles }}
            className="circle-radio"
          ></div>
        )}
      </div>
      <span className="text-input">{labelInput}</span>
    </div>
  );
}
