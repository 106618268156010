import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ expiryDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(expiryDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [expiryDate]);

  return (
    <div>
      {timeLeft.days !== undefined ? (
        <div style={{ fontSize: 14, padding: '8px 0px', color: 'gray' }}>
          Código expira em:{' '}
          {timeLeft.days > 0 && <span>{timeLeft.days}d </span>}
          {timeLeft.hours > 0 && <span>{timeLeft.hours}h </span>}
          {timeLeft.minutes > 0 && <span>{timeLeft.minutes}m </span>}
          {timeLeft.seconds > 0 && <span>{timeLeft.seconds}s</span>}
        </div>
      ) : (
        <span>Código expirado</span>
      )}
    </div>
  );
};

export default CountdownTimer;
