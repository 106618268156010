import React, { useEffect, useState } from 'react';
import './HamburgerIcon.scss';

export default function HamburgerIcon({
  initialValue,
  onToggleVisibility,
  disableScrollWhenOpen,
  customClass = '',
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (initialValue === isOpen) return;
    setIsOpen(initialValue);
    updateScrollY(initialValue);
  }, [initialValue]);

  function toggleVisibility() {
    const value = !isOpen;
    setIsOpen(value);
    if (onToggleVisibility) onToggleVisibility(value);
    updateScrollY(value);
  }

  function updateScrollY(state) {
    if (disableScrollWhenOpen) {
      if (state) window.scrollTo({ top: 0, behavior: 'smooth' });
      document.body.style.overflowY = state ? 'hidden' : 'auto';
    }
  }

  return (
    <button
      className={`btn btn-primary-fill hamburguer-button p-0 m-0 position-relative ${
        isOpen ? 'hamburguer-button-open' : ''
      }`}
      onClick={() => toggleVisibility()}
    >
      <div className={`hamburguer-button__content ${customClass}`}></div>
    </button>
  );
}
