import React, { createContext, useState } from 'react';

export const SalesRepresentative = createContext({});
export const StoreOwner = createContext({});

export default function AuthenticationProvider(props) {
  //Represenante
  const [representativeData, setRepresentativeData] = useState(null);

  //Estabelecimento
  const [storeOwnerData, setStoreOwnerData] = useState(null);

  //Primeiro acesso
  const [storeData, setStoreData] = useState(null);

  return (
    <SalesRepresentative.Provider
      value={{ representativeData, setRepresentativeData }}
    >
      <StoreOwner.Provider
        value={{ storeOwnerData, setStoreOwnerData, storeData, setStoreData }}
      >
        {props.children}
      </StoreOwner.Provider>
    </SalesRepresentative.Provider>
  );
}
