import axios from 'axios';
import { toast } from 'react-toastify';
import { getItemInBrowserStorage } from '../Utils/Index';

export async function httpService(
  path,
  endpoint,
  body,
  consumer = false,
  method = 'post',
  customURI = ''
) {
  const URI = customURI ? customURI : process.env.REACT_APP_DELIVERY_WS_URL;
  let tokenInStorage;

  if (consumer) tokenInStorage = getItemInBrowserStorage('USER_TOKEN', false);
  else tokenInStorage = await getItemInBrowserStorage('TOKEN', false);

  const url = `${URI}/api/${path}/${endpoint}`;

  try {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, POST',
      Authorization: `Bearer ${tokenInStorage}`,
    };
    const config = {
      headers: headers,
      withCredentials: false,
    };
    let response;
    if (!method || method === 'post')
      response = await axios.post(url, JSON.stringify(body), config);
    else if (method === 'get') response = await axios.get(url, config);
    else if (method === 'delete') response = await axios.delete(url, config);
    else if (method === 'put')
      response = await axios.put(url, JSON.stringify(body), config);
    return response.data;
  } catch (error) {
    if (
      window.location.pathname !== '/login' &&
      error.response !== undefined &&
      error.response.status === 401 &&
      !consumer
    ) {
      toast.error(
        'Ocorreu um erro realizar sua autenticação na plataforma, por favor realize o login novamente'
      );
      window.open('/login', '_self');
      throw error;
    }
    throw error;
  }
}

export async function callBrasilApi(path, value, isV1 = true) {
  const URI = 'https://brasilapi.com.br/api';
  const version = isV1 ? 'v1' : 'v2';
  let resp = null;
  try {
    await fetch(`${URI}/${path}/${version}/${value}`)
      .then((response) => response.json()) // promise
      .then((json) => {
        resp = json;
      });
  } catch (error) {
    return error;
  }

  return resp;
}

export async function httpServiceUpload(path, endpoint, body) {
  const url = `${process.env.REACT_APP_DELIVERY_WS_URL}/api/${path}/${endpoint}`;
  const sessionToken = getItemInBrowserStorage('TOKEN', false);

  const requestOptions = {
    method: 'POST',
    body: body,
    redirect: 'follow',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST',
      Authorization: `Bearer ${sessionToken}`,
    },
  };

  try {
    await fetch(url, requestOptions);
    return true;
  } catch (err) {
    throw err;
  }
}
