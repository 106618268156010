import react, { useState } from 'react';

export const OrderContext = react.createContext({});

export default function OrderProvider(props) {
  const [alertOnChangeStatus, setAlertOnChangeStatus] = useState({});
  const [printOrder, setPrintOrder] = useState({});
  const [enableAudioAlert, setEnableAudioAlert] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [storeName, setStoreName] = useState(null);
  const [serviceHours, setServiceHours] = useState({
    timeToOpen: null,
    timeToClose: null,
    currentDay: '',
    openToday: false,
    openedNow: false,
  });

  return (
    <OrderContext.Provider
      value={{
        alertOnChangeStatus,
        setAlertOnChangeStatus,
        printOrder,
        setPrintOrder,
        enableAudioAlert,
        setEnableAudioAlert,
        totalOrders,
        setTotalOrders,
        storeName,
        setStoreName,
        serviceHours,
        setServiceHours,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
}
