import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getAllCoupons, updateCoupon } from '../../../services/Configuration';
import {
  copyText,
  formatDateTime,
  maskCurrencyBRLWithoutSymbol,
  maskCurrencyBRLWithoutSymbolString,
} from '../../../Utils/Index';
import { Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import SpringModal from '../../../shared/Modal/Modal';
import moment from 'moment';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function CupomDesconto({
  firstAccess = false,
  handleConfigureStore,
}) {
  const [cupons, setCupons] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [descontoTipo, setDescontoTipo] = useState('valor');
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    codigo: 0,
    cupom: '',
    desconto_percentual: 0,
    desconto_valor: 0,
    cod_categoria: 0,
    cod_produto: 0,
    ativo: 1,
    dh_inicio: null,
    dh_final: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDescontoChange = (e) => {
    let value = e.target.value;
    value = maskCurrencyBRLWithoutSymbolString(value);

    if (descontoTipo === 'percentual') {
      setFormData({
        ...formData,
        desconto_percentual: value,
        desconto_valor: 0,
      });
    } else {
      setFormData({
        ...formData,
        desconto_valor: value,
        desconto_percentual: 0,
      });
    }
  };

  const handleTipoChange = (e) => {
    setDescontoTipo(e.target.value);
    setFormData({
      ...formData,
      desconto_percentual: 0,
      desconto_valor: 0,
    });
  };

  const handlerFormatCupom = (e) => {
    let value = e.target.value;
    value = value.toUpperCase();
    value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    value = value.replace(/[^a-zA-Z0-9_ ]/g, '');
    value = value.replace(/\s+/g, '_');

    setFormData({
      ...formData,
      cupom: value,
    });
  };

  const formatDateForInput = (dateString) => {
    return moment.utc(dateString).format('YYYY-MM-DDTHH:mm');
  };

  const openCupom = (cupom = null) => {
    if (cupom) {
      setDescontoTipo(cupom.desconto_valor ? 'valor' : 'percentual');
      setFormData(cupom);
    } else {
      setFormData({
        codigo: 0,
        cupom: '',
        desconto_percentual: 0,
        desconto_valor: 0,
        cod_categoria: 0,
        cod_produto: 0,
        ativo: 1,
        dh_inicio: null,
        dh_final: null,
      });
    }
    setOpenModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let response = await updateCoupon({
        ...formData,
        desconto_percentual: formData.desconto_percentual
          ? parseFloat(String(formData.desconto_percentual).replace(',', '.'))
          : formData.desconto_percentual,
        desconto_valor: formData.desconto_valor
          ? parseFloat(String(formData.desconto_valor).replace(',', '.'))
          : formData.desconto_valor,
      });

      if (response.success) {
        if (formData.codigo) {
          let _cupons = cupons;
          const cupomIdx = cupons.findIndex(
            (item) => item.codigo === formData.codigo
          );

          _cupons[cupomIdx] = {
            ..._cupons[cupomIdx],
            ...formData,
            ativo: parseInt(formData.ativo),
          };

          setCupons(_cupons);
        } else {
          getCoupons();
        }

        setOpenModal(false);
        toast.success('Cupom salvo com sucesso!');
      } else {
        toast.error('Ocorreu um erro ao salvar o cupom.');
      }
    } catch (error) {
      console.error('Erro:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCoupons();
  }, []);

  const getCoupons = async () => {
    let resp = await getAllCoupons();
    if (resp.success && resp.results.length) {
      setCupons(resp.results);
    }
  };

  const copyCoupon = (cupom) => {
    const response = copyText(cupom);
    if (!response) {
      toast.error('Ocorreu um erro ao copiar o cupom.');
      return;
    }
    toast.success('Cupom copiado com sucesso!');
  };

  return (
    <div className="conf-store">
      <div className="vstack pb-3">
        <h3 className="fs-3 fw-bold m-0 p-0 text-primary">
          Cupons de desconto
        </h3>
        <span className="fs-7 text-body-secondary">
          Configure seus cupons de descontos para distribuir aos clientes.
        </span>
      </div>

      <button
        className="btn btn-primary mb-2 max-w-fit"
        onClick={() => openCupom()}
      >
        Criar cupom
      </button>

      <SpringModal handleOpen={openModal} handleClose={setOpenModal}>
        <div className="p-3">
          <div className="w-100 hstack justify-content-between px-2">
            <h2 className="fw-bold fs-2 text-center">
              {formData.codigo ? 'Editar' : 'Novo'} Cupom
            </h2>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => setOpenModal(false)}
            ></button>
          </div>
          <form onSubmit={handleSubmit} className="container">
            <div className="mb-3">
              <label htmlFor="cupom" className="form-label">
                Cupom
              </label>
              <input
                type="text"
                className="form-control"
                id="cupom"
                name="cupom"
                placeholder="Insira um código para cupom"
                value={formData.cupom}
                maxLength={20}
                onChange={handlerFormatCupom}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="desconto" className="form-label">
                Desconto
              </label>
              <div className="input-group">
                <select
                  className="form-select"
                  onChange={handleTipoChange}
                  value={descontoTipo}
                >
                  <option value="valor">(R$) Valor Fixo</option>
                  <option value="percentual">(%) Percentual</option>
                </select>
                <input
                  type="text"
                  className="form-control"
                  maxLength={6}
                  id="desconto"
                  name={
                    descontoTipo === 'percentual'
                      ? 'desconto_percentual'
                      : 'desconto_valor'
                  }
                  value={
                    descontoTipo === 'percentual'
                      ? formData.desconto_percentual
                      : formData.desconto_valor
                  }
                  onChange={handleDescontoChange}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="ativo" className="form-label">
                Status
              </label>
              <select
                className="form-select"
                id="ativo"
                name="ativo"
                value={formData.ativo}
                onChange={handleChange}
                required
              >
                <option value={1}>Ativo</option>
                <option value={0}>Desativado</option>
              </select>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="dh_inicio" className="form-label">
                  Data de Início
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  id="dh_inicio"
                  name="dh_inicio"
                  value={formatDateForInput(formData.dh_inicio)}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="dh_final" className="form-label">
                  Data de Fim
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  id="dh_final"
                  name="dh_final"
                  value={formatDateForInput(formData.dh_final)}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={isLoading}
            >
              {!isLoading && (formData.codigo ? 'Editar' : 'Criar')}
              {isLoading && '...'}
            </button>
          </form>
        </div>
      </SpringModal>

      <TableContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontWeight: 'bold' }}>
                Cupom
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold' }} align="right">
                Valor
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold' }}>
                Tipo
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold' }} align="center">
                Status
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold' }} align="right">
                Criado em
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold' }} align="right">
                Validade
              </StyledTableCell>
              <StyledTableCell
                style={{ fontWeight: 'bold' }}
                align="center"
              ></StyledTableCell>
            </TableRow>
          </TableHead>
          {cupons.map((cupom, index) => {
            return (
              <TableBody
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? '#0000000a' : '#FFFFFF',
                }}
              >
                <TableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                    className="hstack gap-1"
                  >
                    <Tooltip title="Copiar cupom">
                      <button
                        className="btn btn-primary-fill p-0 m-0"
                        onClick={(_) => copyCoupon(cupom.cupom)}
                      >
                        <span className="material-symbols-outlined">
                          content_copy
                        </span>
                      </button>
                    </Tooltip>{' '}
                    {cupom.cupom}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="right">
                    {cupom.desconto_valor
                      ? 'R$' +
                        maskCurrencyBRLWithoutSymbol(cupom.desconto_valor)
                      : maskCurrencyBRLWithoutSymbol(
                          cupom.desconto_percentual
                        ) + '%'}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {cupom.desconto_percentual
                      ? '(%) Percentual'
                      : '(R$) Valor fixo'}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {cupom.ativo ? 'Ativo' : 'Desativado'}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="right">
                    {formatDateTime(cupom.dh_criacao)}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="right">
                    {formatDateTime(cupom.dh_inicio) +
                      ' às ' +
                      formatDateTime(cupom.dh_final)}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    <Tooltip title="Editar cupom">
                      <button
                        className="btn btn-primary-fill p-0 m-0"
                        onClick={() => openCupom(cupom)}
                      >
                        <span className="material-symbols-outlined">edit</span>
                      </button>
                    </Tooltip>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
    </div>
  );
}
