import { Skeleton } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { IconWallet } from '../../assets/Icons';
import './SimpleDataBox.scss';

function SimpleDataBox({
  data,
  title,
  displayCustomLabel,
  label,
  clickOnCustomLabel,
}) {
  const [dataToShow, setDataToShow] = useState('');
  const [titleForData, setTitleForData] = useState('');
  const [iconColor, setIconColor] = useState('#000000');

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (data === null || !title) {
      setLoaded(false);
      return;
    }
    setDataToShow(data);
    setTitleForData(title);
    setLoaded(true);
    return () => {
      setDataToShow('');
      setTitleForData('');
      setLoaded(false);
    };
  }, [data, title]);

  return loaded ? (
    <div className="representative">
      <div className="dl-card">
        {displayCustomLabel && (
          <div
            className="dl-card__custom-button"
            onMouseEnter={() => setIconColor('#F10542')}
            onMouseLeave={() => setIconColor('#000000')}
          >
            <button
              type="button"
              className="dl-card__custom-button__button"
              onClick={clickOnCustomLabel}
              style={{ color: iconColor }}
            >
              Sacar dinheiro
            </button>
            <IconWallet color={iconColor} size={18} />
          </div>
        )}
        <h3 className="dl-card-data">{dataToShow}</h3>
        <h5 className="dl-card-title">{titleForData}</h5>
      </div>
    </div>
  ) : (
    <div className="dl-card-skeleton">
      <Skeleton
        className="dl-card"
        animation="wave"
        variant="rect"
        width={300}
        height={150}
        style={{ border: 'none' }}
      />
    </div>
  );
}

export default SimpleDataBox;
