import React, { useState, useEffect, useRef, useContext } from 'react';
import './Pedidos.scss';
import {
  aliveStoreHelth,
  getAllOrders,
  handlerStoreStatus,
} from '../../services/OrderManager';
import { toast } from 'react-toastify';
import { maskCurrencyBRL, useWindowDimensions } from '../../Utils/Index';
import OrderInformation from './OrderInformation/OrderInformation';
import OrderCard from './OrderCard/OrderCard';
import SetupMenu from './SetupMenu/SetupMenu';
import FilterStatusOrder from './FilterStatusOrder/FilterStatusOrder';
import audioMP3 from '../../assets/bell.mp3';
import SpringModal from '../../shared/Modal/Modal';
import { OrderContext } from './OrderProvider/OrderProvider';
import useInterval from '../../shared/UseInterval/UseInterval';
import EventEmitter from '../../services/Event';

export default function Pedidos() {
  const [infsOrders, setInfsOrders] = useState([]);
  const [infsOrdersOriginal, setInfsOrdersOriginal] = useState([]);
  const [noOrders, setNoOrders] = useState(true);
  const [searchOrder, setSearchOrder] = useState('');
  const [orderDetails, setOrderDetails] = useState(null);
  const [modalOrders, setModalOrders] = useState(false);
  const [mobileLayout, setMobileLayout] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [codeStatusFilter, setCodeStatusFilter] = useState(undefined);
  const [forceFilterOrder, setForceFilterOrder] = useState(null);
  const [callOrders, setCallOrders] = useState(false);
  const [saveOrderSelected, setSaveOrderSelected] = useState({ codigo: -1 });

  const { enableAudioAlert, setTotalOrders, totalOrders, serviceHours } =
    useContext(OrderContext);
  const { width } = useWindowDimensions();
  const orderRef = useRef([]);
  const audio = new Audio(audioMP3);

  useEffect(() => {
    setMobileLayout(width <= 1170);
  }, [width]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    EventEmitter.on('update-hours-opening', (settingOpening) => {
      if (settingOpening.openedNow) callGetAllOrders();
      setCallOrders(settingOpening.openedNow);
    });
    return () => {
      EventEmitter.off('update-hours-opening');
      setCallOrders(false);
      if (serviceHours.openedNow) handlerStoreStatus(false);
    };
  }, []);

  useInterval(
    () => {
      callGetAllOrders();
    },
    callOrders ? 10000 : null
  );

  useInterval(
    () => {
      aliveStoreHelth();
      if (firstRender) setFirstRender(false);
    },
    firstRender ? 10 : 120000
  ); // 2 min

  async function callGetAllOrders() {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const { dtStart, dtEnd } = getDateRange();
    const response = await getAllOrders(dtStart, dtEnd);
    if (response.success) {
      if (response.result.length) {
        setSettingsOrder(response.result);
        if (enableAudioAlert && response.result.length > totalOrders)
          audio.play();
        setTotalOrders(response.result.length);
      }
      return;
    }

    toast.error(
      'Houve um problema ao buscar os pedidos, por favor entre em contato com nosso suporte.'
    );
  }

  function getDateRange() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return {
      dtStart: `${yesterday.toISOString().split('T')[0]} 00:00:00`,
      dtEnd: `${today.toISOString().split('T')[0]} 23:59:59`,
    };
  }

  function setSettingsOrder(orders) {
    if (orders.length > 0) {
      setNoOrders(false);
      let allOrders = orders.map((order) => {
        if (!order.edited) {
          order.selected =
            order.force_selected || saveOrderSelected.codigo === order.codigo;
          order.force_selected = false;
          order.valor_total = maskCurrencyBRL(
            order.valor_total_com_desconto
              ? order.valor_total_com_desconto
              : order.valor_total
          );
          order.start_time = order.dh_criacao;
          order.dh_criacao =
            order.dh_criacao.length > 6
              ? order.dh_criacao.substring(11, 16)
              : order.dh_criacao;
          order.dh_inicio = order.dh_inicio
            ? order.dh_inicio.length > 6
              ? order.dh_inicio.substring(11, 16)
              : order.dh_inicio
            : '--:--';
          order.dh_concluido = order.dh_concluido
            ? order.dh_concluido.length > 6
              ? order.dh_concluido.substring(11, 16)
              : order.dh_concluido
            : '--:--';
          order.payment_method =
            order.pagamento_grupo !== 'Outros'
              ? `${order.pagamento_grupo}-${order.pagamento_tipo}`
              : order.pagamento_tipo;
          order.color_status = getColorStatus(order.cod_pedido_status);
          order.cupom_ativo = order.cod_cupom || order.cupom ? true : false;
          order.edited = true;
        }
        return order;
      });
      setInfsOrders(allOrders);
      setInfsOrdersOriginal(allOrders);
    }
  }

  function searchOrderFilter(text) {
    setSearchOrder(text);
    let temp = infsOrdersOriginal.filter((item) => {
      if (
        (item.nome && item.nome.toLowerCase().includes(text.toLowerCase())) ||
        (item.codigo && item.codigo.toString().includes(text))
      ) {
        if (codeStatusFilter) {
          if (
            item.cod_pedido_status === codeStatusFilter.code ||
            item.cod_pedido_status === codeStatusFilter.subcode
          ) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    });
    setInfsOrders(temp);
    setNoOrders(temp.length === 0);
  }

  function getColorStatus(idStatus) {
    let color = '';
    switch (idStatus) {
      case 1:
        color = '#949494';
        break;
      case 2:
        color = '#0737E3';
        break;
      case 3:
        color = '#FF6B00';
        break;
      case 4:
        color = '#FF6B00';
        break;
      case 5:
        color = '#27AE60';
        break;
      default:
        color = '#000000';
        break;
    }
    return color;
  }

  function handlerOrderSelected(order) {
    setSaveOrderSelected(order);
    setInfsOrders(
      infsOrders.filter((el) => {
        el.selected = el.codigo === order.codigo;
        return el;
      })
    );
    setOrderDetails(order);
    setModalOrders(true);
  }

  function filterOrders(orders, codeStatus) {
    setCodeStatusFilter(codeStatus);
    setInfsOrders(orders);
  }

  function resetOrderSelected(param) {
    setModalOrders(param);
    setOrderDetails(
      infsOrders.map((el) => {
        el.selected = false;
        return el;
      })
    );
  }

  function updateOrderStatus(updatedOrder) {
    const updatedOrders = infsOrdersOriginal.map((order) => {
      if (order.codigo === updatedOrder.codigo) order = updatedOrder;
      return order;
    });
    setSettingsOrder(updatedOrders);
    setForceFilterOrder(updatedOrder.cod_pedido_status);
    setForceFilterOrder(null);
    setTimeout(() => {
      orderRef.current[updatedOrder.codigo]?.scrollIntoView();
    }, 500);
  }

  return (
    <div className="vh-100 p-0 m-0 vstack position-relative justify-content-start align-items-center">
      <div className="container-sm h-100 pb-3">
        <SetupMenu />

        <FilterStatusOrder
          allOrders={infsOrdersOriginal}
          showOrderWithStatus={filterOrders}
          noOrders={setNoOrders}
          forceFilter={forceFilterOrder}
        />

        <div className="p-2 rounded-1 shadow-sm">
          {callOrders && (
            <div className="bg-light d-flex flex-column flex-md-row gap-1 rounded w-100">
              <div className="gap-1 hstack">
                <span className="fs-7">Dia da semana:</span>
                <span className="fs-7 fw-bold">{serviceHours.currentDay}</span>
              </div>
              <div className="gap-1 hstack">
                <span className="fs-7">Horário de funcionamento:</span>
                <span className="fs-7 fw-bold">
                  {serviceHours.timeToOpen} às {serviceHours.timeToClose} hrs
                </span>
              </div>
            </div>
          )}

          <div className="align-items-start gap-md-3 hstack justify-content-center w-100">
            <div className="w-100 h-100">
              <div className="w-100 vstack h-100">
                <div className="w-100 hstack gap-1 align-items-center justify-content-between py-3">
                  <input
                    type="text"
                    placeholder="Cód ou nome do cliente"
                    className="input-style m-0"
                    value={searchOrder}
                    onChange={(e) => setSearchOrder(e.target.value)}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => searchOrderFilter(searchOrder)}
                  >
                    Pesquisar
                  </button>
                </div>

                <div
                  className="d-flex flex-column overflow-y-auto"
                  style={{ maxHeight: 480 }}
                >
                  {noOrders ? (
                    <span className="w-100 text-center text-black fs-7 d-block">
                      Não há pedidos, tente resetar os filtros de pesquisa ou
                      aguarde até um novo.
                    </span>
                  ) : (
                    infsOrders.map((order) => {
                      return (
                        <div
                          key={order.codigo}
                          ref={(element) =>
                            orderRef.current.splice(order.codigo, 1, element)
                          }
                        >
                          <OrderCard
                            infsOrder={order}
                            orderInformation={handlerOrderSelected}
                          />
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>

            {mobileLayout ? (
              orderDetails &&
              modalOrders && (
                <SpringModal handleOpen={true} handleClose={resetOrderSelected}>
                  <OrderInformation
                    orderInfs={orderDetails}
                    setNewStatusOrder={updateOrderStatus}
                    close={() => {
                      setModalOrders(false);
                      setOrderDetails(null);
                    }}
                  />
                </SpringModal>
              )
            ) : (
              <>
                {orderDetails ? (
                  <div className="order-details-modal">
                    <OrderInformation
                      orderInfs={orderDetails}
                      setNewStatusOrder={updateOrderStatus}
                      close={() => setOrderDetails(null)}
                    />
                  </div>
                ) : (
                  <span className="align-items-start hstack justify-content-center w-100">
                    Selecione um pedido para ver seus detalhes.
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
