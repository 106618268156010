import React, { useEffect, useState } from 'react';
import { PRODUCT_DEFAULT_IMAGE_URL } from '../../../Utils/Index';

function ProductLine({ product, edit }) {
  const [image, setImage] = useState(PRODUCT_DEFAULT_IMAGE_URL);

  useEffect(() => {
    setImage(product.image_url);
  }, [product]);

  return (
    <div className="d-flex align-items-center gap-2 py-3">
      <div className="align-icon" onClick={() => edit()}>
        <span className="material-symbols-outlined">edit</span>
      </div>
      <img
        className="CardProductImage"
        src={image}
        alt="Imagem do produto"
        onError={() => setImage(PRODUCT_DEFAULT_IMAGE_URL)}
      />
      <div className="align-description">
        <span className="text-name-product">{product.nome}</span>
        <span className="description-text">{product.descricao}</span>
      </div>
    </div>
  );
}

export default ProductLine;
