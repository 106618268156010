import React, { useState, useEffect, useContext } from 'react';
import './RepresentativeAccountDetails.scss';
import {
  IconEdit,
  IconKey,
  IconPhone,
  IconMessage,
  IconDocument,
} from '../../assets/Icons';
import {
  getItemInBrowserStorage,
  maskCNPJ,
  maskCPF,
  maskPhoneBR,
  validCpf,
  validEmail,
  validPhoneBRL,
} from '../../Utils/Index';
import { SalesRepresentative } from '../../Authentication/Provider/AuthenticationProvider';
import { toast } from 'react-toastify';
import { getPix, savePix } from '../../services/SalesRepresentativeService';
import { SalesRepresentativeInfs } from '../SalesRepresentativeProvider/SalesRepresentativeProvider';

function RepresentativeAccountDetails() {
  const [typeOfPix, setTypeOfPix] = useState({
    maxLength: 18,
    icon: <IconDocument />,
    selected: true,
    enum: 'CPF/CNPJ',
    placeholder: 'Número do CPF/CNPJ',
    label: 'CPF/CNPJ',
    labelError: 'CPF/CNPJ inválido.',
  });
  const [personalData, setPersonalData] = useState({});

  const [loadedData, setLoadedData] = useState(false);
  const [loadedPixInfs, setLoadedPixInfs] = useState(false);
  const [editingPix, setEditingPix] = useState(false);

  const [representativePix, setRepresentativePix] = useState(null);
  const [pixValue, setPixValue] = useState('');

  const [methodPix, setMethodPix] = useState([
    {
      maxLength: 18,
      icon: <IconDocument />,
      selected: true,
      enum: 'CPF/CNPJ',
      placeholder: 'Número do CPF/CNPJ',
      label: 'CPF/CNPJ',
      labelError: 'CPF/CNPJ inválido.',
    },
    {
      maxLength: 13,
      icon: <IconPhone />,
      selected: false,
      enum: 'PHONE',
      placeholder: 'Número do celular',
      label: 'Celular',
      labelError: 'Número inválido.',
    },
    {
      maxLength: 50,
      icon: <IconMessage />,
      selected: false,
      enum: 'EMAIL',
      placeholder: 'Informe o e-mail',
      label: 'E-mail',
      labelError: 'E-mail inválido.',
    },
    {
      maxLength: 150,
      icon: <IconKey />,
      selected: false,
      enum: 'ALEATORY_KEY',
      placeholder: 'Chave aleatória',
      label: 'Chave aleatória',
      labelError: 'Chave aleatória inválida.',
    },
  ]);

  const { representativeData, setRepresentativeData } =
    useContext(SalesRepresentative);
  const {
    personalDataContext,
    setPersonalDataContext,
    representativePixContext,
    setRepresentativePixContext,
  } = useContext(SalesRepresentativeInfs);

  useEffect(() => {
    if (personalDataContext) {
      setPersonalData(personalDataContext);
      setLoadedData(true);
      return;
    }

    if (!representativeData) {
      getRepresentativeDataInStorage();
      return;
    }

    const { representante, cod_representante } = representativeData;
    setPersonalData({ ...representante, cod_representante });
    setLoadedData(true);
  }, []);

  useEffect(() => {
    if (representativePixContext) {
      setRepresentativePix(representativePixContext);
      setLoadedPixInfs(true);
      callGetPix();
      return;
    }
    callGetPix();
  }, []);

  function getRepresentativeDataInStorage() {
    const personalData = getItemInBrowserStorage('CLIENT_DATA', false);
    if (!personalData) {
      return;
    }
    setRepresentativeData(personalData);
    const { representante, cod_representante } = personalData;
    setPersonalDataContext({ ...representante, cod_representante });
    setPersonalData({ ...representante, cod_representante });
    setLoadedData(true);
  }

  async function callGetPix() {
    if (representativePix) return;
    const response = await getPix();

    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao buscar o PIX cadastrado para pagamento, tente novamente ou entre em contato conosco.'
      );
      return;
    }
    setRepresentativePix(response.results);
    setRepresentativePixContext(response.results);
    setLoadedPixInfs(true);
  }

  function changeTypeOfPix(newTypeOf) {
    setTypeOfPix(newTypeOf);
    setPixValue('');
    const updatedMethod = methodPix.map((method) => {
      if (method.enum === newTypeOf.enum) {
        method.selected = true;
        return method;
      }
      method.selected = false;
      return method;
    });
    setMethodPix(updatedMethod);
  }

  function getNewPixValue(text) {
    let pixValue;
    if (text.length > typeOfPix.maxLength) return;
    switch (typeOfPix.enum) {
      case 'CPF/CNPJ':
        if (text.length <= 14) pixValue = maskCPF(text);
        else pixValue = maskCNPJ(text);
        break;
      case 'PHONE':
        pixValue = maskPhoneBR(text);
        break;
      default:
        pixValue = text;
        break;
    }
    setPixValue(pixValue);
  }

  function isValidValueForPix(newValue) {
    let isValidValue = true;
    switch (typeOfPix.enum) {
      case 'CPF/CNPJ':
        isValidValue = validCpf(newValue);
        break;
      case 'PHONE':
        isValidValue = validPhoneBRL(newValue);
        break;
      case 'EMAIL':
        isValidValue = validEmail(newValue);
        break;
      default:
        break;
    }

    if (!isValidValue) {
      toast.error(
        'Valor informado inválido, por favor preencha os dados corretamente.'
      );
      return;
    }

    return isValidValue;
  }

  async function saveNewPix(newValue) {
    if (!isValidValueForPix(newValue)) return;
    let pixValue = newValue;
    if (typeOfPix.enum === 'CPF/CNPJ' || typeOfPix.enum === 'PHONE')
      pixValue = pixValue.replace(/\D/g, '');
    const response = await savePix(pixValue, typeOfPix.enum);
    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao atualizar o seu PIX, por favor tente novamente ou entre em contato conosco.'
      );
      return;
    }
    toast.success('PIX salvo com sucesso.');
  }

  return (
    <div className="representative-account">
      <h1 className="sales-representative-section-header">Dados pessoais</h1>
      <div className="representative-account__card">
        {loadedData ? (
          <>
            <div className="representative-account__data">
              <span className="representative-account__data-type">Nome</span>
              <span className="representative-account__data-value">
                {personalData.nome}
              </span>
            </div>
            <div className="representative-account__data">
              <span className="representative-account__data-type">CPF</span>
              <span className="representative-account__data-value">
                {personalData.cpf}
              </span>
            </div>
            <div className="representative-account__data">
              <span className="representative-account__data-type">E-mail</span>
              <span className="representative-account__data-value">
                {personalData.email}
              </span>
            </div>
            <div className="representative-account__data">
              <span className="representative-account__data-type">
                Cupom/Código de afiliado
              </span>
              <span className="representative-account__data-value">
                {personalData.cupom}
              </span>
            </div>
          </>
        ) : (
          <span>Teste</span>
        )}

        <div className="representative-account__data">
          <span className="representative-account__data-type">
            PIX{' '}
            {loadedPixInfs && !editingPix && (
              <small>({representativePix.tipo})</small>
            )}
          </span>
          {loadedPixInfs && (
            <>
              {!editingPix ? (
                <div className="representative-account__data-align-row">
                  <span className="representative-account__data-value">
                    {representativePix.chave}
                  </span>
                  <div
                    className="representative-account__data-icon"
                    onClick={() => setEditingPix(!editingPix)}
                  >
                    <IconEdit size={16} color={'#000000'} />
                  </div>
                </div>
              ) : (
                <div
                  className="representative-account__configure-pix"
                  style={{ width: '100%' }}
                >
                  <div className="representative-account__configure-pix">
                    <h4>Selecione uma chave</h4>
                    <div className="representative-account__configure-pix__pix-identification">
                      {methodPix.map((method, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => changeTypeOfPix(method)}
                            className={`representative-account__configure-pix__pix-identification__card ${
                              method.selected ? 'selected-card' : ''
                            }`}
                          >
                            <span>{method.label}</span>
                            <div>{method.icon}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="representative-account__configure-pix__pix-value">
                    <input
                      type="text"
                      placeholder={typeOfPix.placeholder}
                      className="sign-in__input"
                      onChange={(e) => getNewPixValue(e.target.value)}
                      value={pixValue}
                    />
                  </div>
                  <div className="sign-in__container-sign-btn align-btns-register">
                    <button
                      type="button"
                      style={{ width: '35%' }}
                      className="sign-in__container-sign-btn-back"
                      onClick={() => setEditingPix(!editingPix)}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className={`sign-in__container-sign-btn-confirm ${
                        !Boolean(pixValue) ? 'disable-click' : ''
                      }`}
                      onClick={() => saveNewPix(pixValue)}
                      disabled={false}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default RepresentativeAccountDetails;
