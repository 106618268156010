import React from 'react';

function ClientAuthMethods({ sign, createAccount, close }) {
  return (
    <div className="vstack p-2">
      <div className="hstack w-100 align-items-center justify-content-between mb-4">
        <h1 className="text-center fw-bold fs-5 d-block m-0">
          Já possui cadastro ?
        </h1>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => close()}
        ></button>
      </div>
      <button onClick={() => sign()} className="btn btn-primary w-100">
        Entrar
      </button>
      <span className="fs-8 text-center py-1">ou</span>
      <button
        onClick={() => createAccount()}
        className="btn btn-outline-primary w-100"
      >
        Criar conta
      </button>
    </div>
  );
}

export default ClientAuthMethods;
