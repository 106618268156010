import React from 'react';
import './Plans.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function Plans() {
  const history = useHistory();

  const features = [
    { name: 'Gestor de pedidos', released: true },
    { name: 'Integração com iFood', released: false },
    { name: 'Sistema de troco', released: true },
    { name: 'Raio de entrega', released: true },
    { name: 'Horário de loja personalizado', released: true },
    { name: 'Análise de acessos', released: true },
    { name: 'Cupom de desconto', released: true },
    { name: 'Cardápio QRCode', released: true },
    { name: 'Customização da loja', released: true },
    { name: 'Pagamento online', released: false },
    { name: 'Domínio próprio', released: true },
    { name: 'Monitor para cozinha', released: false },
  ];

  function handleToSection() {
    history.push('/criar-conta');
  }

  return (
    <div className="align-items-center justify-content-center p-2 p-xl-5 vstack w-100">
      <div className="lp-plans-v2-container">
        <div className="vstack mb-4">
          <h1 className="fw-bold">Conheça nossos planos</h1>
          <h2 className="fs-5 m-0">
            Venda sem se preocupar com limites, taxas e comissões, aqui a taxa é{' '}
            <strong className="bg-primary rounded-1 p-1 text-white fw-bold text-truncate">
              R$ 0,00
            </strong>
          </h2>
        </div>

        <div className="align-items-start d-flex flex-column flex-md-row gap-5 gap-md-4 gap-xl-5 justify-content-center w-100">
          <div className="card shadow-sm w-100">
            <h5 className="bg-primary card-title fs-4 fw-bold m-0 p-2 rounded-top-2 text-center text-white">
              Funcionalidades
            </h5>
            {features.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`hstack align-items-center gap-2 card-text py-2 px-3 ${
                    index % 2 ? 'bg-light' : ''
                  }`}
                >
                  <span className="material-symbols-outlined text-success fs-5">
                    task_alt
                  </span>
                  <span>{item.name}</span>
                </div>
              );
            })}
          </div>

          <div className="d-flex flex-column-reverse flex-md-column justify-between w-100">
            <div className="align-items-start d-flex flex-column-reverse flex-xl-row gap-3">
              <div className="card shadow text-center w-100">
                <h3 className="bg-black bg-opacity-75 card-title fs-4 fw-bold m-0 p-3 rounded-top-2 text-center text-white">
                  Mensal
                </h3>
                <div className="p-4 gap-4">
                  <div className="align-items-center gap-1 hstack justify-content-center pb-4">
                    <h2 className="fw-bold text-primary">R$ 50,00</h2>
                    <span className="fs-8 text-black-50">/mês</span>
                  </div>

                  <span className="py-4 text-center border-top">
                    no pagamento mensal
                  </span>
                </div>
              </div>

              <div className="card shadow text-center w-100">
                <div className="position-relative">
                  <h3 className="bg-black bg-opacity-75 card-title fs-4 fw-bold m-0 p-3 rounded-top-2 text-center text-white">
                    Anual
                  </h3>
                  <div
                    className="hstack justify-content-center position-absolute w-100"
                    style={{ top: '-12px' }}
                  >
                    <h6 className="bg-primary fs-7 fw-bold p-1  px-3 rounded-5 text-white">
                      Recomendado
                    </h6>
                  </div>
                </div>
                <div className="p-4 gap-4">
                  <div className="align-items-center gap-1 hstack justify-content-center pb-4">
                    <h2 className="fw-bold text-primary">R$ 35,00</h2>
                    <span className="fs-8 text-black-50">/mês</span>
                  </div>

                  <span className="py-4 text-center border-top">
                    no pagamento anual
                  </span>
                </div>
              </div>
            </div>

            <div className="justify-content-center gap-5 vstack py-md-5 pb-5">
              <h3 className="text-center fw-bold fs-1 m-0">
                Faça um teste grátis <br />
                por{' '}
                <strong className="bg-primary rounded-1 p-1 text-white fw-bold">
                  60 dias
                </strong>
              </h3>

              <button
                className="btn btn-primary p-3 fw-bold shadow"
                onClick={() => handleToSection()}
              >
                Experimente grátis
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
