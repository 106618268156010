import React, { useState, useEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Skeleton } from '@material-ui/lab';
import { formatDateTime, maskCurrencyBRL } from '../../../Utils/Index';

import { paymentsHistory } from '../../../services/StoreData';
import { toast } from 'react-toastify';

function FinancialHistory() {
  const [loaded, setLoaded] = useState(false);

  const [financialhistory, setFinancialhistory] = useState([]);

  const fakeData = [0, 0, 0, 0, 0, 0, 0, 0];

  useEffect(() => {
    callGetPaymentsHistory();
    return () => {
      setLoaded(false);
      setFinancialhistory([]);
    };
  }, []);

  async function callGetPaymentsHistory() {
    const response = await paymentsHistory();
    if (!response || !response.success) {
      setLoaded(false);
      toast.error(
        'Ocorreu um erro ao buscar o seu histórico de transação, por favor tente novamente ou entre em contato conosco.',
        { autoClose: 8000 }
      );
      return;
    }
    formatData(response.results);
  }

  function formatData(paymentsHistory) {
    if (!paymentsHistory.length) {
      setLoaded(true);
      return;
    }

    const formatedData = paymentsHistory.map((history) => {
      history.valor = maskCurrencyBRL(history.valor);
      history.datahora_cobranca = formatDateTime(history.datahora_cobranca);
      history.datahora_pagto = formatDateTime(history.datahora_pagto);
      return history;
    });
    setFinancialhistory(formatedData);
    setLoaded(true);
  }

  return (
    <div>
      <h1 className="sales-representative-section-header">
        Histórico financeiro
      </h1>
      <div>
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell className="labelColumn" align="center">
                  Método pagamento
                </TableCell>
                <TableCell className="labelColumn" align="center">
                  Status pagamento
                </TableCell>
                <TableCell className="labelColumn" align="center">
                  Valor
                </TableCell>
                <TableCell className="labelColumn" align="center">
                  Plano
                </TableCell>
                <TableCell className="labelColumn" align="center">
                  Vigência do plano
                </TableCell>
                <TableCell className="labelColumn" align="center">
                  Data/hora cobrança
                </TableCell>
                <TableCell className="labelColumn" align="center">
                  Data/hora pagamento
                </TableCell>
                <TableCell className="labelColumn" align="center">
                  Código pagamento
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loaded && financialhistory.length ? (
                <>
                  {financialhistory.map((proposal, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left" style={{ maxWidth: 250 }}>
                          {proposal.pagamento_tipo}
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: 150 }}>
                          {proposal.pagamento_status}
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: 150 }}>
                          {proposal.valor}
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: 150 }}>
                          {proposal.plano.nome}
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: 150 }}>
                          {proposal.plano.qtd_dias}
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: 150 }}>
                          {proposal.datahora_cobranca}
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: 250 }}>
                          {proposal.datahora_pagto}
                        </TableCell>
                        <TableCell align="center" style={{ maxWidth: 150 }}>
                          {proposal.transacao_referencia}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : loaded ? (
                <span>Não há histórico financeiro</span>
              ) : (
                <>
                  {fakeData.map((_, index) => {
                    return (
                      <TableRow key={index}>
                        {fakeData.map((_, index2) => {
                          return (
                            <TableCell
                              key={index2}
                              align="left"
                              style={{ maxWidth: 250 }}
                            >
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={18}
                                width={'100%'}
                                style={{
                                  border: 'none',
                                  borderRadius: 8,
                                  maxWidth: 450,
                                }}
                              />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default FinancialHistory;
