import React, { useState, useEffect, useContext } from 'react';
import './OrderNote.scss';
import { formatDateTime } from '../../../Utils/Index';
import { OrderContext } from '../OrderProvider/OrderProvider';

export default function OrderNote({ orderInfs = null, orderPrices }) {
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderCreationTime, setOrderCreationTime] = useState(null);
  const [orderFinancier, setOrderFinancier] = useState([{}]);
  const { storeName } = useContext(OrderContext);

  useEffect(() => {
    if (orderInfs) {
      setOrderDetails(orderInfs);
      setOrderCreationTime(
        orderInfs.dh_criacao.length > 5
          ? formatDateTime(orderInfs.dh_criacao).split('-')[1]
          : orderInfs.dh_criacao
      );
      financeSettingOrder(orderInfs, orderPrices);
    }
    return () => {
      setOrderDetails(null);
    };
  }, [orderInfs]); //eslint-disable-line react-hooks/exhaustive-deps

  function financeSettingOrder(data, prices) {
    let payment_method;
    if (data.pagamento_grupo.toLowerCase().includes('outros'))
      payment_method = data.payment_method;
    else payment_method = `${data.payment_method} - ${data.pagamento_tipo}`;
    setOrderFinancier([
      { label: 'Pagamento', value: payment_method },
      { label: 'Desconto', value: prices.coupon },
      { label: 'Taxa de entrega', value: prices.delivery },
      { label: 'Troco', value: prices.trade },
      { label: 'Total', value: prices.total },
    ]);
  }

  return (
    <>
      {orderDetails && (
        <div className="content-order-note complet-order">
          <h2 className="store-name">{storeName}</h2>
          <div className="content-details">
            <ul
              className="infs-order"
              style={{ width: Boolean(orderDetails.ifood) ? '60%' : '43%' }}
            >
              <li>Pedido&nbsp;#{orderDetails.codigo}</li>
              {Boolean(orderDetails.ifood) && <li>Ifood</li>}
              <li>{Boolean(orderDetails.entrega) ? 'Entrega' : 'Delivery'}</li>
            </ul>
            <div className="time">
              <span>Horário:&nbsp;{orderCreationTime}</span>
            </div>
          </div>
          <span className="label-itens">Itens:</span>
          <div className="order-delivery-details remove-background">
            {orderDetails.items.map((order, index) => {
              return (
                <div key={index} className="align-infs">
                  <div className="item-order">
                    <div className="order-details">
                      <div className="order-items">
                        <span className="txt-inf item">
                          {order.qtd}x {order.nome}
                        </span>
                        <span className="pointer break-line">
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . .
                        </span>
                        <span className="txt-price item">
                          {order.preco}{' '}
                          <span className="txt-inf item">
                            ({order.subtotal})
                          </span>
                        </span>
                      </div>
                      {order.complementos.map((complement, index) => {
                        return (
                          <div key={index} className="order-complements">
                            <span className="txt-inf item">
                              {complement.grupo} - {complement.nome}
                            </span>
                            <span className="pointer break-line">
                              . . . . . . . . . . . . . . . . . . . . . . . . .
                              . . . . . . . . . . . . . . . . . . . . . . . . .
                              . . . . . . . . . . . . . . . . . . . . . . . . .
                              . . . . . . . . . . . .
                            </span>
                            <span className="txt-price item">
                              {complement.preco}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                    <div className={order.obs ? 'note-order' : 'hidden'}>
                      <span className="title-section">Obs: </span>
                      <span className="txt-inf note">{order.obs}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="order-delivery-details client-data remove-background">
            <span className="title-section bold">Dados do cliente:</span>
            {
              <div className="align-infs">
                <span className="txt-inf">{orderDetails.nome}</span>
                <span className="txt-inf">
                  {orderDetails.endereco_rua}, {orderDetails.endereco_nro} -
                  CEP: {orderDetails.cep_entrega}
                </span>
                <span className="txt-inf">
                  {orderDetails.endereco_complemento}
                </span>
                <span className="txt-inf">{orderDetails.endereco_bairro}</span>
                <span className="txt-inf">
                  {orderDetails.endereco_cidade} / {orderDetails.endereco_uf}
                </span>
              </div>
            }
          </div>
          {orderFinancier.map((item, index) => {
            return (
              item.value && (
                <div
                  key={index}
                  className={
                    item.label === 'Pagamento'
                      ? 'order-delivery-details client-data remove-background flex-column remove-margin'
                      : 'order-delivery-details client-data remove-background remove-margin'
                  }
                >
                  <span className="title-section bold">{item.label}:</span>
                  <span
                    className={
                      item.label !== 'Pagamento'
                        ? 'title-section mr'
                        : 'title-section'
                    }
                  >
                    {item.value}
                  </span>
                </div>
              )
            );
          })}
        </div>
      )}
    </>
  );
}
