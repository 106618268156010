import React, { useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import InputV2 from '../../../../../shared/InputV2/InputV2';
import { validateCardNumber } from '../../../../../Utils/Index';

const CreditCardForm = ({ onAddCreditCard }) => {
  const formRef = useRef(null);

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        cc_name: Yup.string()
          .required('O nome completo do titular do cartão é obrigatório')
          .min(3, 'O nome completo deve ter pelo menos 3 caracteres')
          .max(50, 'O nome completo deve ter no máximo 26 caracteres'),
        cc_number: Yup.string()
          .required('O número do cartão de crédito é obrigatório')
          .matches(
            /^[0-9 ]{19}$/,
            'O número do cartão de crédito deve ter exatamente 16 dígitos'
          )
          .test('valid-card', 'Número de cartão de crédito inválido', (value) =>
            validateCardNumber(value)
          ),
        cc_expiration: Yup.string()
          .required('A data de validade é obrigatória')
          .matches(
            /^(0[1-9]|1[0-2])\/[0-9]{4}$/,
            'Formato de data inválido, use MM/AAAA'
          )
          .test('valid-date', 'Data de validade inválida', function (value) {
            if (!value) return false;
            const [month, year] = value.split('/');
            const expDate = new Date(`20${year}`, month - 1);
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            return expDate >= currentDate;
          }),
        cc_cvv: Yup.string()
          .required('O CVV é obrigatório')
          .matches(/^[0-9]{3,4}$/, 'O CVV deve ter 3 ou 4 dígitos'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      data.cc_number = data.cc_number.replace(/\D/g, '');
      onAddCreditCard(data);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
    }
  };

  return (
    <div>
      <h2 className="text-center fw-bold text-primary mb-1 fs-4">
        Preencha os dados do cartão:
      </h2>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className="d-flex flex-column"
      >
        <InputV2
          label=""
          name="cc_name"
          type="text"
          maxLength={45}
          placeholder="Nome idêntico ao do cartão"
        />
        <InputV2
          name="cc_number"
          type="text"
          placeholder="0000 0000 0000 0000"
          label=""
          maxLength={19}
          mask={'CC_NUMBER'}
        />
        <div className="hstack w-100 gap-2 justify-content-between">
          <InputV2
            name="cc_expiration"
            type="text"
            placeholder="MM/YYYY"
            label=""
            maxLength={7}
            mask={'CC_EXPIRATION'}
          />
          <InputV2
            name="cc_cvv"
            type="text"
            placeholder="123"
            label=""
            maxLength={4}
            mask={'NUMBER'}
          />
        </div>

        <button type="submit" className="btn btn-primary w-100 my-3">
          Realizar Pagamento
        </button>
      </Form>
    </div>
  );
};

export default CreditCardForm;
