import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default function ProductsSkeleton() {
  const numbers = [1, 2, 3, 4, 5];
  return (
    <div className="CardCategoria">
      <TableContainer>
        <Table size="small">
          <TableHead>
            <div
              style={{
                width: '207%',
                borderBottom: '2px solid #E0E0E0',
                marginBottom: 16,
              }}
            >
              <Skeleton
                animation="wave"
                variant="rect"
                width={120}
                height={30}
                style={{ borderRadius: 4, marginBottom: 4 }}
              />
            </div>
            <TableRow>
              <TableCell className="labelColumn" align="left">
                Items
              </TableCell>
              <TableCell className="labelColumn" align="center">
                Preço
              </TableCell>
              <TableCell className="labelColumn" align="center">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {numbers.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center" style={{ width: 200 }}>
                    <div className="align-items-product">
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={25}
                        height={25}
                        style={{ borderRadius: 8 }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={88}
                        height={72}
                        style={{ borderRadius: 8, margin: '0px 8px' }}
                      />
                      <div className="align-description">
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width={80}
                          height={20}
                          style={{ borderRadius: 4, marginBottom: 8 }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width={120}
                          height={40}
                          style={{ borderRadius: 4 }}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ width: 110, position: 'relative' }}
                  >
                    <div style={{ position: 'absolute', top: 25, left: '35%' }}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={102}
                        height={37}
                        style={{ borderRadius: 8 }}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ width: 130, position: 'relative' }}
                  >
                    <div style={{ position: 'absolute', top: 25, left: '40%' }}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={110}
                        height={25}
                        style={{ borderRadius: 8 }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <Skeleton
          animation="wave"
          variant="rect"
          width={185}
          height={42}
          style={{ borderRadius: 8, margin: '8px 0px 0px 0px' }}
        />
      </div>
    </div>
  );
}
