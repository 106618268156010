import React, { useContext, useEffect, useRef, useState } from 'react';
import { IconPaymentMethod } from '../../../Utils/IconPaymentMethod';
import './OrderInformation.scss';
import {
  acceptOrder,
  concludedOrder,
  declineOrder,
  setStatusOrder,
} from '../../../services/OrderManager';
import { toast } from 'react-toastify';
import SpringModal from '../../../shared/Modal/Modal';
import {
  formatDateTime,
  maskCurrencyBRL,
  updateStatusOrder,
} from '../../../Utils/Index';
import { OrderContext } from '../OrderProvider/OrderProvider';
import { useReactToPrint } from 'react-to-print';
import OrderNote from '../OrderNote/OrderNote';

export default function OrderInformation({
  orderInfs,
  setNewStatusOrder,
  close,
}) {
  const [orderDetails, setOrderDetails] = useState(null);
  const [labelButton, setLabelbutton] = useState({
    btnCancel: '',
    btnStatus: '',
  });
  const [modalStatus, setModalStatus] = useState(false);
  const [disablebutton, setDisablebutton] = useState(false);
  const [isCancelOrder, setIsCancelOrder] = useState(true);
  const [itemsOrder, setItemsOrder] = useState([{}]);
  const [priceOrder, setPriceOrder] = useState({
    total: 0,
    trade: 0,
    coupon: 0,
    delivery: 0,
  });
  const [historyStatusOrder, setHistoryStatusOrder] = useState([{}]);
  const [showStatusHistory, setShowStatusHistory] = useState(false);
  const [showDeliveryInfs, setShowDeliveryInfs] = useState(false);
  const [showCouponInfs, setShowCouponInfs] = useState(false);
  const [updatedOrder, setUpdatedOrder] = useState(null);
  const [disableBtnPrint, setDisableBtnPrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [callOrderNote, setCallOrderNote] = useState(false);

  const { alertOnChangeStatus, printOrder } = useContext(OrderContext);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => handlerPrintOrder(true),
  });

  useEffect(() => {
    const orderDetails =
      updatedOrder && updatedOrder.codigo === orderInfs.codigo
        ? updatedOrder
        : orderInfs;
    setOrderDetails(orderDetails);
    getLabelButton(orderDetails.cod_pedido_status);
    formatItemsOrder(orderDetails.items);
    formatPriceOrder(
      orderDetails.valor_total_com_desconto,
      orderDetails.pagamento_troco,
      orderDetails.valor_desconto,
      orderDetails.valor_entrega
    );
    formatHistoryOrder(orderDetails);
    setDisableBtnPrint(orderDetails.cod_pedido_status > 5);
    setCallOrderNote(
      orderDetails.cod_pedido_status > 1 && orderDetails.cod_pedido_status < 6
    );
  }, [orderInfs, updatedOrder]); //eslint-disable-line react-hooks/exhaustive-deps

  async function callSetStatusOrder(orderDetails) {
    let resp,
      msgFeedback,
      idOrder,
      concluded = null;
    idOrder = orderDetails.codigo;

    switch (orderDetails.cod_pedido_status) {
      case 1:
        resp = await acceptOrder(idOrder);
        msgFeedback = 'Pedido aceito.';
        break;
      case 2:
        resp = await setStatusOrder(idOrder);
        msgFeedback = `Pedido liberado para ${
          orderDetails.entrega ? 'entrega' : 'retirada'
        }.`;
        break;
      case 3:
        resp = await concludedOrder(idOrder);
        msgFeedback = 'Pedido concluído.';
        break;
      case 4:
        resp = await concludedOrder(idOrder);
        msgFeedback = 'Pedido concluído.';
        break;
      case 6:
        resp = await declineOrder(idOrder, false);
        msgFeedback = 'Pedido cancelado.';
        break;
      default:
        concluded = true;
        toast.warning('Em breve.');
        break;
    }
    if (!concluded) {
      if (resp.success) {
        toast.success(msgFeedback);
        updateOrder(idOrder, resp.result.status, orderDetails.historico);
      } else
        toast.error(
          'Erro ao alterar status do pedido, por favor tente novamente ou entre em contato conoso.'
        );
    }
    setModalStatus(false);
    setTimeout(() => {
      setDisablebutton(false);
    }, 1000);
  }

  async function cancelOrder(order) {
    const resp = await declineOrder(
      order.codigo,
      order.cod_pedido_status === 1
    );
    if (resp.success) {
      updateOrder(order.codigo, resp.result.status, orderDetails.historico);
      toast.success(
        `Pedido ${order.cod_pedido_status === 1 ? 'recusado' : 'cancelado'}.`
      );
    } else
      toast.error(
        'Erro ao cancelar o pedido, por favor tente novamente ou entre em contato conosco.'
      );
    setModalStatus(false);
  }

  function getLabelButton(idStatusOrder) {
    let label = '';
    switch (idStatusOrder) {
      case 1:
        label = { btnCancel: 'Recusar pedido', btnStatus: 'Aceitar pedido' };
        break;
      case 2:
        label = {
          btnCancel: 'Cancelar pedido',
          btnStatus: 'Liberar pedido para entrega/retirada',
        };
        break;
      case 3:
        label = { btnCancel: 'Cancelar pedido', btnStatus: 'Finalizar pedido' };
        break;
      case 4:
        label = { btnCancel: 'Cancelar pedido', btnStatus: 'Finalizar pedido' };
        break;
      case 5:
        label = { btnCancel: 'Cancelar pedido', btnStatus: 'Pedido concluído' };
        break;
      case 6:
        label = { btnCancel: 'Cancelar pedido', btnStatus: 'Cancelar pedido' };
        break;
      default:
        label = { btnCancel: 'Cancelar pedido', btnStatus: 'Pedido cancelado' };
        break;
    }
    setLabelbutton(label);
  }

  function formatItemsOrder(orders) {
    let itemsOrderFormated = orders.map((el) => {
      el.preco = maskCurrencyBRL(el.preco);
      el.subtotal = maskCurrencyBRL(el.subtotal);
      el.complementos.forEach((complement) => {
        complement.preco = maskCurrencyBRL(complement.preco);
      });
      return el;
    });
    setItemsOrder(itemsOrderFormated);
  }

  function formatPriceOrder(
    totalPrice,
    tradePrice,
    couponPrice,
    deliveryPrice
  ) {
    let tradeMoney,
      couponValue,
      total,
      delivery = null;
    if (couponPrice) couponValue = maskCurrencyBRL(couponPrice);
    if (deliveryPrice) delivery = maskCurrencyBRL(deliveryPrice);
    if (tradePrice) tradeMoney = maskCurrencyBRL(tradePrice);
    else tradeMoney = 'R$ 00,00';
    total = maskCurrencyBRL(totalPrice);
    setPriceOrder({
      total: total,
      trade: tradeMoney,
      coupon: couponValue,
      delivery: delivery,
    });
  }

  function changeStatusOrder(isCancelOrder = false) {
    setIsCancelOrder(isCancelOrder);
    if (alertOnChangeStatus) {
      setModalStatus(true);
    } else {
      setDisablebutton(true);
      if (isCancelOrder) cancelOrder(orderDetails);
      else callSetStatusOrder(orderDetails, true);
      setTimeout(() => {
        setDisablebutton(false);
      }, 1000);
    }
  }

  function formatHistoryOrder(orderInfs) {
    if (orderInfs)
      setHistoryStatusOrder(
        orderInfs.historico.map((el) => {
          el.time_formated = !el.time_formated
            ? formatDateTime(el.datahora)
            : el.time_formated;
          return el;
        })
      );
  }

  function updateOrder(idOrder, newIdStatusOrder, historyOrder) {
    const orderUpdated = {
      ...orderDetails,
      ...updateStatusOrder(idOrder, newIdStatusOrder, historyOrder),
    };
    setUpdatedOrder(orderUpdated);
    setNewStatusOrder(orderUpdated);
    if (printOrder && orderUpdated.cod_pedido_status === 2)
      handlerPrintOrder(false, orderUpdated.cod_pedido_status);
  }

  function handlerPrintOrder(afterPrint = false, codStatus) {
    if (!afterPrint && codStatus > 1 && codStatus < 6) {
      setDisableBtnPrint(true);
      setLoading(true);
      handlePrint();
    } else if (afterPrint) {
      setLoading(false);
      setDisableBtnPrint(false);
    } else toast.warning('Aceite o pedido para imprimi-lo.');
  }

  return (
    <div className="order-information p-2 d-flex flex-column justify-content-between h-100 overflow-y-auto">
      {orderDetails && (
        <>
          <div className="align-items-center flex-wrap d-inline-flex justify-content-between mb-2 w-100">
            <div className="vstack justify-content-between align-items-center w-100 flex-wrap gap-2">
              <div className="hstack justify-content-between align-items-center w-100 ">
                <span className="d-block fs-4 fw-bold text-left w-100">
                  Detalhes do pedido #{orderDetails.codigo}
                </span>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    setOrderDetails(null);
                    close();
                  }}
                ></button>
              </div>

              {printOrder && !disableBtnPrint && (
                <button
                  className={`align-items-center btn btn-primary gap-3 hstack justify-content-center w-100 ${
                    !printOrder || disableBtnPrint ? 'pe-none opacity-50' : ''
                  }`}
                  disabled={!printOrder || disableBtnPrint}
                  onClick={() =>
                    handlerPrintOrder(false, orderDetails.cod_pedido_status)
                  }
                >
                  <div className="hstack gap-2">
                    {loading ? (
                      <>
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        ></div>
                        <span>Carregando impressão</span>
                      </>
                    ) : (
                      <>
                        <span className="material-symbols-outlined">print</span>
                        <span>Imprimir pedido</span>
                      </>
                    )}
                  </div>
                </button>
              )}

              <button
                className="btn btn-primary-fill p-0 position-relative w-100"
                onMouseEnter={() => setShowStatusHistory(true)}
                onMouseLeave={() => setShowStatusHistory(false)}
              >
                <div className="hstack w-100 gap-1">
                  <span className="fs-5 material-symbols-outlined">
                    schedule
                  </span>
                  <span className="fs-7">
                    {orderDetails.last_status.status}
                  </span>
                  <span className="fs-7">{orderDetails.last_status.time}</span>
                </div>

                {showStatusHistory && (
                  <div className="position-absolute w-auto h-auto rounded bg-white vstack justify-content-between p-2 tooltip-content">
                    <span className="text-left pb-1 fs-6 fw-bold">
                      Histórico do pedido:
                    </span>
                    {historyStatusOrder.map((item, index) => {
                      return (
                        <div key={index} className="fs-7 mb-1">
                          <span>
                            {item.status} -{' '}
                            <span className="fw-bold">
                              {item.time_formated}
                            </span>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </button>
            </div>
          </div>
          <div className="hstack justify-content-between align-items-center mb-2 flex-wrap">
            <div className="hstack align-items-center justify-content-start position-relative w-100 gap-2">
              <button
                className="btn btn-primary-fill p-0 bg-white border px-3 py-1 border-success fs-7 position-relative rounded-5 text-success"
                onMouseEnter={() => setShowDeliveryInfs(true)}
                onMouseLeave={() => setShowDeliveryInfs(false)}
              >
                {orderDetails.entrega ? 'Entrega' : 'Retirada'}
              </button>

              {showDeliveryInfs && (
                <div className="position-absolute tooltip-content bg-white mt-2 hstack gap-1 p-2 rounded">
                  <span className="fs-7">Valor entrega:</span>
                  <span className="fs-7 fw-bold">{priceOrder.delivery}</span>
                </div>
              )}

              <div className="hstack gap-2">
                {orderDetails.cupom && (
                  <button
                    className="btn btn-primary-fill p-0 coupon-icon position-relative"
                    onMouseEnter={() => setShowCouponInfs(true)}
                    onMouseLeave={() => setShowCouponInfs(false)}
                  >
                    <span className="material-symbols-outlined text-dark bg-warning rounded-circle p-1">
                      redeem
                    </span>
                    {showCouponInfs && (
                      <div className="position-absolute tooltip-content mt-2 bg-white rounded p-1">
                        <div className="vstack text-left w-100 align-items-start">
                          <span className="fs-7">Cupom Utilizado:</span>
                          <span className="fs-7 fw-bold">
                            {orderDetails.coupon}
                          </span>
                        </div>
                        <div className="vstack text-left w-100 align-items-start">
                          <span className="fs-7">Desconto aplicado:</span>
                          <span className="fs-7 fw-bold">
                            {priceOrder.coupon}
                          </span>
                        </div>
                      </div>
                    )}
                  </button>
                )}

                <div className="w-100 hstack align-items-center gap-1">
                  <IconPaymentMethod
                    idPayment={orderDetails.cod_tipo_pagto}
                    width={36}
                    height={27}
                  />
                  <span className="fs-8 w-75 d-block">
                    {orderDetails.payment_method}
                  </span>
                </div>
              </div>
            </div>

            <button
              className={`btn my-3 w-100 ${
                orderDetails.cod_pedido_status >= 5
                  ? 'pe-none opacity-50'
                  : 'btn btn-outline-primary'
              } `}
              onClick={() => changeStatusOrder(true)}
              disabled={disablebutton || orderDetails.cod_pedido_status >= 5}
            >
              {labelButton.btnCancel}
            </button>
          </div>
          <div className="vstack w-100 h-75">
            <div>
              <div className="p-2 bg-light h-auto rounded mb-2 border shadow-sm">
                <span className="fs-7 fw-light mb-0">Dados do cliente</span>
                <div className="vstack">
                  <span className="fs-8 text-black fw-bold letter-spacing">
                    {orderDetails.nome}
                  </span>
                  <span className="fs-8 text-black fw-bold letter-spacing">
                    {orderDetails.endereco_rua}, {orderDetails.endereco_nro} -{' '}
                    {orderDetails.endereco_bairro} -{' '}
                    {orderDetails.endereco_cidade}/{orderDetails.endereco_uf}
                  </span>
                  <span className="fs-8 text-black fw-bold letter-spacing">
                    CEP: {orderDetails.endereco_cep}
                  </span>
                </div>
              </div>

              <div className="p-2 bg-light h-auto rounded mb-2 border shadow-sm ">
                <span className="fs-6 fw-bold mb-1">Pedido:</span>
                {itemsOrder.map((order, index) => {
                  return (
                    <div key={index} className="hstack">
                      <div className="w-100 hstack align-items-start justify-content-between position-relative overflow-hidden mb-2 ">
                        <div className="justify-content-between vstack w-100">
                          <div className="w-100 hstack justify-content-between position-relative">
                            <span className="fs-8 text-black fw-bold letter-spacing bg-light z-1">
                              {order.qtd}x {order.nome}
                            </span>
                            <span className="pointer">
                              . . . . . . . . . . . . . . . . . . . . . . . . .
                              . . . . . . . . . . . . . . . . . . . . . . . . .
                              . . . . . . . . . . . . . . . . . . . . . . . . .
                              . . . . . . . . . . . .
                            </span>
                            <div className="z-1 hstack gap-1 bg-light">
                              <span className="fs-8 text-body-secondary fw-bold letter-spacing ">
                                {order.preco}
                              </span>
                              <span className="fs-8 text-black fw-bold letter-spacing">
                                ({order.subtotal})
                              </span>
                            </div>
                          </div>
                          <div className="pl-2">
                            {order.complementos.map((complement, index) => {
                              return (
                                <div
                                  key={index}
                                  className="d-flex justify-content-between"
                                >
                                  <span className="fs-8 text-black fw-bold letter-spacing z-1 bg-light">
                                    {complement.grupo} - {complement.nome}
                                  </span>
                                  <span className="pointer break-line">
                                    . . . . . . . . . . . . . . . . . . . . . .
                                    . . . . . . . . . . . . . . . . . . . . . .
                                    . . . . . . . . . . . . . . . . . . . . . .
                                    . . . . . . . . . . . . . . . . . . . . .
                                  </span>
                                  <span className="bg-light fs-8 fw-bold letter-spacing text-black z-1">
                                    {complement.preco}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {orderDetails.obs && (
                <div className="p-2 bg-light h-auto rounded mb-2 border shadow-sm ">
                  <span className="fs-7 fw-light mb-0">Obersavação:</span>
                  <span className="fs-8 text-black fw-bold">
                    {orderDetails.obs}
                  </span>
                </div>
              )}
            </div>

            <div className="vstack w-100 gap-2">
              <div className="hstack w-100 justify-content-center gap-4">
                <div className="fs-6 hstack gap-1 align-items-center">
                  <span className="fs-8">Total:</span>
                  <span className="fw-bold text-success">
                    {priceOrder.total}
                  </span>
                </div>
                <div className="fs-6 hstack gap-1 align-items-center">
                  <span className="fs-8">Troco:</span>
                  <span className="fw-bold text-success">
                    {priceOrder.trade}
                  </span>
                </div>
              </div>

              <button
                className={`btn btn-primary w-100 ${
                  orderDetails.cod_pedido_status >= 5
                    ? 'pe-none opacity-50'
                    : ''
                }`}
                disabled={orderDetails.cod_pedido_status >= 5}
                onClick={() => changeStatusOrder()}
              >
                {labelButton.btnStatus}
              </button>
            </div>
          </div>
        </>
      )}

      {callOrderNote && (
        <div style={{ display: 'none' }}>
          <div ref={componentRef}>
            <OrderNote orderInfs={orderDetails} orderPrices={priceOrder} />
          </div>
        </div>
      )}

      <SpringModal handleOpen={modalStatus} handleClose={setModalStatus}>
        <div className="vstack align-items-center justify-content-center bg-white rounded shadow-lg p-2 gap-4">
          <span className="fs-4 fw-bold">Confirmar alteração:</span>
          <span className="fs-6 text-center">
            Após confirmar a ação não será possível reverter.
          </span>

          <div className="vstack gap-2 w-100 justify-content-between">
            <button
              className="btn btn-outline-primary w-100"
              onClick={() => setModalStatus(false)}
            >
              Cancelar
            </button>

            <button
              className="btn btn-primary w-100"
              onClick={() =>
                isCancelOrder
                  ? cancelOrder(orderDetails)
                  : (setDisablebutton(true), callSetStatusOrder(orderDetails))
              }
              disabled={disablebutton}
            >
              Confirmar
            </button>
          </div>
        </div>
      </SpringModal>
    </div>
  );
}
