import React from 'react';
import Sidebar from 'react-sidebar';
import UserOrders from '../../UserOrders/UserOrders';

function OrdersHistory({ close }) {
  return (
    <Sidebar open={true} pullRight={false} contentClassName="bg-white z-3">
      <UserOrders closeSideBar={() => close()} />
    </Sidebar>
  );
}

export default OrdersHistory;
