import React, { useState } from 'react';
import ClientAuthMethods from './ClientAuthMethods';
import SignInClient from './SignInClient';
import CreateClientAccount from './CreateClientAccount';
import RecoverPasswordCore from '../../../Authentication/Store/RecoverPassword';

function AuthenticateClient({ back, authenticated }) {
  const [isUnknown, setIsUnknown] = useState(true);
  const [signIn, setSignIn] = useState(true);
  const [recoverPassword, setRecoverPassword] = useState(false);

  function handleAuthMethod(isSignIn) {
    setSignIn(isSignIn);
    setIsUnknown(false);
  }

  return isUnknown ? (
    <ClientAuthMethods
      sign={() => handleAuthMethod(true)}
      createAccount={() => handleAuthMethod(false)}
      close={() => back()}
    />
  ) : signIn ? (
    <SignInClient
      backStep={() => setIsUnknown(true)}
      onAuthenticate={() => authenticated()}
      recoverPassword={() => {
        setSignIn(false);
        setRecoverPassword(true);
      }}
    />
  ) : recoverPassword ? (
    <RecoverPasswordCore
      isClientRecover={true}
      goBack={() => {
        setIsUnknown(false);
        setSignIn(true);
      }}
    />
  ) : (
    <CreateClientAccount
      backToSignIn={() => setIsUnknown(true)}
      onCreateAccount={() => authenticated()}
    />
  );
}

export default AuthenticateClient;
