import React, { useEffect, useState } from 'react';
import './Resumo.scss';
import { Link } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import {
  formatDateTime,
  maskCurrencyBRLWithoutSymbol,
} from '../../Utils/Index';
import moment from 'moment';
import {
  getChartInvoicingOrders,
  getOrderDetails,
  getResumeCards,
  getResumeGridOrders,
  getTopProducts,
} from '../../services/Resume';
import {
  BarPlot,
  ChartsLegend,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  ResponsiveChartContainer,
} from '@mui/x-charts';
import { ptBR } from '@mui/x-data-grid/locales';
import SpringModal from '../../shared/Modal/Modal';

export default function Resumo() {
  const [formData, setFormData] = useState({
    dh_inicio: new Date(moment().subtract(7, 'days')),
    dh_final: new Date(moment()),
  });

  const [cards, setCards] = useState([
    {
      id: 'pedidos_atendidos',
      title: 'Pedidos Atendidos',
      labelView: 0,
    },
    {
      id: 'pedidos_cancelados',
      title: 'Pedidos Cancelados',
      labelView: 0,
    },
    {
      id: 'valor_total',
      title: 'Faturamento Bruto',
      labelView: `R$ 0,00`,
    },
  ]);

  const [tabNav, setTabNav] = useState([
    {
      label: 'Pedidos',
      actived: true,
    },
    {
      label: 'Ranking +Vendidos',
      actived: false,
    },
  ]);

  const [charts, setCharts] = useState([
    {
      label: 'Pedidos x Faturamento',
    },
  ]);
  const [dataChart, setDataChart] = useState([]);

  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsRanking, setRowsRanking] = useState([]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [openModal, setOpenModal] = useState(false);
  const [order, setOrder] = useState(null);

  const columns = [
    {
      field: 'dh_criacao',
      headerName: 'Data/Hora',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return formatDateTime(params.value);
      },
    },
    {
      field: 'codigo',
      headerName: 'Nº. Pedido',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return `#${params.value}`;
      },
    },
    {
      field: 'valor_total',
      headerName: 'Valor Total',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return `R$ ${maskCurrencyBRLWithoutSymbol(params.value)}`;
      },
    },
    {
      field: 'valor_total_com_desconto',
      headerName: 'Total c/ Desconto',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return `R$ ${maskCurrencyBRLWithoutSymbol(params.value)}`;
      },
    },
    {
      field: 'nome_cliente',
      headerName: 'Cliente',
      sortable: false,
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 150,
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const handleClick = () => {
          getOrder(params.row.codigo);
        };

        return (
          <button
            className="btn btn-sm btn-outline-primary"
            type="button"
            onClick={() => handleClick()}
          >
            Visualizar
          </button>
        );
      },
    },
  ];

  const columnsRanking = [
    {
      field: 'nome',
      headerName: 'Produto',
      sortable: false,
      width: 150,
    },
    {
      field: 'total_qtd_vendida',
      headerName: 'Total',
      sortable: false,
      width: 150,
    },
  ];

  const handlerActiveTab = (infs) => {
    let temp = tabNav.map((el) => {
      el.actived = infs.label === el.label;
      return el;
    });

    if (infs.label === 'Pedidos') {
      getDataGrid();
    } else if (infs.label === 'Ranking +Vendidos') {
      getRankingProducts();
    }

    setTabNav(temp);
  };

  const formatDateForInput = (dateString) => {
    return moment.utc(dateString).format('YYYY-MM-DD');
  };

  useEffect(() => {
    getCards();
    getChart();
  }, []);

  useEffect(() => {
    getDataGrid();
  }, [paginationModel]);

  const getCards = async () => {
    let resp = await getResumeCards(formData.dh_inicio, formData.dh_final);

    if (resp.success) {
      const items = cards.map((item) => {
        item.labelView =
          item.id === 'valor_total'
            ? `R$${maskCurrencyBRLWithoutSymbol(resp.result[item.id])}`
            : resp.result[item.id];

        return item;
      });

      setCards(items);
    }
  };

  const getData = () => {
    getCards();
    getDataGrid();
    getRankingProducts();
    getChart();
  };

  const getDataGrid = async () => {
    setIsLoading(true);
    let resp = await getResumeGridOrders(
      formData.dh_inicio,
      formData.dh_final,
      paginationModel.page,
      paginationModel.pageSize
    );
    if (resp.success && resp.result.length) {
      const items = resp.result.map((item) => {
        item.id = item.codigo;

        return item;
      });
      setRows(items);
      setTotalRows(resp.total_items);
      setTotalPages(resp.total_pages);
    } else {
      setRows([]);
      setTotalRows(0);
      setTotalPages(0);
    }
    setIsLoading(false);
  };

  const getRankingProducts = async () => {
    setIsLoading(true);
    let resp = await getTopProducts(formData.dh_inicio, formData.dh_final);

    if (resp.success && resp.result.length) {
      setRowsRanking(resp.result);
    } else {
      setRowsRanking([]);
    }
    setIsLoading(false);
  };

  const getChart = async () => {
    let resp = await getChartInvoicingOrders(
      formData.dh_inicio,
      formData.dh_final
    );

    if (resp.success && resp.result.length) {
      setDataChart(resp.result);
    } else {
      setDataChart([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getOrder = async (orderNumber) => {
    setIsLoading(true);

    let resp = await getOrderDetails(orderNumber);

    if (resp.success) {
      setOrder(resp.result);
      setOpenModal(true);
    }

    setIsLoading(false);
  };

  return (
    <div className="resumo-container">
      <SpringModal handleOpen={openModal} handleClose={setOpenModal}>
        {order && (
          <div className="p-3 overflow-y-auto">
            <div className="w-100 hstack justify-content-between px-2">
              <h2 className="fw-bold fs-2 text-center">
                Pedido #{order.codigo}
              </h2>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setOpenModal(false)}
              ></button>
            </div>
            <div className="container content-modal">
              {/* Data/Hora - Campos de Pedido */}
              <div className="section-fields">
                <h4>Histórico do pedido</h4>

                {order.historico.map((hist, index) => {
                  return (
                    <div key={'status_order_' + index} className="d-flex gap-2">
                      <div className="fw-bold">
                        {formatDateTime(hist.datahora)}
                      </div>
                      <div>{hist.status}</div>
                    </div>
                  );
                })}
              </div>

              {/* Status, Nome e Observação */}
              <div className="section-fields">
                <h4>Sobre</h4>
                <div className="d-flex gap-2 justify-content-between">
                  <div className="mb-3">
                    <label htmlFor="nome" className="form-label">
                      Status do pedido
                    </label>
                    <div>{order.status}</div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="nome" className="form-label">
                      Tipo de entrega
                    </label>
                    <div>{order.entrega ? 'Delivery' : 'Retirada'}</div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="obs" className="form-label">
                    Observação
                  </label>
                  <div>{order.obs}</div>
                </div>
              </div>

              {/* Itens */}
              <div className="section-fields">
                <h4>Itens</h4>

                <div className="d-flex gap-2 justify-content-between">
                  <div className="text-secondary fw-bold">Item</div>
                  <div className="d-flex gap-2">
                    <div
                      className="text-secondary fw-bold"
                      style={{ width: 100, textAlign: 'right' }}
                    >
                      Preço
                    </div>
                    <div
                      className="text-secondary fw-bold"
                      style={{ width: 100, textAlign: 'right' }}
                    >
                      Subtotal
                    </div>
                  </div>
                </div>

                {order.items.map((item, index) => {
                  return (
                    <>
                      <div
                        key={'itens_' + index}
                        className="d-flex gap-2 justify-content-between"
                        style={{
                          borderBottom: 1,
                          borderBottomStyle: 'dashed',
                          borderColor: 'lightgray',
                        }}
                      >
                        <div className="">
                          {item.qtd}x {item.nome ? item.nome : 'sem nome'}
                        </div>
                        <div className="d-flex gap-2">
                          <div style={{ width: 100, textAlign: 'right' }}>
                            R${maskCurrencyBRLWithoutSymbol(item.preco)}
                          </div>
                          <div style={{ width: 100, textAlign: 'right' }}>
                            R${maskCurrencyBRLWithoutSymbol(item.subtotal)}
                          </div>
                        </div>
                      </div>
                      {item.complementos.length > 0 &&
                        item.complementos.map((comp, idx) => {
                          return (
                            <div
                              key={'complement_' + idx}
                              className="d-flex gap-2 justify-content-between"
                            >
                              <div className="" style={{ paddingLeft: 16 }}>
                                {comp.grupo} {'>'}{' '}
                                {comp.nome ? comp.nome : 'sem nome'}
                              </div>
                              <div style={{ width: 100, textAlign: 'right' }}>
                                R${maskCurrencyBRLWithoutSymbol(comp.preco)}
                              </div>
                            </div>
                          );
                        })}
                    </>
                  );
                })}
              </div>

              {/* Dados do cliente */}
              <div className="section-fields">
                <h4>Dados do cliente</h4>

                <div className="mb-3">
                  <label htmlFor="nome" className="form-label">
                    Nome
                  </label>
                  <div>{order.nome}</div>
                </div>

                <div className="d-flex gap-2 justify-content-between">
                  <div className="mb-3">
                    <label htmlFor="endereco_rua" className="form-label">
                      Logradouro
                    </label>
                    <div>{order.endereco_rua}</div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="endereco_nro" className="form-label">
                      Número
                    </label>
                    <div>{order.endereco_nro ? order.endereco_nro : '-'}</div>
                  </div>
                </div>

                {order.endereco_complemento && (
                  <div className="mb-3">
                    <label
                      htmlFor="endereco_complemento"
                      className="form-label"
                    >
                      Complemento
                    </label>
                    <div>
                      {order.endereco_complemento
                        ? order.endereco_complemento
                        : '-'}
                    </div>
                  </div>
                )}

                <div className="mb-3">
                  <label htmlFor="endereco_bairro" className="form-label">
                    Bairro
                  </label>
                  <div>{order.endereco_bairro}</div>
                </div>

                <div className="d-flex gap-2 justify-content-between">
                  <div className="mb-3">
                    <label htmlFor="endereco_cidade" className="form-label">
                      Cidade
                    </label>
                    <div>{order.endereco_cidade}</div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="endereco_uf" className="form-label">
                      Estado (UF)
                    </label>
                    <div>{order.endereco_uf}</div>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="endereco_cep" className="form-label">
                    CEP
                  </label>
                  <div>{order.endereco_cep}</div>
                </div>
              </div>

              {/* Cupom e Descontos */}
              <div className="section-fields">
                <h4>Desconto</h4>

                <div className="d-flex gap-2 justify-content-between">
                  <div className="mb-3">
                    <label htmlFor="cupom" className="form-label">
                      Cupom
                    </label>
                    <div>
                      {order.cupom ? order.cupom : 'Nenhum cupom utilizado'}
                    </div>
                  </div>

                  {order.cupom && order.desconto_percentual && (
                    <div className="mb-3">
                      <label
                        htmlFor="desconto_percentual"
                        className="form-label"
                      >
                        Desconto Percentual
                      </label>
                      <div>{order.desconto_percentual}%</div>
                    </div>
                  )}

                  {order.cupom && order.desconto_valor && (
                    <div className="mb-3">
                      <label htmlFor="desconto_valor" className="form-label">
                        Desconto em Valor
                      </label>
                      <div>
                        R${maskCurrencyBRLWithoutSymbol(order.desconto_valor)}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Dados de Pagamento */}

              <div className="section-fields">
                <h4>Financeiro</h4>

                <div className="d-flex gap-2 justify-content-between">
                  <div className="mb-3">
                    <label htmlFor="pagamento_grupo" className="form-label">
                      Metodo de Pagamento
                    </label>
                    <div>{order.pagamento_grupo}</div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="pagamento_tipo" className="form-label">
                      Tipo de Pagamento
                    </label>
                    <div>{order.pagamento_tipo}</div>
                  </div>
                </div>
                <div className="d-flex gap-2 justify-content-between">
                  <div className="mb-3">
                    <label htmlFor="pagamento_troco" className="form-label">
                      Troco para Pagamento
                    </label>
                    <div>
                      R${maskCurrencyBRLWithoutSymbol(order.pagamento_troco)}
                    </div>
                  </div>

                  {/* Valores */}
                  <div className="mb-3">
                    <label htmlFor="valor_desconto" className="form-label">
                      Valor do Desconto
                    </label>
                    <div>
                      R${maskCurrencyBRLWithoutSymbol(order.valor_desconto)}
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="valor_entrega" className="form-label">
                    Valor da Entrega
                  </label>
                  <div>
                    R${maskCurrencyBRLWithoutSymbol(order.valor_entrega)}
                  </div>
                </div>

                <div className="d-flex gap-2 justify-content-between">
                  <div className="mb-3">
                    <label htmlFor="valor_total" className="form-label">
                      Valor Total
                    </label>
                    <div>
                      R${maskCurrencyBRLWithoutSymbol(order.valor_total)}
                    </div>
                  </div>

                  {order.valor_total !== order.valor_total_com_desconto && (
                    <div className="mb-3">
                      <label
                        htmlFor="valor_total_com_desconto"
                        className="form-label"
                      >
                        Valor Total com Desconto
                      </label>
                      <div>
                        R$
                        {maskCurrencyBRLWithoutSymbol(
                          order.valor_total_com_desconto
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </SpringModal>

      <h3>Resumo geral</h3>
      <div className="label-filter">Filtrar Período</div>
      <div className="period-filter">
        <div>
          <label htmlFor="dh_inicio" className="form-label">
            De
          </label>
          <input
            type="date"
            className="form-control"
            id="dh_inicio"
            name="dh_inicio"
            value={formatDateForInput(formData.dh_inicio)}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="dh_inicio" className="form-label">
            Até
          </label>
          <input
            type="date"
            className="form-control"
            id="dh_final"
            name="dh_final"
            value={formatDateForInput(formData.dh_final)}
            onChange={handleChange}
            required
          />
        </div>
        <button
          className="btn btn-primary"
          type="submit"
          onClick={() => getData()}
        >
          Filtrar
        </button>
      </div>
      <div className="list-cards">
        {cards.map((item, index) => {
          return (
            <div key={'card_' + index} className="card">
              <div className="card-title">{item.title}</div>
              <div className="card-value">{item.labelView}</div>
            </div>
          );
        })}
      </div>
      <div className="line-nav">
        {tabNav.map((item, index) => {
          return (
            <Link
              key={'tab_' + index}
              className={
                item.actived ? 'card-config card-active' : 'card-config'
              }
              onClick={(_) => handlerActiveTab(item)}
            >
              {item.label}
            </Link>
          );
        })}
      </div>
      <div className="section-grid">
        {tabNav[0].actived && (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[10, 50, 100]}
            disableColumnSelector
            disableRowSelectionOnClick
            disableColumnMenu
            autoHeight
            paginationMode="server"
            paginationModel={paginationModel}
            pagination
            initialState={{
              pagination: {
                paginationModel,
              },
            }}
            page={paginationModel?.page}
            rowCount={totalRows}
            loading={isLoading}
            onPaginationModelChange={setPaginationModel}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
        {tabNav[1].actived && (
          <DataGrid
            rows={rowsRanking}
            columns={columnsRanking}
            pageSizeOptions={[10, 25]}
            disableColumnSelector
            disableRowSelectionOnClick
            disableColumnMenu
            autoHeight
            loading={isLoading}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
      </div>
      <div className="section-charts">
        {charts.map((item, index) => {
          return <div key={'chart_' + index}>{item.label}</div>;
        })}
      </div>
      {dataChart?.length > 0 && (
        <ResponsiveChartContainer
          series={[
            {
              type: `bar`,
              data: dataChart.map((item) => item.qtd_pedidos),
              label: 'Quantidade de Pedidos',
              color: '#2f80ed',
              showMark: false,
            },
            {
              type: `bar`,
              data: dataChart.map((item) => item.qtd_cancelados),
              label: 'Pedidos Cancelados',
              color: '#f10542',
              showMark: false,
            },
          ]}
          xAxis={[
            {
              dataKey: 'date',
              id: 'datatime',
              scaleType: 'band',
              data: dataChart.map((item) => {
                return new Date(item.data);
              }),
              valueFormatter: (date) =>
                moment(date).add(1, 'day').format('DD/MM/YY'),
            },
          ]}
          height={500}
        >
          <BarPlot />
          <ChartsTooltip />
          <ChartsLegend direction="row" />
          <ChartsYAxis />
          <ChartsXAxis label="" position="bottom" axisId="datatime" />
        </ResponsiveChartContainer>
      )}

      {dataChart?.length > 0 && (
        <ResponsiveChartContainer
          series={[
            {
              type: `line`,
              data: dataChart.map((item) => item.faturamento_total),
              label: 'Faturamento',
              color: '#02b345',
              valueFormatter: (val) => `R$${maskCurrencyBRLWithoutSymbol(val)}`,
            },
          ]}
          xAxis={[
            {
              dataKey: 'date',
              id: 'datatime',
              scaleType: 'band',
              data: dataChart.map((item) => {
                return new Date(item.data);
              }),
              valueFormatter: (date) =>
                moment(date).add(1, 'day').format('DD/MM/YY'),
            },
          ]}
          height={500}
        >
          <LinePlot />
          <ChartsTooltip />
          <ChartsLegend direction="row" />
          <ChartsYAxis />
          <ChartsXAxis label="" position="bottom" axisId="datatime" />
        </ResponsiveChartContainer>
      )}

      {dataChart?.length === 0 && (
        <div>Nenhum dado para exibir nesse período!</div>
      )}
    </div>
  );
}
