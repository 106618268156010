import React from 'react';
import { LOGO_IMAGE_URL } from '../Utils/Index';

function CompanyDetails() {
  const FUNCS = [
    {
      title: 'Gerencie seus pedidos',
      text: 'Acompanhe e controle todos os pedidos em tempo real, garantindo uma entrega eficiente.',
    },
    {
      title: 'Insights de acesso',
      text: 'Descubra como os clientes interagem com seu cardápio e otimize a experiência.',
    },
    {
      title: 'Área de entrega',
      text: 'Defina e gerencie facilmente sua área de entrega, alcançando mais clientes.',
    },
  ];

  return (
    <div className="py-4 bg-body-tertiary align-items-center vstack p-2">
      <div
        className="d-flex flex-column gap-3 align-items-center flex-sm-row justify-content-between"
        style={{ maxWidth: 980 }}
      >
        <div className="col-md-4">
          <img
            alt="Logo delivery.Legal"
            loading="lazy"
            width="320"
            src={LOGO_IMAGE_URL}
          />
        </div>

        <div className="col-md-6">
          <h2 className="fs-3 text-center text-sm-start">
            <span className="text-primary fw-bold">Delivery.Legal</span>, uma
            plataforma projetada para impulsionar as vendas do seu
            estabelecimento.
          </h2>
          <p className="fs-5 text-center text-sm-start">
            Oferecemos um sistema de cardápio próprio e diversas funcionalidades
            para o gerenciamento do seu estabelecimento e pedidos dos clientes.
          </p>
          <p className="fs-5 text-center text-sm-start">
            Nossa plataforma busca revolucionar a experiência de pedidos online
            para uma vida mais prática. Saiba como funcionamos:
          </p>
        </div>
      </div>

      <div className="d-flex flex-column flex-sm-row hstack justify-content-evenly w-100">
        {FUNCS.map((item, index) => {
          return (
            <div key={index} className="company-details-funcs">
              <h4 className="fs-5 fw-bold mb-2 text-center text-sm-start">
                {item.title}
              </h4>
              <p className="fs-6">{item.text}</p>
            </div>
          );
        })}
      </div>

      <a
        className="btn btn-primary w-fit px-5 py-2 fw-bold"
        href="/criar-conta"
      >
        Experimente grátis
      </a>
    </div>
  );
}

export default CompanyDetails;
