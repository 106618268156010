import React from 'react';
import { LOGO_IMAGE_URL } from '../Utils/Index';
import Footer from '../LandingPage/Footer/Footer';
import FAQ from './FAQ';
import CompanyDetails from './CompanyDetails';
import SupportForm from './SupportForm';

function SupportPage() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <nav className="navbar p-0 bg-white">
        <div className="hstack p-2">
          <a href="/home">
            <img
              src={LOGO_IMAGE_URL}
              alt="Logo"
              width="160"
              height="76"
              className="d-inline-block align-text-top"
            />
          </a>
          <div className="vr mx-3"></div>
          <h1 className="fs-1 fw-bold">Suporte</h1>
        </div>
      </nav>

      <main className="bg-primary d-flex flex-grow-1 justify-content-center align-items-center">
        <div
          className="align-items-center hstack max-w-fit vstack p-3"
          style={{ minHeight: 300 }}
        >
          <h1 className="m-0 text-white fw-bold fs-1 text-center">
            Como podemos te ajudar ?
          </h1>

          <img
            src={`${process.env.REACT_APP_FIREBASE_URL}/assets%2Fsuporte%2Fsupport.png?alt=media`}
            alt="Imagem com várias pessoas trabalhando juntas"
            width="280"
            height="280"
          />
        </div>
      </main>

      <div className="w-100 h-100 bg-white p-2 pb-3">
        <FAQ />
      </div>

      <CompanyDetails />

      <SupportForm />

      <div className="text-center py-5">
        <h2 className="mb-4 fw-bold">Siga-nos nas redes sociais</h2>
        <div className="d-flex justify-content-center gap-3">
          <a
            href="https://instagram.com/delivery.legal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${process.env.REACT_APP_FIREBASE_URL}/assets%2Flanding_page%2Finst.png?alt=media`}
              alt="Imagem com várias pessoas trabalhando juntas"
              width="24"
              height="24"
            />
          </a>
          <a
            href="https://tiktok.com/@deliverylegal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${process.env.REACT_APP_FIREBASE_URL}/assets%2Flanding_page%2Ftt.png?alt=media`}
              alt="Imagem com várias pessoas trabalhando juntas"
              width="24"
              height="24"
            />
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SupportPage;
