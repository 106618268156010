import React from 'react';
import './ModalConfirmAction.scss';

function ModalConfirmAction({
  title,
  warningMesage,
  labelConfirmBtn = 'Confirmar',
  labelCancelBtn = 'Cancelar',
  handleAction,
}) {
  return (
    <div className="dl-modal">
      <h1 className="dl-modal-title-modal">{title}</h1>
      <span className="dl-modal-txt-warning">{warningMesage}</span>
      <div className="dl-modal-align-btn">
        <button
          className="Btn add-items btn-modal-cancel"
          onClick={() => handleAction(false)}
        >
          {labelCancelBtn}
        </button>
        <button
          className="Btn add-items btn-modal"
          onClick={() => handleAction(true)}
        >
          {labelConfirmBtn}
        </button>
      </div>
    </div>
  );
}

export default ModalConfirmAction;
