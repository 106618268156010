import React, { useContext, useEffect, useState } from 'react';
import { getUserData } from '../../../../services/Consumer';
import { toast } from 'react-toastify';
import { maskCEP } from '../../../../Utils/Index';
import { logout } from '../../../../services/Menu';
import { userDataContext } from '../../../MenuProvider/MenuProvider';

function UserData({ editPassword, editAddress, close }) {
  const accountInfos = [
    { label: 'Nome', field: 'name' },
    { label: 'CPF', field: 'cpf' },
    { label: 'Telefone', field: 'phone' },
    { label: 'E-mail', field: 'email' },
    { label: 'Senha', field: 'password' },
  ];

  const addressInfos = [
    { label: 'Número', field: 'number' },
    { label: 'Complemento', field: 'complement' },
    { label: 'Bairro', field: 'neighborhood' },
    { label: 'CEP', field: 'cep' },
    { label: 'Cidade', field: 'city' },
    { label: 'Estado', field: 'state' },
  ];

  const { userData, setUserData } = useContext(userDataContext);
  const [address, setAddress] = useState();
  const [personalData, setPersonalData] = useState();
  const [loading, setLoading] = useState(true);
  const [errorOnLoad, setErrorOnLoad] = useState(false);

  useEffect(() => {
    if (userData?.personal?.email) {
      handleUserInfos(userData);
      return;
    }
    callGetUserData();
  }, []);

  function callGetUserData() {
    setLoading(true);
    getUserData()
      .then((response) => handleUserInfos(response.result))
      .catch(() => {
        setErrorOnLoad(true);
        setLoading(false);
        toast.error(
          'Ocorreu um erro ao dados da conta. tente novamente ou entre em contato conosco.'
        );
      });
  }

  function handleUserInfos(infos) {
    const { address, personal } = infos;
    address.cep = maskCEP(address.cep);
    address.complement = address.complement ? address.complement : 'N/A';
    setAddress(address);
    setPersonalData(personal);
    setLoading(false);
    userData.personal = personal;
    setUserData(userData);
  }

  function loadingOrErrorContent() {
    return (
      <div className="vstack py-3 align-items-center gap-1">
        {loading ? (
          <>
            <span className="fs-8">Carregando dados, por favor aguarde</span>
            <div
              className="spinner-border spinner-border-sm"
              role="status"
            ></div>
          </>
        ) : (
          <>
            <h2 className="fw-bold fs-5">Erro ao carregar dados</h2>
            <span className="fs-8 text-danger">
              Ocorreu um erro ao carregar os dados da conta, tente novamente ou
              entre em contato com nosso suporte.
            </span>

            <button
              type="button"
              className="btn btn-primary w-100 mt-4"
              onClick={() => callGetUserData()}
            >
              Tentar novamente
            </button>

            <button
              type="button"
              className="btn btn-outline-primary w-100 mt-4 hstack text-center gap-1 justify-content-center"
              onClick={() => logout()}
            >
              <span>Deslogar</span>
              <span className="material-symbols-outlined  fs-7">logout</span>
            </button>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="p-2 w-100 vstack gap-4">
      {loading || errorOnLoad ? (
        loadingOrErrorContent()
      ) : (
        <>
          <div className="vstack w-100">
            <h2 className="text-center fw-bold text-primary mb-1">
              Dados pessoais
            </h2>

            <div className="w-100 vstack gap-1">
              {accountInfos.map((item) => {
                return (
                  <div key={item.field} className="vstack border-bottom py-1">
                    <span className="fs-8">{item.label}</span>
                    <>
                      {item.field === 'password' ? (
                        <div className="w-100 hstack justify-content-between align-items-center">
                          <span className="fs-8 fw-bold">***********</span>
                          <button
                            className="btn btn-primary-fill fs-8 btn-sm m-0 p-1"
                            onClick={() => editPassword()}
                            disabled={errorOnLoad}
                          >
                            <span className="material-symbols-outlined fs-6">
                              edit
                            </span>
                          </button>
                        </div>
                      ) : (
                        <span className="fs-8 fw-bold">
                          {personalData[item.field]}
                        </span>
                      )}
                    </>
                  </div>
                );
              })}

              <div className="w-100 justify-content-between align-items-center hstack border-bottom py-1 fw-bold">
                <span className="fs-8">Sair da conta</span>
                <button
                  type="button"
                  className="btn btn-primary-fill py-1 px-2"
                  onClick={() => logout()}
                >
                  <span className="material-symbols-outlined  fs-7">
                    logout
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="vstack w-100">
            <div className="hstack w-100 justify-content-between align-items-center">
              <h2 className="text-center fw-bold text-primary mb-1">
                Endereço
              </h2>
              <button
                className="btn btn-primary-fill fs-8 btn-sm m-0 p-1"
                onClick={() => editAddress()}
                disabled={errorOnLoad}
              >
                <span className="material-symbols-outlined fs-6">edit</span>
              </button>
            </div>

            <div className="vstack border-bottom py-1">
              <span className="fs-8">Logradourdo</span>
              <span className="fs-8 fw-bold">{address.street}</span>
            </div>

            <div className="d-flex flex-wrap">
              {addressInfos.map((item) => {
                return (
                  <div
                    key={item.field}
                    className="vstack border-bottom py-1 w-50"
                  >
                    <span className="fs-8">{item.label}</span>
                    <span className="fs-8 fw-bold">{address[item.field]}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      <div className="w-100 hstack align-items-center justify-content-between gap-1">
        <button
          type="button"
          className="btn btn-outline-primary w-100"
          onClick={() => close()}
        >
          Fechar
        </button>
      </div>
    </div>
  );
}

export default UserData;
