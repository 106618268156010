import React, { useState } from 'react';
import { getActualDay } from '../../../../Utils/Index';
import { TextField } from '@material-ui/core';
import moment from 'moment';

function StoreStatusAlertModal({
  configureNewHour,
  openToday,
  openingHours,
  close,
  openStore,
}) {
  const [newOpeningHours, setNewOpeningHours] = useState({
    timeToOpen: '09:00',
    timeToClose: '18:00',
  });
  const [invalidNewHours, setInvalidNewHours] = useState({
    error: false,
    mensage: '',
  });

  function HandleOpeningHours(type, time) {
    const actualHours = moment().format('HH:mm');
    if (type === 'inicio') {
      setNewOpeningHours({ ...newOpeningHours, ...{ timeToOpen: time } });

      if (!validTime('fim', actualHours, time)) {
        setInvalidNewHours({
          error: true,
          message:
            'Horário de abertura não pode ser inferior ao horário atual.',
        });
        return;
      }

      if (!validTime('inicio', newOpeningHours.timeToClose, time)) {
        setInvalidNewHours({
          error: true,
          message:
            'Horário de abertura não pode ser superior ao horário de fechamento.',
        });
        return;
      }

      setInvalidNewHours({ error: false, message: '' });
    } else {
      setNewOpeningHours({ ...newOpeningHours, ...{ timeToClose: time } });

      if (!validTime('fim', newOpeningHours.timeToOpen, time)) {
        setInvalidNewHours({
          error: true,
          message:
            'Horário de fechamento não pode ser inferior ao horário de abertura.',
        });
        return;
      }
    }
    setInvalidNewHours({ error: false, message: '' });
  }

  function validTime(type, timeLimit, value) {
    if (value) {
      if (value[0].includes('0')) {
        let first = value[0] === '0' ? '2' : value[0];
        let second = (parseInt(value[1]) + 3).toString();
        let time = `${first}${second}:${value[3]}${value[4]}`;
        return type === 'fim' ? timeLimit <= time : timeLimit >= time;
      } else {
        return type === 'fim' ? timeLimit <= value : timeLimit >= value;
      }
    }
  }

  return (
    <div
      className="vstack w-100 p-3 justify-content-between gap-2"
      style={{ maxWidth: 420 }}
    >
      <h2 className="fw-bold fs-3 text-center">
        Deseja abrir seu estabelecimento ?
      </h2>

      <div className="vstack gap-3">
        {!configureNewHour && (
          <span className="fs-6">
            O funcionamento da loja está definido como
            <strong className="text-danger"> fechado</strong>, logo os clientes
            não poderão fazer novos pedidos, deseja abrir seu estabelecimento?
          </span>
        )}

        {openToday ? (
          <div>
            <div className="hstack gap-1">
              <span>Horário abertura:</span>
              <span className="fw-bold">{openingHours.timeToOpen} hrs</span>
            </div>
            <div className="hstack gap-1">
              <span>Horário fechamento:</span>
              <span className="fw-bold">{openingHours.timeToClose} hrs</span>
            </div>
          </div>
        ) : (
          <span>
            Seu estabelecimento está configurado para
            <strong className="text-danger">
              {' '}
              não abrir de {getActualDay(true).toLocaleLowerCase()}
            </strong>
            , caso queira abri-lo configure um horário de funcionamento:
          </span>
        )}

        {configureNewHour && (
          <div className="vstack w-100 align-items-center gap-1">
            <div className="hstack align-items-center gap-2 justify-content-center">
              <span>Abrir as: </span>
              <TextField
                type="time"
                value={newOpeningHours.timeToOpen}
                onChange={(e) => HandleOpeningHours('inicio', e.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{ step: 300 }}
              />
            </div>

            <div className="hstack align-items-center gap-2 justify-content-center">
              <span>Fechar as: </span>
              <TextField
                type="time"
                value={newOpeningHours.timeToClose}
                onChange={(e) => HandleOpeningHours('fim', e.target.value)}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }}
              />
            </div>

            {invalidNewHours.error && (
              <span className="fw-bold text-danger fs-7 mt-1">
                {invalidNewHours.message}
              </span>
            )}
          </div>
        )}
      </div>

      <div className="hstack gap-1 mt-3">
        <button
          className="btn btn-outline-primary w-100"
          onClick={() => close()}
        >
          Manter fechado
        </button>
        <button
          className={`btn btn-primary w-100 ${
            invalidNewHours.error && 'pe-none opacity-50'
          }`}
          onClick={() => openStore(newOpeningHours)}
          disabled={invalidNewHours.error}
        >
          Abrir
        </button>
      </div>
    </div>
  );
}

export default StoreStatusAlertModal;
