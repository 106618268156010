import { Skeleton } from '@material-ui/lab';

function SkeletonOrderDetails() {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Skeleton
        animation="wave"
        variant="rect"
        height={115}
        width={'100%'}
        style={{
          border: 'none',
          borderRadius: 16,
          marginBottom: 16,
          maxWidth: 450,
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        height={300}
        width={'100%'}
        style={{
          border: 'none',
          borderRadius: 16,
          marginBottom: 16,
          maxWidth: 450,
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        height={100}
        width={'100%'}
        style={{
          border: 'none',
          borderRadius: 16,
          marginBottom: 16,
          maxWidth: 450,
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        height={100}
        width={'100%'}
        style={{
          border: 'none',
          borderRadius: 16,
          marginBottom: 16,
          maxWidth: 450,
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        height={35}
        width={'100%'}
        style={{
          border: 'none',
          borderRadius: 8,
          marginBottom: 16,
          maxWidth: 450,
        }}
      />
      <Skeleton
        animation="wave"
        variant="rect"
        height={24}
        width={'50%'}
        style={{
          border: 'none',
          borderRadius: 16,
          marginBottom: 16,
          maxWidth: 450,
        }}
      />
    </div>
  );
}

export default SkeletonOrderDetails;
