import React, { useState, useEffect } from 'react';
import './Banner.scss';
import { useWindowDimensions } from '../../Utils/Index';

function Banner() {
  const [groupedFeatures, setGroupedFeatures] = useState([]);
  const [qtyItemsPerLine, setQtyItemsPerLine] = useState(0);

  const features = [
    'Delivery Personalizado',
    'Gestor de Pedidos',
    'Integração com Ifood',
    'Cardápio QR Code',
    'Domínio Próprio',
    'Sistema de Troco',
    'Controle de Distância',
    'Horários Customizáveis',
    'Audiência de Acessos',
  ];

  const { width } = useWindowDimensions();

  useEffect(() => {
    const itemsPerLine = width <= 535 ? 2 : 3;
    if (itemsPerLine === qtyItemsPerLine) return;
    setQtyItemsPerLine(itemsPerLine);
    setGroupedFeatures(groupArr(features, itemsPerLine));
  }, [width]);

  function groupArr(array, qtyItemsInArray) {
    const group = [];
    for (let i = 0, j = 0; i < array.length; i++) {
      if (i >= qtyItemsInArray && i % qtyItemsInArray === 0) j++;
      group[j] = group[j] || [];
      group[j].push(array[i]);
    }
    return group;
  }

  return (
    <div className="banner">
      <div className="banner-container">
        <h1 className="banner-title">
          Seu estabelecimento <br />
          pode vender muito<span className="banner-plus">+</span>
        </h1>
        <div className="banner__features">
          {groupedFeatures.length &&
            groupedFeatures.map((group, groupIndex) => {
              return (
                <span key={groupIndex} className="banner__features-group">
                  {group.map((feature, featureIndex) => {
                    return (
                      <div key={featureIndex}>
                        <span className="banner__features-label">
                          {feature}
                        </span>
                        {featureIndex !== qtyItemsPerLine - 1 &&
                          group.length > 1 && <span className="dot">•</span>}
                      </div>
                    );
                  })}
                </span>
              );
            })}
        </div>
        <img
          className="banner-image"
          src={`${process.env.REACT_APP_FIREBASE_URL}/assets%2Flanding_page%2Flanding_page_motorcycle.png?alt=media`}
          loading="lazy"
          alt="Banner promocional do Delivery.Legal"
        />
      </div>
    </div>
  );
}

export default Banner;
