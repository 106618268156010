import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GESTOR_TABS } from '../../../shared/StoreHeader/constants/header.constants';
import { Route, Switch } from 'react-router-dom';
import StoreHeader from '../../../shared/StoreHeader/StoreHeader';
import { StoreOwner } from '../../../Authentication/Provider/AuthenticationProvider';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getItemInBrowserStorage } from '../../../Utils/Index';

export default function Painel() {
  const history = useHistory();
  const { storeOwnerData, setStoreOwnerData } = useContext(StoreOwner);

  useEffect(() => {
    if (!storeOwnerData) {
      const data = getItemInBrowserStorage('STORE_OWNER');
      if (!data) {
        logout();
        return;
      }
      setStoreOwnerData(storeOwnerData);
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  function logout() {
    history.push('/login');
    console.error('access-denied=[401]');
    sessionStorage.clear();
    localStorage.clear();
  }

  return (
    <Router>
      <StoreHeader />
      <Switch>
        {GESTOR_TABS.map((route) => (
          <Route
            exact={true}
            key={route.id}
            path={route.url}
            children={route.component}
          />
        ))}
      </Switch>
    </Router>
  );
}
