import React, { useEffect, useState } from 'react';
import './DocumentationsFilter.scss';

import { deepClone } from '../../Utils/Index';
import documentations from '../../assets/mock/documentations';

function DocumentationsFilter({ disableHeader, useModalColors }) {
  const [documentationSearched, setDocumentationSearched] =
    useState(documentations);

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty('--use-modal-colors', true);
  }, [useModalColors]);

  function handleSearch(text) {
    const docsToSearch = deepClone(documentations);

    const filteredDocs = docsToSearch.filter((doc) =>
      doc.type
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(
          text
            .toLocaleLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        )
    );

    const filteredsubDocs = docsToSearch
      .filter((doc) => {
        doc.documentations = doc.documentations.filter((subDoc) =>
          subDoc.type
            .toLocaleLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              text
                .toLocaleLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
        );
        return doc;
      })
      .filter((doc) => doc.documentations.length > 0);

    setDocumentationSearched([...filteredsubDocs, ...filteredDocs]);
  }

  return (
    <div className="documentations">
      <div className="documentations__container">
        <div className="documentations__container__header">
          {!disableHeader && (
            <h2 className="documentations__container__header__title">
              Como podemos te ajudar ?
            </h2>
          )}
          <input
            type={'text'}
            className="documentations__container__header__input"
            placeholder="Busque respostas..."
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="documentations__container__documentations">
          {documentationSearched.map((type, index) => {
            return (
              <div
                key={index}
                className="documentations__container__documentations__section"
              >
                <a
                  className="documentations__container__documentations__section__type"
                  href={type.documentation_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {type.type}
                </a>

                <>
                  {type.documentations && type.documentations.length
                    ? type.documentations.map((doc, docIndex) => {
                        return (
                          <a
                            key={docIndex}
                            className="documentations__container__documentations__section__type__content"
                            href={doc.documentation_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {doc.type}
                          </a>
                        );
                      })
                    : null}
                </>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DocumentationsFilter;
