import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import './Input.scss';
import {
  detectCardFlag,
  maskCardNumber,
  maskCEP,
  maskCNPJ,
  maskCPF,
  maskCurrencyBRLWithSymbolString,
  maskPhoneBR,
  onlyNumbers,
  validateExpiryDate,
} from '../../Utils/Index';
import {
  IconArrowDown,
  IconArrowUp,
  IconEyeHide,
  IconEyeShow,
} from '../../assets/Icons';

function Input({
  label = '',
  containerStyle,
  labelStyle,
  name,
  mask = null,
  callBack,
  controlled = false,
  initialCrontrolledValue = '',
  disabled = false,
  clearValueInput = false,
  cpfAndCnpj = false,
  containerCustomClass,
  labelCustomClass,
  inputCustomClass,
  useDropdownMode = false,
  isDropdownType = false,
  dropDownOptions = [
    'Restaurante',
    'Pizzaria',
    'Farmacia',
    'Lanchonete',
    'Padaria',
    'Outro',
  ],
  ...rest
}) {
  const inputRef = useRef(null);

  const { defaultValue, fieldName, registerField, error } = useField(name);

  const [inputValue, setInputValue] = useState('');
  const [currentOptionSelected, setCurrentOptionSelected] = useState('');
  const [inputControls, setInputControls] = useState('');
  const [customsStylesInLabel, setCustomsStylesInLabel] = useState(null);
  const [customsStylesInContainer, setCustomsStylesInContainer] =
    useState(null);

  const [controlledInput, setControlledInput] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordInput, setIsPasswordInput] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [dropdownMode, setDropdownMode] = useState(false);
  const [displayViewOptions, setDisplayViewOptions] = useState(false);

  useEffect(() => {
    return () => {
      setInputValue(null);
      setCurrentOptionSelected(null);
      setInputControls(null);
      setCustomsStylesInLabel(null);
      setCustomsStylesInContainer(null);
      setControlledInput(false);
      setShowPassword(false);
      setIsPasswordInput(false);
      setOpenDropdown(false);
      setDisplayViewOptions(false);
    };
  }, []);

  useEffect(() => {
    if (clearValueInput && inputValue) setInputValue('');
  }, [clearValueInput]);

  useEffect(() => {
    if (containerStyle !== customsStylesInContainer)
      setCustomsStylesInContainer(containerStyle);
    if (labelStyle !== customsStylesInLabel)
      setCustomsStylesInLabel(labelStyle);
    return () => {
      setCustomsStylesInLabel(null);
      setCustomsStylesInContainer(null);
    };
  }, [containerStyle, labelStyle]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
    setInputControls(rest);
  }, [defaultValue, registerField]);

  useEffect(() => {
    setControlledInput(Boolean(mask) || controlled || cpfAndCnpj);
  }, [mask]);

  useEffect(() => {
    setIsPasswordInput(rest.type === 'password');
  }, []);

  useEffect(() => {
    if (!initialCrontrolledValue) return;
    if (useDropdownMode)
      setTimeout(() => {
        handleOptionSelected(initialCrontrolledValue);
      }, 150);
    if (initialCrontrolledValue) setInputValue(initialCrontrolledValue);
  }, [initialCrontrolledValue]);

  useEffect(() => {
    if (useDropdownMode) setDropdownMode(useDropdownMode);
  }, [useDropdownMode]);

  function getValueInput(value) {
    if (Boolean(mask) || cpfAndCnpj) setInputMask(mask, value);
    else if (isPasswordInput) validPassword(value);
    else {
      setInputValue(value);
      callBack(value);
    }
  }

  function setInputMask(maskType, value) {
    let formatedValue = value;
    if (cpfAndCnpj) {
      const isCNPJ = value.replace(/\D/g, '').length > 11;
      if (isCNPJ) maskType = 'CNPJ';
      else maskType = 'CPF';
    }

    switch (maskType) {
      case 'CPF':
        formatedValue = maskCPF(value);
        break;
      case 'PHONE':
        formatedValue = maskPhoneBR(value);
        break;
      case 'CEP':
        formatedValue = maskCEP(value);
        if (value.length === 9) callBack(value);
        break;
      case 'CNPJ':
        formatedValue = maskCNPJ(value);
        break;
      case 'CURRENCY':
        formatedValue = maskCurrencyBRLWithSymbolString(value);
        break;
      case 'CC_NUMBER':
        formatedValue = maskCardNumber(value);
        break;
      case 'CC_EXPIRATION':
        formatedValue = validateExpiryDate(value);
        break;
      case 'NUMBER':
        formatedValue = onlyNumbers(value);
        break;
      default:
        break;
    }
    setInputValue(formatedValue);
  }

  function toggleVisibilityPassword() {
    inputControls.type = showPassword ? 'password' : 'text';
    setShowPassword(!showPassword);
    setInputControls(inputControls);
  }

  function validPassword(value) {
    setInputValue(value);
    callBack(value);
  }

  function toggleDropdown() {
    setOpenDropdown(!openDropdown);
  }

  function handleOptionSelected(option) {
    if (option === 'Outro') {
      toggleInputMode(false);
      return;
    }
    setCurrentOptionSelected(option);
    getValueInput(option);
    inputRef.current.value = option;
  }

  function toggleInputMode(displayModal) {
    if (displayModal) callBack('');
    setDropdownMode(displayModal);
    setControlledInput(!displayModal);
    setDisplayViewOptions(!displayModal);
  }

  return (
    <div
      className={containerCustomClass ? containerCustomClass : 'input-content'}
      style={customsStylesInContainer}
    >
      {dropdownMode ? (
        <>
          <label
            htmlFor={name}
            className={
              labelCustomClass ? labelCustomClass : 'input-content-label'
            }
            style={customsStylesInLabel}
          >
            {label}
            <div
              className="dropdown-container"
              onClick={() => toggleDropdown()}
            >
              <input
                {...inputControls}
                ref={inputRef}
                style={{ borderColor: error && '#F10542' }}
                readOnly={true}
              />
              <button className="dropdown-container__arrows" type="button">
                {openDropdown ? <IconArrowDown /> : <IconArrowUp />}
              </button>

              {openDropdown && (
                <div className="dropdown-container__options">
                  {dropDownOptions.map((option, key) => {
                    return (
                      <span
                        key={key}
                        className={`dropdown-container__options__option ${
                          currentOptionSelected === option
                            ? 'dropdown-container__options__option-selected'
                            : ''
                        }`}
                        onClick={() => handleOptionSelected(option)}
                      >
                        {option}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
          </label>
        </>
      ) : (
        <label
          htmlFor={name}
          className={
            labelCustomClass ? labelCustomClass : 'input-content-label'
          }
          style={customsStylesInLabel}
        >
          {label}
          {controlledInput ? (
            <div className="input-content__container__input">
              <input
                {...inputControls}
                ref={inputRef}
                value={inputValue}
                onChange={(e) => getValueInput(e.target.value)}
                style={{ borderColor: error && '#F10542' }}
                disabled={disabled}
              />
              {rest.type === 'password' && (
                <div
                  id="eye-icon"
                  className="input-content__container__input-icon-eye"
                  onClick={() => toggleVisibilityPassword()}
                >
                  {showPassword ? (
                    <IconEyeHide size={20} />
                  ) : (
                    <IconEyeShow size={20} />
                  )}
                </div>
              )}
              {displayViewOptions && (
                <button
                  className="input-content__container__input__show-modal-again"
                  onClick={() => toggleInputMode(true)}
                >
                  Visualizar opções
                </button>
              )}
              {name === 'cc_number' && (
                <div className="input-content__container__input-icon-eye">
                  {detectCardFlag(inputValue)}
                </div>
              )}
            </div>
          ) : (
            <div className="input-content__container__input">
              <input
                {...inputControls}
                ref={inputRef}
                style={{ borderColor: error && '#F10542' }}
                disabled={disabled}
              />
              {rest.type === 'password' && (
                <div
                  id="eye-icon"
                  className="input-content__container__input-icon-eye"
                  onClick={() => toggleVisibilityPassword()}
                >
                  {showPassword ? (
                    <IconEyeHide size={20} />
                  ) : (
                    <IconEyeShow size={20} />
                  )}
                </div>
              )}
            </div>
          )}
        </label>
      )}
      {error && <span className="input-content-error-input">{error}</span>}
    </div>
  );
}

export default Input;
