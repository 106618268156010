import React from 'react';
import './App.scss';
import LandingPage from './LandingPage/LandingPage';
import Painel from './painel/Configuracao/components/Painel';
import Representante from './SalesRepresentative/SalesRepresentative';
import Menu from './menu/Menu';
import ConclueOrder from './menu/Cart/ConclueOrder/ConclueOrder';
import PageNotFound from './shared/PageNotFound';
import ConfigureStore from './ConfigureStore/ConfigureStore';
import MenuProvider from './menu/MenuProvider/MenuProvider';
import AuthenticationProvider from './Authentication/Provider/AuthenticationProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import StoreLogin from './Authentication/Store/StoreLogin';
import StoreCreateAccount from './Authentication/Store/CreateAccount';
import StoreRecoverPassword from './Authentication/Store/StoreRecoverPassword';
import SupportPage from './Support/SupportPage';

export default function App() {
  return (
    <React.Fragment>
      <Router>
        <AuthenticationProvider>
          <MenuProvider>
            <Switch>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
              {/* Onboarding */}
              <Route exact path="/home" component={LandingPage} />
              <Route exact path="/login" component={StoreLogin} />
              <Route exact path="/criar-conta" component={StoreCreateAccount} />
              <Route
                exact
                path="/recuperar-senha"
                component={StoreRecoverPassword}
              />

              {/* Store */}
              <Route path="/cardapio/:id" component={Menu} />
              <Route path="/pedido/:id" component={ConclueOrder} />

              {/* Private */}
              <Route path="/painel" component={Painel} />
              <Route path="/primeiro-acesso" component={ConfigureStore} />
              <Route path="/representante" component={Representante} />

              {/* Error Pages */}
              <Route path="/suporte" component={SupportPage} />
              <Route path="*" component={PageNotFound} />
            </Switch>
          </MenuProvider>
        </AuthenticationProvider>
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
}
