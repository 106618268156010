import React, { createContext, useState } from 'react';

export const MenuContext = createContext({});
export const StoreContext = createContext({});
export const ComplementsContext = createContext({});
export const userDataContext = createContext({});

export default function MenuProvider(props) {
  const [storeSettingsProvider, setStoreSettingsProvider] = useState({
    idStore: null,
    settings: null,
  });
  const [storePayments, setStorePayments] = useState([]);

  const [productsOrders, setProductsOrders] = useState([]);
  const [productSelectedProvider, setProductSelectedProvider] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState([]);

  const [complementsProductOpened, setcomplementsProductOpened] = useState([]);
  const [complementsProductSelected, setComplementsProductSelected] = useState(
    []
  );

  const [userData, setUserData] = useState(null);

  const [historyOrders, setHistoryOrders] = useState([]);
  const [historyOrdersDetails, setHistoryOrdersDetails] = useState([]);

  return (
    <StoreContext.Provider
      value={{
        storeSettingsProvider,
        setStoreSettingsProvider,
        storePayments,
        setStorePayments,
      }}
    >
      <MenuContext.Provider
        value={{
          paymentMethod,
          setPaymentMethod,
          totalPrice,
          setTotalPrice,
          productsOrders,
          setProductsOrders,
          productSelectedProvider,
          setProductSelectedProvider,
          complementsProductSelected,
          complementsProductOpened,
        }}
      >
        <ComplementsContext.Provider
          value={{
            complementsProductOpened,
            setcomplementsProductOpened,
            complementsProductSelected,
            setComplementsProductSelected,
          }}
        >
          <userDataContext.Provider
            value={{
              userData,
              setUserData,
              historyOrders,
              setHistoryOrders,
              historyOrdersDetails,
              setHistoryOrdersDetails,
            }}
          >
            {props.children}
          </userDataContext.Provider>
        </ComplementsContext.Provider>
      </MenuContext.Provider>
    </StoreContext.Provider>
  );
}
