import React, { useEffect, useState } from 'react';
import AnnualPlanCard from '../Plans/components/AnnualPlanCard';
import MonthlyPlanCard from '../Plans/components/MonthlyPlanCard';
import './PlanCheckout.scss';
import PlanPurchase from './PlanPurchase';

function PlanCheckout({ planParams, exitCheckout, trialAvailableDays }) {
  const [backToPaymentSelected, setBackToPaymentSelected] = useState(false);
  const [planInfos, setPlanInfos] = useState(null);

  useEffect(() => {
    setPlanInfos(planParams);
  }, [planParams]);

  function resetPaymentInfosOrBackToPlan() {
    if (backToPaymentSelected) {
      setBackToPaymentSelected(false);
      setPlanInfos(structuredClone(planParams));
      return;
    }
    exitCheckout();
  }

  return (
    <div className="plan-checkout-container">
      <div className="plan-checkout-container-checkout-content">
        {planInfos && planInfos.type === 'ANNUAL' ? (
          <AnnualPlanCard blockButton={true} />
        ) : (
          <MonthlyPlanCard blockButton={true} />
        )}
        <PlanPurchase
          plan={planInfos}
          trialAvailableDays={trialAvailableDays}
          onSelectedPaymentMethod={() => setBackToPaymentSelected(true)}
        />
      </div>

      <button
        type="submit"
        className="btn btn-outline-primary mt-3 w-25"
        onClick={() => resetPaymentInfosOrBackToPlan()}
      >
        Voltar
      </button>
    </div>
  );
}

export default PlanCheckout;
