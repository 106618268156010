import React, { useState, useEffect, useContext } from 'react';
import './BillingData.scss';

import { toast } from 'react-toastify';
import {
  dashboardCards,
  getPix,
  requestWithdrawal,
} from '../../services/SalesRepresentativeService';
import SimpleDataBox from '../../shared/SimpleDataBox/SimpleDataBox';
import {
  deepClone,
  maskCurrencyBRL,
  maskCurrencyBRLWithSymbolString,
} from '../../Utils/Index';
import { SalesRepresentativeInfs } from '../SalesRepresentativeProvider/SalesRepresentativeProvider';
import SpringModal from '../../shared/Modal/Modal';

function BillingData() {
  const [representativeBillingData, setRepresentativeBillingData] = useState([
    { label: 'Valor para solicitação', service: 'valor_liberado', value: null },
    { label: 'Total líquido', service: 'valor_total', value: null },
    {
      label: 'Estabelecimentos cadastrados',
      service: 'estabelecimentos_cadastrados',
      value: null,
    },
    {
      label: 'Finalizados',
      service: 'estabelecimentos_concluidos',
      value: null,
    },
    {
      label: 'Não finalizaram',
      service: 'estabelecimentos_nao_concluidos',
      value: null,
    },
    // {label: "Usaram seu cupom", service: "cupons_utilizados", value: null},
  ]);

  const [enableWithdrawMoney, setEnableWithdrawMoney] = useState(false);
  const [openModalWithdraw, setOpenModalWithdraw] = useState(false);
  const [choosingValueToWithdraw, setChoosingValueToWithdraw] = useState(true);
  const [disableRequestButton, setDisableRequestButton] = useState(false);

  const [valueToWithdraw, setValueToWithdraw] = useState('');
  const [messageError, setMessageError] = useState('');
  const [pix, setPix] = useState({ chave: '', tipo: '' });
  const [maxValueToWithdraw, setMaxValueToWithdraw] = useState('R$ 0,00');

  const [confirmedValueToWithdraw, setConfirmedValueToWithdraw] = useState(0);

  const {
    billingDataContext,
    setBillingDataContext,
    setRepresentativePixContext,
    representativePixContext,
  } = useContext(SalesRepresentativeInfs);

  useEffect(() => {
    if (!representativePixContext) callGetPix();
    else setPix(representativePixContext);
    if (!billingDataContext) {
      getDataOfRepresentative();
      return;
    }

    setRepresentativeBillingData(billingDataContext);
    getDataOfRepresentative();
    return () => {
      setEnableWithdrawMoney(false);
      setOpenModalWithdraw(false);
      setChoosingValueToWithdraw(true);
      setDisableRequestButton(false);

      setValueToWithdraw('');
      setMessageError('');
      setPix({ chave: '', tipo: '' });
      setMaxValueToWithdraw('');

      setConfirmedValueToWithdraw(0);
    };
  }, []);

  useEffect(() => {
    if (!openModalWithdraw) resetVariables();
  }, [openModalWithdraw]);

  async function callGetPix() {
    const response = await getPix();
    if (!response || !response.success) {
      toast.error('Ocorreu um erro ao buscar o PIX cadastro');
      return;
    }
    setPix(response.results);
    setRepresentativePixContext(response.results);
  }

  async function getDataOfRepresentative() {
    const response = await dashboardCards();
    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao buscar os dados de faturamento, tente novamente ou entre em contato conosco.'
      );
      return;
    }
    updateRepresentativeData(response.results);
  }

  function updateRepresentativeData(representativeData) {
    const valueOfDataRepresentative = representativeBillingData.map((data) => {
      Object.keys(representativeData).forEach((serviceData) => {
        if (data.service === serviceData) {
          if (
            serviceData === 'valor_liberado' ||
            serviceData === 'valor_total'
          ) {
            if (
              serviceData === 'valor_liberado' &&
              representativeData[serviceData] >= 150
            ) {
              setMaxValueToWithdraw(
                representativeData[serviceData] >= 1000
                  ? 'R$ 1.000,00'
                  : representativeData[serviceData].toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })
              );
              setEnableWithdrawMoney(true);
            }

            data.value = maskCurrencyBRL(representativeData[serviceData]);
            return data;
          }
          data.value = representativeData[serviceData];
        }
      });
      return data;
    });
    setBillingDataContext(valueOfDataRepresentative);
    setRepresentativeBillingData(valueOfDataRepresentative);
  }

  function handleWithdraw() {
    if (!valueToWithdraw) {
      setMessageError('Informe um valor para o saque.');
      return;
    }

    const valueToWithdrawNumber = removeCurrencyMask(valueToWithdraw);
    const maxValueToWithdrawNumber = removeCurrencyMask(maxValueToWithdraw);

    if (valueToWithdrawNumber < 150) {
      setMessageError('Valor solicitado inferior ao permitido.');
      return;
    }

    if (valueToWithdrawNumber > maxValueToWithdrawNumber) {
      setMessageError('Valor solicitado superior ao permitido.');
      return;
    }
    setConfirmedValueToWithdraw(valueToWithdrawNumber);
    setChoosingValueToWithdraw(false);
    setMessageError('');
  }

  function removeCurrencyMask(value) {
    let val = value;
    val = val.replace(/\D/g, '');
    val = val.replace(/(\d)(\d{2})$/, '$1.$2');
    return parseFloat(val);
  }

  async function callRequestWithdrawal() {
    setDisableRequestButton(true);
    const response = await requestWithdrawal(confirmedValueToWithdraw);
    if (!response || !response.success) {
      if (response.msg === 'ERROR_WITHDRAWAL_LOWER_BALANCE') {
        toast.warn(
          'Você não possui saldo suficiente para socilitar este saque, possívelmente há solicitações em aberto, caso precise entre em contato conosco.',
          { autoClose: 7000 }
        );
        return;
      }
      toast.error(
        'Ocorreu um erro ao solicitar o seu saque, tente novamente ou entre em contato conosco.',
        { autoClose: 7000 }
      );
      setDisableRequestButton(false);
      return;
    }
    updateValueToWithdraw();
    toast.success(
      'Socilitação enviada com sucesso, em breve você receberá o valor solicitado.',
      { autoClose: 7000 }
    );
  }

  function updateValueToWithdraw() {
    let newValueToReceive = parseFloat(
      removeCurrencyMask(representativeBillingData[0].value) -
        confirmedValueToWithdraw
    ).toFixed(2);
    setEnableWithdrawMoney(newValueToReceive <= 0);
    if (newValueToReceive <= 0) {
      setEnableWithdrawMoney(false);
      newValueToReceive = 'R$ 0,00';
    } else
      newValueToReceive = maskCurrencyBRLWithSymbolString(newValueToReceive);
    const billingData = deepClone(representativeBillingData);
    billingData[0].value = newValueToReceive;
    setMaxValueToWithdraw(newValueToReceive);
    setRepresentativeBillingData(billingData);
    resetVariables();
  }

  function resetVariables() {
    setOpenModalWithdraw(false);
    setChoosingValueToWithdraw(true);
    setDisableRequestButton(false);
    setValueToWithdraw('');
    setMessageError('');
    setConfirmedValueToWithdraw(0);
  }

  return (
    <div className="billing-data">
      <h1 className="sales-representative-section-header">Faturamento</h1>
      <div className="billing-data__card-container">
        {representativeBillingData.map((field, index) => {
          return (
            <div className="billing-data__card-container-card" key={index}>
              <SimpleDataBox
                title={field.label}
                data={field.value}
                displayCustomLabel={
                  field.service === 'valor_liberado' && enableWithdrawMoney
                }
                clickOnCustomLabel={() => setOpenModalWithdraw(true)}
              />
            </div>
          );
        })}
      </div>
      <SpringModal
        handleOpen={openModalWithdraw}
        handleClose={setOpenModalWithdraw}
        customStyleModal={{ minWidth: '500px' }}
      >
        <div className="modal-withdraw">
          {choosingValueToWithdraw ? (
            <>
              <h1 className="modal-withdraw__title">Sacar dinheiro</h1>
              <div className="modal-withdraw__content">
                <p className="modal-withdraw__content__subtitle">
                  {' '}
                  Informe a quantidade que deseja sacar.
                </p>
                <div className="modal-withdraw__content__values">
                  <p>
                    Valor minímo: <strong>R$ 150,00</strong> | Valor máximo:{' '}
                    <strong>{maxValueToWithdraw}</strong>
                  </p>
                </div>
              </div>
              <div className="modal-withdraw__request">
                <input
                  type="text"
                  className="sign-in__input mb"
                  onChange={(e) =>
                    setValueToWithdraw(
                      maskCurrencyBRLWithSymbolString(e.target.value)
                    )
                  }
                  value={valueToWithdraw}
                  placeholder="Informe o valor que deseja sacar"
                />
                <span className="modal-withdraw__request__message-error">
                  {messageError}
                </span>
              </div>
            </>
          ) : (
            <>
              <h1 className="modal-withdraw__title">
                Confimar valor solicitado
              </h1>
              <div className="modal-withdraw__content">
                <p className="modal-withdraw__content__subtitle">
                  Você está solicitando o saque de{' '}
                  <strong>{valueToWithdraw}</strong>
                </p>
                <div className="modal-withdraw__content__values">
                  <p>
                    Este valor será enviado por <strong>PIX</strong> para o{' '}
                    {pix.tipo}: {pix.chave}
                  </p>
                </div>
              </div>
            </>
          )}
          <div className="modal-withdraw__buttons">
            <button
              type="button"
              className="sign-in__container-sign-btn-back"
              onClick={() =>
                choosingValueToWithdraw
                  ? setOpenModalWithdraw(false)
                  : setChoosingValueToWithdraw(true)
              }
            >
              {choosingValueToWithdraw ? 'Fechar' : 'Voltar'}
            </button>
            <button
              type="button"
              style={{ maxWidth: 300 }}
              className={`align-buttons__btn mb ${
                disableRequestButton ? 'disabled-btn' : ''
              }`}
              onClick={() =>
                choosingValueToWithdraw
                  ? handleWithdraw()
                  : callRequestWithdrawal()
              }
              disabled={disableRequestButton}
            >
              {choosingValueToWithdraw ? 'Solicitar saque' : 'Confirmar'}
            </button>
          </div>
        </div>
      </SpringModal>
    </div>
  );
}

export default BillingData;
