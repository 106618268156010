import EventEmitter from '../../../services/Event';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { updateCategory } from '../../../services/Products';
import './CreateCategory.scss';

export default function CreateCategory({
  dataCategory,
  returnCategory,
  close,
}) {
  const [name, setName] = useState('');
  const [statusCategory, setStatusCategory] = useState(true);
  const [codeCategory, setCodeCategory] = useState(0);
  const [titleModal, setTitleModal] = useState('Nova categoria');

  useEffect(() => {
    if (dataCategory) {
      setTitleModal('Editar categoria');
      setCodeCategory(dataCategory.codigo);
      setStatusCategory(dataCategory.ativo === 1);
      setName(dataCategory.nome);
    }
  }, [dataCategory]);

  async function saveCategory() {
    if (name !== ('' || null || undefined)) {
      let resp = await updateCategory(codeCategory, name, statusCategory);
      if (resp.success) {
        returnCategory({
          codigo: codeCategory === 0 ? resp.result : codeCategory,
          nome: name,
          ativo: statusCategory ? 1 : 0,
          newCat: codeCategory === 0,
        });
        toast.success(
          `Categoria ${codeCategory === 0 ? 'criada' : 'editada'} com sucesso.`
        );
        EventEmitter.emit('closeModal', false);
      }
    } else {
      toast.error(
        `Por favor informe todos os dados para ${
          codeCategory === 0 ? 'criar' : 'editar'
        } a categoria.`
      );
    }
  }

  return (
    <div className="vstack p-2">
      <div className="w-100 hstack justify-content-between">
        <h1 className="title">{titleModal}</h1>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => close()}
        ></button>
      </div>

      <div className="new-category-body">
        <div className="input-content">
          <span className="title-input">
            Nome <span className="required">(Obrigatório)</span>
          </span>
          <input
            style={{ maxWidth: 345, width: '90%' }}
            type="text"
            placeholder="Nome da categoria"
            className="input-style"
            value={name}
            onChange={(e) => setName(e.target.value)}
            maxLength={50}
          />
        </div>
        <div className="input-content card-toggle">
          <span className="title-input">
            Status <span className="required">(Obrigatório)</span>
          </span>
          <div className="btn-toggle">
            <div
              className="switch-btn"
              onClick={() => setStatusCategory(!statusCategory)}
            >
              <div
                style={{ paddingLeft: 11 }}
                className={statusCategory ? 'active' : 'disable'}
              >
                Ativo
              </div>
              <div
                style={{ paddingRight: 5 }}
                className={!statusCategory ? 'active' : 'disable'}
              >
                Inativo
              </div>
              <div
                className={statusCategory ? 'selected left' : 'selected right'}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-btn-save">
        <button
          className="Btn add-items save-updates btn-modal"
          onClick={() => saveCategory()}
        >
          Salvar
        </button>
      </div>
    </div>
  );
}
