import React, { useEffect, useState } from 'react';
import { planStatus } from '../../../services/Assinaturas';
import Plans from './Plans/Plans';
import WithoutPlan from './WithoutPlan';
import './Assinatura.scss';
import PlanCheckout from './PlanCheckout/PlanCheckout';
import Loader from './PlanCheckout/components/Loader';
import ActivePlan from './Plans/components/ActivePlan';

function Assinatura() {
  const [currentPlan, setCurrentPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [withoutPlan, setWithoutPlan] = useState(false);
  const [displayPlans, setDisplayPlans] = useState(false);
  const [planToCheckout, setPlanToCheckout] = useState(null);

  useEffect(() => {
    getPlanStatus();
  }, []);

  async function getPlanStatus() {
    const response = await planStatus();
    setCurrentPlan(response);
    setWithoutPlan(
      response.metodo === 'trial' ||
        ['EXPIRED', 'NOT_SIGNATURE'].includes(response.status)
    );
    setLoading(false);
  }

  function configurePlanToOpenCheckout(type) {
    if (!type) {
      setPlanToCheckout(null);
      setDisplayPlans(true);
      return;
    }
    const isAnnual = type === 'ANNUAL';
    setPlanToCheckout({
      type,
      label: `Delivery ${isAnnual ? 'Anual' : 'Mensal'}`,
      price: isAnnual ? 'R$ 622,80' : 'R$ 50,00',
    });
    setDisplayPlans(false);
  }

  return (
    <div>
      <h1 className="sales-representative-section-header">Assinatura</h1>

      {loading && <Loader />}

      {!loading && !withoutPlan && !displayPlans && !planToCheckout && (
        <ActivePlan
          plan={currentPlan}
          displayPlans={() => setDisplayPlans(true)}
        />
      )}

      {!loading && withoutPlan && !displayPlans && !planToCheckout && (
        <WithoutPlan
          availableDays={currentPlan.dias_restantes}
          goToPlansPage={() => setDisplayPlans(true)}
        />
      )}

      {!loading && displayPlans && (
        <Plans
          showCurrentPlan={() => setDisplayPlans(false)}
          onSelectPlan={(plan) => configurePlanToOpenCheckout(plan)}
        />
      )}

      {!loading && planToCheckout && (
        <PlanCheckout
          planParams={planToCheckout}
          exitCheckout={() => configurePlanToOpenCheckout(null)}
          trialAvailableDays={currentPlan?.dias_restantes ?? 0}
        />
      )}
    </div>
  );
}

export default Assinatura;
