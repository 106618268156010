import React, { useState } from 'react';
import { IconPaymentMethod } from '../../../Utils/IconPaymentMethod';

function OrderPaymentMethod({ payments, onSelectedPayment, close }) {
  const [paymentSelected, setPaymentSelected] = useState(null);

  return (
    <div className="w-100 h-100 d-flex flex-wrap justify-content-center gap-2">
      <h3 className="fs-5 text-primary mb-1 fw-bold text-center">
        Escolha a forma de pagamento
      </h3>

      <div
        className="overflow-y-auto w-100 d-flex flex-wrap justify-content-center gap-2"
        style={{ maxHeight: '45vh' }}
      >
        {payments?.map((payment) => {
          return (
            <button
              key={payment.cod_tipo_pagto}
              style={{ width: 100, maxWidth: 100, height: 75 }}
              className={`btn btn-primary-fill p-0 m-0 vstack border rounded-3 align-items-center justify-content-center p-1 shadow-sm gap-1
                ${
                  payment.cod_tipo_pagto === paymentSelected?.cod_tipo_pagto
                    ? 'border-primary text-primary'
                    : ''
                }
                `}
              onClick={() => setPaymentSelected(payment)}
            >
              <IconPaymentMethod
                idPayment={payment.cod_tipo_pagto}
                width={30}
                height={24}
              />
              <span className="fs-8">{payment.tooltip}</span>
            </button>
          );
        })}
      </div>

      <div className="hstack w-100 mt-3">
        <button onClick={() => close()} className="btn btn-outline-fill w-50">
          Voltar
        </button>
        <button
          disabled={!paymentSelected}
          onClick={() => onSelectedPayment(paymentSelected)}
          className={`btn btn-primary w-50 ${
            !paymentSelected ? 'pe-none opacity-50' : ''
          }`}
        >
          Avançar
        </button>
      </div>
    </div>
  );
}

export default OrderPaymentMethod;
