import React, { useEffect, useState } from 'react';
import {
  IconAleloPayment,
  IconAmexPayment,
  IconBanriComprasPayment,
  IconChequePayment,
  IconDinersPayment,
  IconEloPayment,
  IconGoodCardPayment,
  IconHiperCardPayment,
  IconMasterCardPayment,
  IconPIXPayment,
  IconSodexoPayment,
  IconTicketPayment,
  IconValeCardPayment,
  IconVerdeCardPayment,
  IconVisaPayment,
  IconVRPayment,
} from '../assets/Images/payments/IconsPayment';
import IconMoneyPayment from '../assets/Images/payments/IconMoneyPayment.svg';
import IconCooperCardPayment from '../assets/Images/payments/IconCooperCardPayment.svg';
import IconNugoCardPayment from '../assets/Images/payments/IconNugoCardPayment.svg';
import IconRefeisulPayment from '../assets/Images/payments/IconRefeisulPayment.svg';
import IconVeroCardPayment from '../assets/Images/payments/IconVeroCardPayment.png';
import IconNutriCardPayment from '../assets/Images/payments/IconNutriCardPayment.svg';
import IconBenPayment from '../assets/Images/payments/IconBenPayment.svg';
import IconGreenCardPayment from '../assets/Images/payments/IconGreenCardPayment.svg';

export function IconPaymentMethod({ idPayment, width = 24, height = 18 }) {
  const [iconPayment, setIconPayment] = useState(null);

  useEffect(() => {
    returnIconPayment(idPayment);
  }, [idPayment]); //eslint-disable-line react-hooks/exhaustive-deps

  function returnIconPayment(id) {
    let icon = null;
    switch (id) {
      case 1:
        icon = <IconAmexPayment width={width} height={height} />;
        break;
      case 2:
        icon = <IconEloPayment width={width} height={height} />;
        break;
      case 3:
        icon = <IconMasterCardPayment width={width} height={height} />;
        break;
      case 4:
        icon = (
          <img
            src={IconNugoCardPayment}
            width={width}
            height={height}
            alt="Bandeira da opção de pagamento NUGO"
          />
        );
        break;
      case 5:
        icon = <IconDinersPayment width={width} height={height} />;
        break;
      case 6:
        icon = <IconVisaPayment width={width} height={height} />;
        break;
      case 7:
        icon = <IconBanriComprasPayment width={width} height={height} />;
        break;
      case 8:
        icon = <IconHiperCardPayment width={width} height={height} />;
        break;
      case 9:
        icon = <IconVerdeCardPayment width={width} height={height} />;
        break;
      case 10:
        icon = <IconGoodCardPayment width={width} height={height} />;
        break;
      case 11:
        icon = <IconBanriComprasPayment width={width} height={height} />;
        break;
      case 12:
        icon = <IconEloPayment width={width} height={height} />;
        break;
      case 13:
        icon = <IconMasterCardPayment width={width} height={height} />;
        break;
      case 14:
        icon = <IconVisaPayment width={width} height={height} />;
        break;
      case 15:
        icon = (
          <img
            src={IconMoneyPayment}
            width={width}
            height={height}
            alt="Ícone de dinheiro"
          />
        );
        break;
      case 16:
        icon = <IconChequePayment width={width} height={height} />;
        break;
      case 17:
        icon = (
          <img
            src={IconRefeisulPayment}
            width={width}
            height={height}
            alt="Ícone da bandeira do Refeisul"
          />
        );
        break;
      case 18:
        icon = <IconValeCardPayment width={width} height={height} />;
        break;
      case 19:
        icon = <IconTicketPayment width={width} height={height} />;
        break;
      case 20:
        icon = (
          <img
            src={IconGreenCardPayment}
            width={width}
            height={height}
            alt="Ícone da bandeira do Cooper Card"
          />
        );
        break;
      case 21:
        icon = (
          <img
            src={IconBenPayment}
            width={width}
            height={height}
            alt="Ícone da bandeira do Ben"
          />
        );
        break;
      case 22:
        icon = <IconSodexoPayment width={width} height={height} />;
        break;
      case 23:
        icon = (
          <img
            src={IconVeroCardPayment}
            width={width}
            height={height}
            alt="Ícone da bandeira do Cooper Vero Card"
          />
        );
        break;
      case 24:
        icon = <IconAleloPayment width={width} height={height} />;
        break;
      case 25:
        icon = <IconVRPayment width={width} height={height} />;
        break;
      case 26:
        icon = (
          <img
            src={IconCooperCardPayment}
            width={width}
            height={height}
            alt="Ícone da bandeira do Cooper Card"
          />
        );
        break;
      case 27:
        icon = (
          <img
            src={IconNutriCardPayment}
            width={width}
            height={height}
            alt="Ícone da nutri card"
          />
        );
        break;
      case 28:
        icon = <IconAleloPayment width={width} height={height} />;
        break;
      case 29:
        icon = <IconSodexoPayment width={width} height={height} />;
        break;
      case 30:
        icon = <IconTicketPayment width={width} height={height} />;
        break;
      case 31:
        icon = <IconVRPayment width={width} height={height} />;
        break;
      case 32:
        icon = <IconPIXPayment width={width} height={height} />;
        break;
      default:
        icon = null;
        break;
    }
    setIconPayment(icon);
  }

  return <> {iconPayment} </>;
}
