import React, { useEffect, useState } from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { getStore } from '../services/Menu';
import LoadingContent from '../menu/LoadingStoreInfos/LoadingContent';
import { saveInBrowserStorage } from '../Utils/Index';

function PageNotFound() {
  const location = useLocation();
  const history = useHistory();
  const [verifyingID, setVerifyingID] = useState(true);

  useEffect(() => {
    if (!location.pathname) {
      setVerifyingID(false);
      return;
    }
    verifyIsStoreID();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  function verifyIsStoreID() {
    const id = normalizeID(location.pathname.split('/')[1] ?? '');
    if (!id) {
      setVerifyingID(false);
      return;
    }
    getStore(id)
      .then((response) => {
        if (!response || !response.success) {
          setVerifyingID(false);
          return;
        }
        saveInBrowserStorage(`${id}_BY_REDIR`, response);
        history.push(`/cardapio/${id}?byRedir=1`);
      })
      .catch(() => setVerifyingID(false));
  }

  function normalizeID(value) {
    const normalizedStr = value.normalize('NFD');
    return normalizedStr
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9 ]/g, '');
  }

  return (
    <>
      {verifyingID ? (
        <LoadingContent />
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="text-center">
            <img
              src={`${process.env.REACT_APP_FIREBASE_URL}/assets%2Fonboarding%2F404-page.png?alt=media`}
              alt="Imagem de um homem com uma cara triste"
              className="img-fluid mb-4"
              style={{ maxWidth: '300px' }}
            />

            <h1 className="display-1 text-danger">404</h1>

            <p className="lead text-muted mb-4">
              Oops! A página que você está procurando não foi encontrada.
            </p>

            <div className="d-flex justify-content-center gap-3">
              <a href="/home" className="btn btn-danger">
                Voltar para Início
              </a>
              <a href="/suporte" className="btn btn-outline-danger">
                Suporte
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PageNotFound;
