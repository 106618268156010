import React from 'react';
import DocumentationsFilter from '../components/DocumentationsFilter/DocumentationsFilter';
import SupportModal from '../components/SupportModal/SupportModal';
import './SupportPage.scss';

function Documentation() {
  return (
    <div className="documentation">
      <DocumentationsFilter useModalColors={false} />
      <SupportModal />
    </div>
  );
}

export default Documentation;
